import { Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../../Components/ShowToast/ToastNotification";

const Customer = () => {
    const { t } = useTranslation();

    // Initialize formData with API response fields
    const [formData, setFormData] = useState({
        host_name: "",
        customer_name: "",
        dashboard_msg_en: "",
        dashboard_msg_ar: "",
        session_lifetime: 0,
        ldap_enabled: 0, 
        sso_enabled: 0, 
        login_using_email_enabled: 0, 
        language_id: 0,
    });

    // Fetch customer data
    const fetchCustomerData = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/customer/settings", {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                showToast(t("failedtofetchdata"), "error");
                return;
            }            

            const data = await response.json();
            if (data.status === "success") {
                // Update formData with API response, ensure 1 or 0 for checkboxes
                setFormData({
                    host_name: data.data.host_name,
                    customer_name: data.data.customer_name,
                    dashboard_msg_en: data.data.dashboard_msg_en,
                    dashboard_msg_ar: data.data.dashboard_msg_ar,
                    session_lifetime: data.data.session_lifetime,
                    ldap_enabled: data.data.ldap_enabled, 
                    sso_enabled: data.data.sso_enabled, 
                    login_using_email_enabled: data.data.login_using_email_enabled, 
                    language_id: data.data.language_id,
                });
            }
        } catch (err) {
            showToast(t("errfetchingdata"), "error");
        }        
    };

    useEffect(() => {
        fetchCustomerData();
    }, []);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            // [name]: type === "checkbox" ? (checked ? 1 : 0) : value, // Set 1 or 0 for checkbox
            [name]: type === "checkbox" ? (checked ? 1 : 0) : (name === "language_id" ? parseInt(value) : value),
    
        });
    };

    // Function to update customer data
    const updateCustomerData = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/customer/settings/update", {
                mode: 'cors',
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData), // Send form data as JSON
            });

            if (!response.ok) {
                showToast(t("failedtoupdatedata"), "error");
                return;
            }
            
            const data = await response.json();
            if (data.status === "success") {
                fetchCustomerData();
                showToast(t("customerdataupdatedsuccessfully"), "success");
            } else {
                showToast(t("updatefailed"), "error");
            }
            } catch (err) {
                showToast(t("errorupdatingdata"), "error");
            }            
    };

    // Handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        updateCustomerData();
    };

    return (
        <div className="py-4" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '0px 15px' }}>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("HostName")}</label>
                            <input
                                type="text"
                                name="host_name"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.host_name}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("CustomerName")}</label>
                            <input
                                type="text"
                                name="customer_name"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.customer_name}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("DashboardMessage(English)")}</label>
                            <textarea
                                name="dashboard_msg_en"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.dashboard_msg_en}
                                rows="2"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("DashboardMessage(Arabic)")}</label>
                            <textarea
                                name="dashboard_msg_ar"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.dashboard_msg_ar}
                                rows="2"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("SessionLifetime")}</label>
                            <input
                                type="number"
                                name="session_lifetime"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.session_lifetime}
                            />
                        </div>
                    </div>

                    <div className="p-4">
                        <div className="row" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '0px 15px' }}>
                            {/* Checkbox section - Grouped in one column */}
                            <div className="col-md-6 mt-4">
                                <div className="mb-4">
                                    {/* LDAP Enabled */}
                                    <div className="form-check mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="ldap_enabled"
                                            checked={formData.ldap_enabled === 1} // Check if it's 1
                                            onChange={handleChange}
                                            id="ldapEnabled"
                                        />
                                        <label className="form-check-label" htmlFor="ldapEnabled">
                                            LDAP
                                        </label>
                                    </div>
                                    {/* SSO Enabled */}
                                    <div className="form-check mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="sso_enabled"
                                            checked={formData.sso_enabled === 1} // Check if it's 1
                                            onChange={handleChange}
                                            id="ssoEnabled"
                                        />
                                        <label className="form-check-label" htmlFor="ssoEnabled">
                                            SSO
                                        </label>
                                    </div>
                                    {/* Login Using Email Enabled */}
                                    <div className="form-check mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="login_using_email_enabled"
                                            checked={formData.login_using_email_enabled === 1} // Check if it's 1
                                            onChange={handleChange}
                                            id="loginUsingEmailEnabled"
                                        />
                                        <label className="form-check-label" htmlFor="loginUsingEmailEnabled">
                                           {t("LoginUsingEmail")}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* Language selection */}
                            <div className="col-md-6 mt-1">
                            <div className="mb-1">
                                <label className="form-label">{t("LanguageSelection")}</label>
                                <div className="form-check mb-0">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="language_id"
                                        value="1"
                                        checked={formData.language_id === 1}
                                        onChange={handleChange}
                                        id="englishLanguage"
                                    />
                                    <label className="form-check-label" htmlFor="englishLanguage">
                                        {t("English")}
                                    </label>
                                </div>
                                <div className="form-check mb-0">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="language_id"
                                        value="2"
                                        checked={formData.language_id === 2}
                                        onChange={handleChange}
                                        id="arabicLanguage"
                                    />
                                    <label className="form-check-label" htmlFor="arabicLanguage">
                                        {t("Arabic")}
                                    </label>
                                </div>
                            </div>
                        </div>


                        </div>
                    </div>
                </div>

                <Button className="mt-2" variant="primary" type="submit">
                    {t('UpdateCustomerSettings')}
                </Button>
            </form>
        </div>
    );
};

export default Customer;
