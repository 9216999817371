import React, { useState, useEffect } from "react";

// Base API URL (replace with your actual base API URL)
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";

const SendEmail = ({id}) => {
    // State variables
    console.log("id",id);
    const [sendemail, setSendEmail] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(""); // To store selected template
    const [selectedServer, setSelectedServer] = useState(""); // To store selected server
    const [arabicTemplates, setArabicTemplates] = useState([]); // To store Arabic email templates
    const [englishTemplates, setEnglishTemplates] = useState([]); // To store English email templates
    const [servers, setServers] = useState([]); // To store available servers
    const [loading, setLoading] = useState(false); // For loading state
    const { t } = useTranslation();

    // handleSendEmail function
    const handleSendEmail = async (id, templateId, serverId) => {
        try {
            const token = localStorage.getItem("userToken");

            const requestBody = {
                campaign_id: id,  // Replace with valid campaign ID
                template_id: templateId,  // Replace with valid template ID
                server_id: serverId,      // Replace with valid server ID
            };

            const response = await fetch(`${api_url}/learning-campaigns-emails/send-emails`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });

            const result = await response.json();
            if (response.ok) {
                showToast(t("emailsentsuccessfully"), "success");
                setSendEmail(false);
            } else {
                showToast(t("failedtosendemail", { message: result.message }), "error");
                setSendEmail(false);
            }
            } catch (error) {
                setSendEmail(false);
                console.error("Error sending email:", error);
                showToast(t("erroroccuredsendingemail"), "error");
            }
            
    };

    // Fetch email templates function
    const fetchEmailTemplates = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/learning-campaigns-settings-templates`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            // Assuming the API response structure is correct
            setArabicTemplates(result.arabic_templates || []);
            setEnglishTemplates(result.english_templates || []);
        } catch (error) {
            showToast(t("failedtofetchemailtemplates"), "error");
        }        
    };

    // Fetch email servers function
    const fetchEmailServers = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-servers`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            // Ensure email_servers is processed correctly
            if (result.data.length > 0) {
                setServers(result.data); // Store the email servers in state
            }else {
                setServers([]);
                showToast(t("noemailserversavailable"), "error");
            }
            } catch (error) {
                console.error("Error fetching email servers:", error);
                showToast(t("failedtofetchemailservers"), "error");
                setServers([]);
            }            
    };

    // Use effect to fetch email templates and servers on component mount
    useEffect(() => {
        fetchEmailTemplates(); // Fetch email templates when the component mounts
        fetchEmailServers(); // Fetch email servers when the component mounts
    }, []);

    // Placeholder for showToast function (you can replace it with your own toast implementation)
    const showToast = (message, type) => {
        console.log(`${type.toUpperCase()}: ${message}`);
    };

    return (
        <div style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'0px 15px'}}>
            {/* Email Templates Section */}
            <div>
                {loading ? (
                    <p>{t('Loading')}...</p>
                ) : (
                    <div>
                        <div style={{ display: "flex", gap: "16px", alignItems: "center",marginTop:'30px' }}>
                            {/* Template Dropdown */}
                            <div className="mb-3" style={{ width: "30%" }}>
                                <label htmlFor="englishTemplate" className="form-label">
                                   {t("EnglishEmailTemplate")}
                                </label>
                                <select
                                    className="form-select"
                                    id="englishTemplate"
                                    value={selectedTemplate}
                                    onChange={(e) => setSelectedTemplate(e.target.value)}
                                    required
                                >
                                    <option value="">{t("SelectEnglishTemplate")}</option>
                                    {englishTemplates.map((template) => (
                                        <option key={template.id} value={template.id}>
                                            {template.title}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Server Dropdown */}
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t("SelectServer")}</label>
                                    <select
                                        className="form-select"
                                        value={selectedServer}
                                        onChange={(e) => setSelectedServer(e.target.value)}
                                        disabled={sendemail}
                                    >
                                        <option value="">{t("SelectServer")}</option>
                                        {servers.length > 0 ? (
                                            servers.map((server, index) => (
                                                <option key={index} value={server.id}>
                                                    {server.name}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">{t("Noserversavailable")}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Send Email Button */}
                        <button
                            onClick={() => {
                                if (selectedTemplate && selectedServer) {
                                    setSendEmail(true);
                                    handleSendEmail(id, selectedTemplate, selectedServer); // Pass valid parameters
                                } else {
                                    showToast(t("pleaseselectbothtemplateandserver"), "error");
                                }                                
                            }}
                            disabled={sendemail || !selectedTemplate || !selectedServer}
                            style={{
                                backgroundColor: sendemail ? "#FFF" : "#234F8E",
                                color: "white",
                                padding: "5px 10px",
                                border: "none",
                                borderRadius: "5px",
                                cursor: sendemail ? "not-allowed" : "pointer",
                                marginTop: "30px",
                                marginBottom: "10px"
                            }}
                        >
                            {sendemail ? t("Sending...") : t("SendEmail")}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SendEmail;
