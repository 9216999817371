import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import "./AddEmailTemplate.css";
import { useTranslation } from "react-i18next";
import { showToast } from "../ShowToast/ToastNotification";
import { Tabs, Tab, Button ,Spinner} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PreviewEmail from "../../Components/PreviewEmailTemplateModal/PreviewEmail";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddEmailTemplate = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [key, setKey] = useState("details");
    const [isLoading, setIsLoading] = useState(false); // Loader state

    // Form states
    const [subject, setSubject] = useState("");
    const [language_id, setLanguageId] = useState(null);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [email_template_type_id, setEmailTemplateTypeId] = useState("");
    const [showHtml, setShowHtml] = useState(false); 

    // Email template types list
    const [Email, setEmail] = useState([]);

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            [{ font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["blockquote", "code-block"],
            ["link", "image", "video"],
            ["clean"],
        ],
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
    };

    useEffect(() => {
        localStorage.setItem("EmailTemp", JSON.stringify({ title, body, subject }));
    }, [subject, title, body]);

    useEffect(() => {
        const fetchEmail = async () => {
            try {
                const token = localStorage.getItem("userToken");
                if (!token) {
                    console.error("No token found in localStorage!");
                    return;
                }
                const response = await fetch(`${api_url}/email-template-types`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                setEmail(data.data);
            } catch (error) {
                console.error("Error fetching email templates:", error);
            }
        };
        fetchEmail();
    }, []);

    const handleLanguageChange = (e) => {
        setLanguageId(e.target.value);
    };

    const handleAddEmail = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start
    
        if (!language_id) {
            showToast(t("SelectLanguageError"), "error");
            return;
        }
        
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-templates`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    title,
                    body,
                    subject,
                    language_id,
                    email_template_type_id,
                }),
            });
    
            if (response.ok) {
                showToast(t("EmailTemplateAddedSuccess"), "success");
                localStorage.setItem("EmailTemp", JSON.stringify());
                navigate("/emailtemplates");
            } else {
                showToast(t("FailedToAddEmailTemplate"), "error");
            }
        } catch (error) {
            showToast(t("SomethingWentWrong"), "error");
        } finally {
            setIsLoading(false); // Loader stop
        }
    };

    return (
        <div className="m-4">
            <div className="header">
                <div className="pageTitle">{t("AddEmailTemplate")}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>

            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="custom-tabs">
                <Tab eventKey="details" title={t('Add')}>
                    <form className="addUserWrapper" onSubmit={handleAddEmail}>
                        <div className="my-3">
                            <label className="form-label">{t("Title")}</label>
                            <input type="text" className="form-control" onChange={(e) => setTitle(e.target.value)} value={title} required/>
                        </div>

                        {/* Quill Editor with Source Code Toggle */}
                        <div style={{position: "relative", height:"410px"}}>
                            <div className="d-flex justify-content-between">
                            <label>{t("EmailBody")}</label>
                            <Button
                                type="button"
                                onClick={() => setShowHtml(!showHtml)}
                                className="btn btn-secondary mb-2 "
                            >
                                {showHtml ? t("SwitchtoEditor") : t("ViewHTMLSource")}
                            </Button>
                            </div>

                            {showHtml ? (
                                <textarea
                                    value={body}
                                    onChange={(e) => setBody(e.target.value)}
                                    style={{
                                        width: "100%",
                                        height: "300px",
                                        fontFamily: "monospace",
                                    }}
                                />
                            ) : (
                                <ReactQuill
                                    value={body}
                                    onChange={setBody}
                                    modules={modules}
                                    formats={[
                                        "header", "font", "size",
                                        "bold", "italic", "underline", "strike",
                                        "color", "background",
                                        "link", "image", "video",
                                        "script",
                                        "list", "bullet", "indent",
                                        "align",
                                        "blockquote", "code-block"
                                    ]}
                                    style={{ height: "300px" ,  width: "100%"}}
                                />
                            )}
                        </div>

                        <div className="subject">
                            <label className="form-label">{t('Subject')}</label>
                            <input type="text" className="form-control" onChange={(e) => setSubject(e.target.value)} value={subject} required/>
                        </div>

                        <div className="mb-3 mt-3">
                            <label className="form-label">{t('ChooseType')}</label>
                            <select className="form-control" onChange={(e) => setEmailTemplateTypeId(e.target.value)} value={email_template_type_id} required>
                                <option value="">{t('SelectType')}</option>
                                {Email.map((template) => (
                                    <option key={template.id} value={template.id}>{template.title_en}</option>
                                ))}
                            </select>
                        </div>

                        <h1>{t('Language')}</h1>
                        <div className="language-options">
                            <label>
                                <input type="radio" name="radioBtn" value="1" onChange={handleLanguageChange} />
                                &nbsp;&nbsp;{t('English')}
                            </label>
                            <label>
                                <input type="radio" name="radioBtn" value="2" onChange={handleLanguageChange} />
                                &nbsp;&nbsp;{t('Arabic')}
                            </label>
                        </div>

                        <div className="mt-3">
                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                    {isLoading ? (
                                        <>
                                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                                            {t('Adding...')}
                                        </>
                                    ) : (
                                        t('AddEmailTemplate')
                                    )}
                                </button>
                        </div>
                    </form>
                </Tab>
                <Tab eventKey="Preview" title={t('Preview')}>
                    <PreviewEmail />
                </Tab>
            </Tabs>
        </div>
    );
};

export default AddEmailTemplate;
