import React, { useState } from "react";
import { Button, Spinner, Tabs, Tab } from "react-bootstrap";
import { api_url } from '../../constant/constant';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { useNavigate } from 'react-router-dom';

const UploadCertificate = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
        showToast(t("Pleaseselectafilefirst"), "error");
        return;
    }

    const token = localStorage.getItem("userToken"); 
    const formDataUpload = new FormData();
    formDataUpload.append("image", file);

    setUploading(true);

    try {
        const response = await fetch(api_url + `/certificate-templates/upload-image`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`, 
            },
            body: formDataUpload,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        showToast(t("CertificateUploadSuccessfully"), "success");
        navigate(-1);

    } catch (error) {

        console.error("Error uploading file:", error);
        showToast(t("Fileuploadfailed"), "error");
    } finally {
        setUploading(false);
    }
};


  return (
    <div className="addCertificateContainer" style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", marginLeft: "19px", marginTop: "20px" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
        <div className="pageTitle">{t('UploadCertificate')}</div>
        <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
      </div>

      <Tabs defaultActiveKey="AddCertificateManager" className="mb-3">
        <Tab eventKey="AddCertificateManager" title={t("UploadCertificate")}>
          <form className="row g-3">
            <div className="col-12">
              <label className="form-label">{t("SelectFile")}</label>
              <input
                type="file"
                className="form-control"
                accept=".pdf,.jpg,.png,.docx"
                onChange={handleFileChange}
              />
            </div>

            <div className="mt-2">
              <button type="button" className="btn btn-secondary" onClick={handleUpload} disabled={uploading}>
                {uploading ? (
                  <>
                    <Spinner animation="border" size="sm" role="status" className="me-2" />
                    {t('Uploading...')}
                  </>
                ) : (
                  t('UploadCertificate')
                )}
              </button>
            </div>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default UploadCertificate;
