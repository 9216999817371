import { Button,Spinner } from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditSurveyDetail.css';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";

const EditSurveyDetail = ({ id }) => {
    const { t } = useTranslation();
    const [survey_id] = useState(id);
    const [lesson_types, setSurveyTypes] = useState([]);


    const [isLoading, setIsLoading] = useState(false); // Loader state


    const fetchSurveyTypes = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/surveys/${survey_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);                
            }

            const result = await response.json();
            setSurveyTypes(result);

        } catch (error) {
            console.error(t("errorFetchingData"), error);
            showToast(t("errorFetchingData"), "error");
        }
    };

    const fetchSurvey = async () => {

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/surveys/${survey_id}`, {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                showToast(t("errorFetchingSurveyDetails"), "error");
            }            

            const data = await response.json();

            setFormData({
                ...formData,
                ["title_en"]: data.title_en,
                ["title_ar"]: data.title_ar,
                ["description_en"]: data.description_en,
                ["description_ar"]: data.description_ar,
            });

        }
        catch (err) {
            showToast(t("somethingWentWrongRefresh"), "error");
        }        
    }

    useEffect(() => {
        fetchSurveyTypes();
        fetchSurvey();
    }, []);

    const [formData, setFormData] = useState({
        title_en: "",
        title_ar: "",
        description_en: "",
        description_ar: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleUpdateSurvey = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/surveys/${survey_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) {
                showToast(t("surveyUpdateSuccess"), "success");
            } else {
                showToast(t("surveyUpdateFailed"), "error");
            }
        } catch (error) {
            console.error("Error updating survey:", error);
            showToast(t("somethingWentWrongRefresh"), "error");
        }finally {
            setIsLoading(false); // Loader stop
        }
    }

    return (
        <div className="py-4" style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'0px 15px'}}>
            <form onSubmit={handleUpdateSurvey} >

                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Title(English)')}</label>
                            <input type="text" name="title_en" className="form-control" onChange={handleChange} value={formData.title_en} required/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Title(Arabic)')}</label>
                            <input type="text" name="title_ar" className="form-control" onChange={handleChange} value={formData.title_ar} required/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Description(English)')}</label>
                            <textarea
                                name="description_en"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.description_en}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Description(Arabic)')}</label>
                            <textarea
                                name="description_ar"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.description_ar}
                                required
                            />
                        </div>
                    </div>
                </div>


                <button type="submit" className="btn btn-primary" disabled={isLoading} onClick={handleUpdateSurvey}>
                                                       {isLoading ? (
                                                           <>
                                                               <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                               {t('Update...')}
                                                           </>
                                                       ) : (
                                                           t('UpdateSurvey')
                                                       )}
                                                   </button>
            </form>
        </div>
    );

}

export default EditSurveyDetail;