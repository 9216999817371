import React, { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../../Components/ShowToast/ToastNotification";

const CustomerName = () => {
    const { t } = useTranslation();
    const [customerName, setCustomerName] = useState(""); // State to store the customer name
    const [loading, setLoading] = useState(false);

    const fetchCustomerData = async () => {
        setLoading(true); // Start loading
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/customer", {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                showToast(t("failedtofetchdata"), "error");
                return;
            }            

            const data = await response.json();
            if (data.status === "success" && data.data.length > 0) {
                // Access the first customer from the data array
                setCustomerName(data.data[0].name);
            } else {
                showToast(t("nocustomerdatafound"), "warning");
            }
            } catch (err) {
                showToast(t("erfetchingdata"), "error");
            } finally {
            setLoading(false); // Stop loading regardless of success or failure
        }
    };

    useEffect(() => {
        fetchCustomerData();
    }, []);

    return (
        <div className="py-4" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '0px 15px' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-3">

                        {loading ? (
                            <div className='d-flex justify-content-center align-items-center my-5'>
                                <div className='spinner-border' role='status' style={{
                                    borderTopColor: "#6897CB",
                                    borderBottomColor: "#6897CB",
                                    borderLeftColor: "#6897CB",
                                    marginTop: "50px"
                                }}>

                                </div>
                            </div>
                        ) : (
                            <div>
                                <label className="form-label">{t('CustomerName')}</label>
                                <div
                                    style={{
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        padding: '10px',
                                        backgroundColor: '#f9f9f9',
                                    }}
                                >
                                    {customerName || t('No customer name available')}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerName;
