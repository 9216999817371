import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Spinner } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import "./AddLcLesson.css";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";

const AddLcLesson = ({ parentfetchLcLesson }) => {
  // const {question_id} = useParams(); // Get questions_id from the URL
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const [data, setLcLesson] = useState([]);
  const [links, setLinks] = useState([]);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const LcLesson_id = searchParams.get("LearningManager_id");
  const [formData, setFormData] = useState({
    // lesson_ids: "",

  });


  const handleClose = () => {
    setShow(false); // Corrected: Set as boolean `false`
  };

  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    const { options } = event.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    setFormData({
      ...formData,
      lesson_ids: selectedValues, // Update with selected values
    });
  };
  const fetchLessons = async (url) => {

    var page = 1;
    if (url !== '') {
      const temp = url.split('?page=');
      var page = temp[1];
    }


    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(api_url + '/lessons?page=' + page, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setLcLesson(result.data);
      setLinks(result.links);
    } catch (error) {
      showToast("Error fetching data:", "error");

    }
  };

  useEffect(() => {
    fetchLessons('');
  }, []);

  const handleAddLcLesson = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Loader start
  
    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/learning-campaigns-lessons/${LcLesson_id}/attach-lessons`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        });

        const result = await response.json();

        if (response.ok) {
            showToast(t("LcLessonAddedSuccess"), "success");
            navigate(-1);
        } else {
            if (result.status === "error") {
                showToast(t(result.message), "error"); // Translate API error messages too
            } else {
                showToast(t("UnexpectedError"), "error");
            }
        }
    } catch (error) {
        showToast(t("SomethingWentWrong"), "error");
    } finally {
        setIsLoading(false); // Loader stop
    }
};

  return (
    <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", margin: "20px", marginTop: "20px" }}>
       <div className="d-flex justify-content-end mb-3">
                      <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
                  </div>

      <Tabs defaultActiveKey="Add Video" className="mb-3">
        <Tab eventKey="Add Video" title={t("AttachLesson")}>
          <form onSubmit={handleAddLcLesson}>

            <div className="mb-3">
              <label className="form-label">{t('SelectLesson')}</label>
              <select
                name="lesson_ids"
                className="form-control"
                onChange={handleChange}
                value={formData.lesson_ids} // Ensure `formData.user_ids` is an array
                multiple // Enables multi-select
              >
                <option value="" disabled>
                  {t('SelectLesson')} {/* Adjust placeholder text */}
                </option>
                {data.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.title_en} ({user.code}) {/* Adjust based on actual field */}
                  </option>
                ))}
              </select>

            </div>
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" role="status" className="me-2" />
                  {t('Adding...')}
                </>
              ) : (
                t('AttachLesson')
              )}
            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AddLcLesson;
