import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { Tabs, Tab } from "react-bootstrap";
import { api_url } from '../../constant/constant';
import { useParams, useNavigate } from 'react-router-dom';  // useNavigate ko import kiya hai
import EditVideo from '../../Components/EditVideoModal/EditVideo';
import Videos from '../Videos/Videos';
import EditLesson from '../../Components/EditLesson/EditLesson';
import Questions from '../Questions/Questions';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import LessonQuiz from'../../Components/LessonQuizModal/LessonQuiz';
import { Button } from "react-bootstrap";
import PlayVideo from '../../Components/PlayVideoModal/PlayVideo';
// import './LessonDetail.css';

const LessonDetail = () => {

    const { t } = useTranslation();
    const { lesson_id } = useParams();
    const [key, setKey] = useState("details");
    const navigate = useNavigate();  

    // State variables
    const [data, setVideos] = useState([]);
    const [links, setLinks] = useState([]);


    return (
        <div className="p-4">

            {/* Back Button & Lesson Details Title */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('VideoDetails')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t("Back")}</Button>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="details" title={t("EditVideo")}>
                    <div>
                        <EditVideo lesson_id={lesson_id}  />
                    </div>
                </Tab>
                <Tab eventKey="Playvideo" title={t("Playvideo")}>
                    <div>
                        <PlayVideo lesson_id={lesson_id}  />
                    </div>
                </Tab>

            </Tabs>

        </div>
    );
};

export default LessonDetail;
