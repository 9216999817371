import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddSurvey from '../../Components/AddSurveyModal/AddSurvey';
// import EditSurvey from '../../Components/EditSurveyModal/EditSurvey';
import { useNavigate } from 'react-router-dom';
import './Surveys.css';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { NavLink } from 'react-router-dom';

const Surveys = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [data, setSurveys] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);




    const [showModal, setShowModal] = useState(false);
    const [selectedSurveyId, setSelectedSurveyId] = useState(null);
    const handleDelete = async (id) => {
        setLoading(true);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/surveys/" + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                showToast(t("surveydeletesuccess"), "success");
                fetchSurveys('');
                setShowModal(false);
              } else {
                showToast(t("surveydeletefailed"), "error");
                setShowModal(false);
              }
              
              } catch (error) {
                setShowModal(false);
                showToast(t("surveydeleteerror"), "error");
                setLoading(false);
              }
               finally {
            setLoading(false);

        }
    };


    useEffect(() => {
        if (searchValue !== '') {
            const filteredData = data.filter((item) =>
                item.title_en.toLowerCase().includes(searchValue.toLowerCase())
                || item.title_ar.toLowerCase().includes(searchValue.toLowerCase())
                || item.created_at.toLowerCase().includes(searchValue.toLowerCase())
            );
            setSurveys(filteredData);
        } else {
            fetchSurveys('');
        }
    }, [searchValue]);


    const fetchSurveys = async (url) => {
        setLoading(true);
        var page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            var page = temp[1];
        }


        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/surveys?page=' + page, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              
              const result = await response.json();
              setSurveys(result.data);
              setLinks(result.links);
              
              } catch (error) {
                console.error('Error fetching data:', error);
                showToast(t("surveyfetcherror"), "error");
                setLoading(false);
              }
              finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSurveys('');
    }, []);

    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'title_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Title(English)')}
                    </div>
                ),
                colName: t('Title(English)'),
            },
            {
                accessorKey: 'title_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Title(Arabic)')}
                    </div>
                ),
                colName: t('Title(Arabic)'),
            },

            {
                accessorKey: 'created_at',
                header: () => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <img src="/images/fi_clock.png" alt="Date of Creation" />{t('DateCreated')}
                    </div>
                ),

                cell: ({ row }) => {
                    const rawDate = row.original.created_at; // Get the raw date string
                    const formattedDate = new Date(rawDate).toLocaleDateString('en-US', {

                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',

                    });

                    return formattedDate; // Return formatted date
                },
                colName: t('DateCreated'),
            },
            {
                accessorKey: 'id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue();
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <div className="iconDel" onClick={(e) => {e.stopPropagation(); setSelectedSurveyId(id); setShowModal(true) }}>
                                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: t('Action'),
            },
        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const handleSurveyRowClick = (id) => {
        navigate(`/surveys/${id}/detail`)
    }

    return (
        <div className="p-4">

            <div className="pageTitle mb-4">{t('AllSurveys')}</div>

            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('SearchSurvey')}</label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder={t("Searchbytitle")} aria-label="Search"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        {/* <AddSurvey parentfetchSurveys={fetchSurveys} /> */}
                         <NavLink className="btnWhiteGradient mt-3" to="/Add-Survey">
                              <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddSurvey')}
                        </NavLink>
                    </div>
                </div>
            </div>

            {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center my-5">
                    <div className="spinner-border" role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>

                    </div>
                </div>
            ) : (

                <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                    {window.innerWidth < 768 ? (
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row"
                                onClick={row.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <table className="table table-bordered table-hover">
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}
                                                className='rowAnchor'
                                                onClick={cell.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}

            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchSurveys(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                <Modal.Title>{t("ConfirmDelete")}</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            {t("Areyousureyouwanttodeletethissurvey?")}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="danger" onClick={()=>handleDelete(selectedSurveyId)} disabled={loading}>
                                {loading ? t("Deleting...") : t("Delete")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
        </div>
    );
}

export default Surveys;