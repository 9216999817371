import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { Tabs, Tab, Button } from "react-bootstrap";
import { api_url } from '../../constant/constant';
import { useNavigate, useParams } from 'react-router-dom';
import EditSurveyDetail from '../../Components/EditSurveyDetail/EditSurveyDetail';
import SurveyQuestions from '../SurveyQuestion/SurveyQuestion';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../Components/ShowToast/ToastNotification';


import './SurveyDetail.css';

const SurveyDetail = () => {

    const { t } = useTranslation();
    const { survey_id } = useParams();
    const [key, setKey] = useState("details");

    // State variables
    const [data, setVideos] = useState([]);
    const [links, setLinks] = useState([]);

    const navigate = useNavigate();

    // Handle delete user action
    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (response.ok) {
                showToast(t("videodeletedsuccessfully"), "success");
            } else {
                showToast(t("failedtodeletevideo"), "error");
            }
            } catch (error) {
                showToast(t("somethingwentwrong"), "error");
            }            
    };

    // Fetch videos data with pagination





    const getLanguageName = (code) => {
        switch (code) {
            case 'en':
                return 'English';
            case 'ar':
                return 'Arabic';
            case 'ar':
                return 'Arabic';
            default:
                return 'Unknown';
        }
    }

    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'size',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Size')}
                    </div>
                ),
                colName: 'Size',
            },
            {
                accessorKey: 'language',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Language')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    return (
                        getLanguageName(getValue())
                    );
                },
                colName: 'Language',
            },

            {
                accessorKey: 'id', // Correct accessor key
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue(); // Extract the value of the 'id' column
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>

                            <div className="iconPlay">
                                <i class="bi bi-play-btn"></i> {t('Play')}
                            </div>

                            {/* EditVideo component with required props */}
                            {/* <EditVideo lesson_id={lesson_id} id={id} parentfetchVideo={fetchVideos} /> */}

                            {/* Delete button */}
                            <div className="iconDel" onClick={() => handleDelete(id)}>
                                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: 'Action', // Correct property spelling
            },

        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('SurveyDetails')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="details" title={t('SurveyDetails')}>
                    <div>
                        <EditSurveyDetail id={survey_id} />
                    </div>
                </Tab>

                {/* <Tab eventKey="videos" title="Videos">
                <div>
                    <Videos />
                </div>
            </Tab> */}

                <Tab eventKey="questions" title={t('Questions')}>
                    <div>
                        <SurveyQuestions  />
                    </div>
                </Tab>
            </Tabs>

        </div>
    );
};

export default SurveyDetail;