import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button,Spinner } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { showToast } from "../ShowToast/ToastNotification";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const AddLessonQuiz = ({ parentfetchQuestion }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
      const [isLoading, setIsLoading] = useState(false); // Loader state
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    question_ids: [], // Ensure this key exists to avoid undefined issues
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    const { options } = event.target;
    const selectedQuestions = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => parseInt(option.value));

    setFormData((prevState) => ({
      ...prevState,
      question_ids: selectedQuestions,
    }));
  };

  // ✅ Function to Show Correct Placeholder
  const getPlaceholderText = () => {
    return formData.question_ids.length > 0 ? `${formData.question_ids.length} selected` : t("ClickToSelect");
  };

  const fetchLessonQuizModal = async () => {
    console.log("Fetching lesson quiz data...");
    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/lessons/${id}/quiz/available-questions`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Fetched Questions:", result);

        if (result.availableQuestions && result.availableQuestions.length > 0) {
            setData(result.availableQuestions);
        } else {
            setData([]);
        }
    } catch (error) {
        showToast(t("quizFetchError", { error: error.message }), "error");
    }
};



  useEffect(() => {
    if (id) fetchLessonQuizModal();
  }, [id]);

  const handleAddLessonQuiz = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Loader start

    console.log("formData:", formData);

    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/lessons/${id}/quiz`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        });

        if (response.ok) {
            showToast(t("lessonQuizSuccess"), "success");
            setFormData({ question_ids: [] });
        } else {
            const result = await response.json();
            console.log("response", result.message);
            showToast(t("lessonQuizError", { message: result.message }), "error");
        }
    } catch (error) {
        console.error("Error adding lesson quiz:", error);
        showToast(t("lessonQuizUnknownError"), "error");
    } finally {
        setIsLoading(false); // Loader stop
    }
};


  return (
    <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", marginLeft: "19px", marginTop: "20px" }}>
       <div className="d-flex justify-content-end mb-3">
                      <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
                  </div>

      <Tabs defaultActiveKey="Add Video" className="mb-3">
        <Tab eventKey="Add Video" title={t("AttachLessonQuiz")}>
          <form onSubmit={handleAddLessonQuiz}>
            <div className="mb-3">
              <label className="form-label">{t("SelectLessonQuiz")}</label>
              <select name="question_ids" className="form-control" onChange={handleChange} value={formData.question_ids} multiple style={{ height: "300px" }}>
                {/* Placeholder Handling */}
                <option value="" disabled>
                  {getPlaceholderText()}
                </option>

                {Object.values(data).length > 0 ? (
                  Object.values(data).map((question) => (
                    <option key={question.id} value={question.id}>
                      {question.question_ar}
                      <br />
                      {question.question_en}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    {t("NoAvailableQuizzes")}
                  </option>
                )}
              </select>
            </div>
                 <button type="submit" className="btn btn-primary" disabled={isLoading} >
                                                          {isLoading ? (
                                                              <>
                                                                  <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                                  {t('Attach...')}
                                                              </>
                                                          ) : (
                                                              t('AttachLessonQuiz')
                                                          )}
                                                      </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AddLessonQuiz;
