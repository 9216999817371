import { Modal, Button,Spinner } from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditVideo.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { useLocation } from "react-router-dom";

const EditVideo = ({parentfetchVideo}) => {
const location = useLocation();
        const searchParams = new URLSearchParams(location.search);
        const lesson_id = searchParams.get("lesson_id");
        const video_id = searchParams.get("video_id");
    const [isLoading, setIsLoading] = useState(false); // Loader state

    // const { lesson_id } = useParams(); // Assuming `id` is a valid variable available in scope
    // const [video_id] = useState();
    const [show, setShow] = useState(false); // Correct syntax for declaring state
    const { t } = useTranslation();
    

    const fetchVideos= async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
			const response = await fetch(`${api_url}/lessons/${lesson_id}/videos/${video_id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
	  
              if (!response.ok) {
                showToast(t("errorFetchingVideoDetails"), "error");
            }
            
	  
			const data = await response.json();

            setFormData({
                ...formData,
                ["video"]: data.video,
                ["language"]: data.language,
            });

		} 
		catch (err) {
            console.error("Unexpected error:", err);
            showToast(t("somethingWentWrong"), "error");
        }        
    }

    
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        fetchVideos();
        setShow(true);
    }

    const [formData, setFormData] = useState({
        video: "",
        language: "",
      });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleUpdatevideo = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/videos/${video_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
            

            if (response.ok) {
                showToast(t("videoUpdateSuccess"), "success");
                parentfetchVideo('');
            } else {
                showToast(t("videoUpdateFailed"), "error");
            }
        } catch (error) {
            console.error("Unexpected error updating video:", error);
            showToast(t("unexpectedError"), "error");
        }finally {
            setIsLoading(false); // Loader stop
        }
    }
    
	const handleFileChange = (e) => {
		setFormData({
		  ...formData,
		  video: e.target.files[0],
		});
	};

    return (
        <div className="py-4"  style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'0px 15px'}}>

<div className="row">
            <div className="mb-3">
            <label className="form-label">{t('Language')}</label>
            <select className="form-select" aria-label="Language" name="language" onChange={handleChange}required value={formData.language}>
                <option value="en">{t('English')}</option>
                <option value="ar">{t('Arabic')}</option>
            </select>
            </div>
            <div className="mb-3">
            <label className="form-label">{t('Video')}</label>
            <input
            type="file"
            className='form-control'
            accept="video/*"
            onChange={handleFileChange}
            required
            />
        </div>
            </div>

                     <button type="submit" className="btn btn-primary" disabled={isLoading}  onClick={handleUpdatevideo} >
                                                      {isLoading ? (
                                                          <>
                                                              <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                              {t('Update...')}
                                                          </>
                                                      ) : (
                                                          t('UpdateVideo')
                                                      )}
                                                  </button>
        </div>
      );

}

export default EditVideo;