import { Button,Spinner } from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";

const EditDepartment = ({ id }) => {
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const { t } = useTranslation();
    const [userId] = useState(id);
    const [formData, setFormData] = useState({
        name: "",
    });


    const fetchDepartmentData = async () => {

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/admin/main/departments/" + userId, {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                showToast(t("errorFetchingUserDetails"), "error");
              }
              

            const data = await response.json();

            setFormData({
                ...formData,
                ["name"]: data.name,

            });

        }
        catch (err) {
            showToast(t("somethingWentWrongRefresh"), "error");
          }          
    }

    useEffect(() => {
        fetchDepartmentData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEditdepartment = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/admin/main/departments/" + userId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) {
                showToast(t("departmentUpdatedSuccessfully"), "success");
                fetchDepartmentData('');
              } else {
                showToast(t("failedToUpdateDepartment"), "error");
                fetchDepartmentData('');
              }
              } catch (error) {
                showToast(t("somethingWentWrongRefresh"), "error");
              }
              finally {
            setIsLoading(false); // Loader stop
        }
    }

    return (
        <div className="py-4"  style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'0px 15px'}}>


            <div className="row">
                <div className="col-md-12">
                    <div className="mb-3">
                        <label className="form-label">{t('EditDepartmentName')}</label>
                        <input type="text" name="name" className="form-control" onChange={handleChange} value={formData.name} />
                    </div>
                </div>
            </div>


          <button type="submit" className="btn btn-primary" disabled={isLoading} onClick={handleEditdepartment}>
                                              {isLoading ? (
                                                  <>
                                                      <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                      {t('Update...')}
                                                  </>
                                              ) : (
                                                  t('Update Department')
                                              )}
                                          </button>
        </div>
    );

}

export default EditDepartment;