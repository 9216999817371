import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddPhishingCompaign from '../../Components/AddPhishingCompaignModal/AddPhishingCompaign';
import { useTranslation } from 'react-i18next';
// import EditLDAPServer from '../../Components/EditLDAPServerModal/EditLDAPServer';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { NavLink } from 'react-router-dom';

const PhishingCompaign = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [links, setLinks] = useState([]);
    const [data, setPhishingCompaign] = useState([]); // Filtered data
    const [allData, setAllData] = useState([]); // Original API data
    const [searchVal, setSearchVal] = useState('');
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (searchVal !== '') {
            const filteredData = allData.filter((item) =>
                (item.name_ar?.toLowerCase() || '').includes(searchVal.toLowerCase()) ||
                (item.name_en?.toLowerCase() || '').includes(searchVal.toLowerCase()) ||
                (item.start_date?.toLowerCase() || '').includes(searchVal.toLowerCase()) ||
                (item.template_id?.toLowerCase() || '').includes(searchVal.toLowerCase()) ||
                (item.execute_url?.toLowerCase() || '').includes(searchVal.toLowerCase())
            );
            setPhishingCompaign(filteredData);
        } else {
            setPhishingCompaign(allData); // Reset data when search is cleared
        }
    }, [searchVal, allData]);
    


    const handleSendEmail = async (campaignId) => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/phishing-campaign-settings/${campaignId}/send-emails`, {  // Ensure correct endpoint
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const result = await response.json();
            if (response.ok) {
                showToast(t("emailsentsuccessfully"), "success");
            } else {
                showToast(t("failetosendemail"), "error");
            }
            } catch (error) {
                console.error("Error sending email:", error);
                showToast(t("anerroroccurredwhilesendingtheemail"), "error");
            }            
    };

    const fetchPhishingCompaign = async (url) => {
    setLoading(true);
    let page = 1;
    if (url !== '') {
        const temp = url.split('?page=');
        page = temp[1] ? temp[1] : 1;
    }

    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/phishing-campaigns?page=${page}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setAllData(result.campaigns || []); // Store original fetched data
        setPhishingCompaign(result.campaigns || []); // Update displayed data
    } catch (error) {
        console.error("Error fetching Phishing Campaign data:", error);
        showToast(t("errorfetchingphishingcampaigndata"), "error");
        setPhishingCompaign([]);
        setAllData([]);
    } finally {
        setLoading(false);
    }
};

// Ensure API fetch happens on component mount
useEffect(() => {
    fetchPhishingCompaign('');
}, []);





    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Namear')}
                    </div>
                ),
                colName: t('Namear'),

            },
            {
                accessorKey: 'name_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Nameen')}
                    </div>
                ),
                colName: t('Nameen'),
            },
            {
                accessorKey: 'start_date',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('StartDate')}
                    </div>
                ),
                colName:t('StartDate'),

            },
            {
                accessorKey: 'end_date',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('EndDate')}
                    </div>
                ),
                colName:t('EndDate'),

            },
            {
                accessorKey: 'start_time',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('StartTime')}
                    </div>
                ),
                colName:t('StartTime'),

            },
            {
                accessorKey: 'enable_attachment',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Attachment')}
                    </div>
                ),
                colName: t('Attachment'),

            },
            //   {
            //     accessorKey: 'save_submitted_data',
            //     header: () => (
            //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            //           <i class="bi bi-clipboard-data"></i>{t('Save Submitted Data')} 
            //         </div>
            //     ),
            //     colName: 'save_submitted_data',

            // },
            {
                accessorKey: 'save_submitted_data',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('SubmittedData')}
                    </div>
                ),
                colName: t('SubmittedData'),

            },
            // {
            //     accessorKey: 'attachment',
            //     header: () => (
            //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            //           <i class="bi bi-clipboard-data"></i>{t('attachment')} 
            //         </div>
            //     ),
            //     colName: 'attachment',

            // },



            {
                accessorKey: 'id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ row }) => {
                    const id = row.original.id;

                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <button
                                onClick={(e) => {e.stopPropagation(); handleSendEmail(id)}}
                                style={{
                                    backgroundColor: "gray",
                                    color: "white",
                                    padding: "5px 10px",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                }}
                            >
                                {t('SendEmail')}
                            </button>

                        </div>

                    );
                },
                colName: t('Action'),
            }
        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    const handleSurveyRowClick = (id) => {
        navigate(`/phishinhcompaigndetail/${id}/phishinhcompaigndetail`)
    }

    return (
        <div className="p-4">
            <div className="pageTitle mb-4">{t('AllPhishingCampaign')}</div>
            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('SearchPhishingCampaign')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("Searchbyname")}
                                aria-label="Search by name"
                                value={searchVal} // Bind input value to searchVal state
                                onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        {/* <AddPhishingCompaign parentfetchPhishingCompaign={fetchPhishingCompaign} /> */}
                             <NavLink className="btnWhiteGradient mt-3" to="/Add-Phishing-Compaign">
                                     <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddPhishingCampaign')}
                            </NavLink>
                    </div>
                </div>
            </div>



            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}></div>
                </div>
            ) : (
                <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                    {window.innerWidth < 768 ? (
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row"
                                onClick={row.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <table className="table table-bordered table-hover">
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}
                                                className='rowAnchor'
                                                onClick={cell.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}


            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchPhishingCompaign(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
};

export default PhishingCompaign;
