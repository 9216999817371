import React, { useState } from 'react';
import { Tabs, Tab, Button } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditLDAPServer from '../../Components/EditLDAPServerModal/EditLDAPServer';// import Infologs from '../Info-logs/Infologs';
import Errorlogs from '../Error-logs/Errorlogs';
import Infologs from '../Info-logs/Infologs';

 const LDAPServerDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [key, setKey] = useState("details");

    const [data] = useState([]); // Mocked data for now

 

    

    return (
        <div className="p-4">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('LDAPServerDetail')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>

            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="details" title={t('EditLDAPServer')}>
                     <EditLDAPServer id={id}  />
                </Tab>
                <Tab eventKey="Info-logs" title={t('Info-Logs')}>
                     <Infologs />
                </Tab> 
                <Tab eventKey="Error-logs" title={t('Error-logs')}>
                    <Errorlogs />
                </Tab>
            </Tabs>
        </div>
    );
};

export default LDAPServerDetail;
