import React, { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { showToast } from "../ShowToast/ToastNotification";
import { useTranslation } from "react-i18next";

const TwoFactorSettings = () => {
  const [twoFactorSettings, setTwoFactorSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(""); // Store selected server_id
  const { t } = useTranslation();


  useEffect(() => {
    const getTwoFactorSettings = async () => {
      const settings = await fetchTwoFactorSettings();
      if (settings) {
        setTwoFactorSettings(settings);
        setSelectedServer(settings.server_id || ""); // Set initial server_id
        setLoading(false);
      } else {
        setError("Failed to load 2FA settings.");
        setLoading(false);
      }
      fetchEmailServers();
    };
    getTwoFactorSettings();
  }, []);

  const fetchEmailServers = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/email-servers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const result = await response.json();
      setServers(result.data.length > 0 ? result.data : []);
    } catch (error) {
      console.error("Error fetching email servers:", error);
      setServers([]);
    }
  };

  const fetchTwoFactorSettings = async () => {
    try {
      const response = await fetch(`${api_url}/customer/settings/2fa`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        return result.status === "success" ? result.data : null;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("userToken");
      const { server_name, ...updatedSettings } = twoFactorSettings; // Exclude server_name

      updatedSettings.server_id = selectedServer; // Update with selected server_id

      const response = await fetch(`${api_url}/customer-settings/2fa`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedSettings),
      });

      if (response.ok) {
        const result = await response.json();
        showToast(
          result.status === "success" ? t("2FASettingsUpdated") : t("FailedToUpdate2FASettings"), 
          result.status === "success" ? "success" : "error"
        );
      } else {
        showToast(t("FailedToUpdate2FA"), "error");
      }
    } catch (error) {
      console.error("Error updating 2FA:", error);
      showToast(t("ErrorUpdating2FA"), "error");
    }
  };

  if (loading) return <div>{t("Loading2FAsettings")}...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", marginLeft: "19px", marginTop: "20px" }}>
      <h3>{t("2FASettings")}</h3>
      <form onSubmit={handleSave}>
        <div className="row mb-3">
          <div className="col-sm-3">
            <label className="form-label">{t("CodeLength")}</label>
            <input
              type="number"
              className="form-control"
              value={twoFactorSettings?.two_factor_code_length || 6}
              onChange={(e) => setTwoFactorSettings({ ...twoFactorSettings, two_factor_code_length: Number(e.target.value) })}
            />
          </div>

          <div className="col-sm-3">
            <label className="form-label">{t("CodeExpiry(Minutes)")}</label>
            <input
              type="number"
              className="form-control"
              value={twoFactorSettings?.two_factor_code_expiry || 5}
              onChange={(e) => setTwoFactorSettings({ ...twoFactorSettings, two_factor_code_expiry: Number(e.target.value) })}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="mb-3" style={{ flex: 1 }}>
            <label className="form-label">{t("CodeExpiry(Minutes)")}</label>
            <select
              className="form-select"
              value={selectedServer}
              onChange={(e) => setSelectedServer(e.target.value)}
            >
              <option value="">{t("SelectServer")}</option>
              {servers.map((server, index) => (
                <option key={index} value={server.id}>
                  {server.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mb-3">
        <div className="col-sm-3 d-flex align-items-center" style={{ gap: "10px", whiteSpace: "nowrap" }}>
    <input
      type="checkbox"
      className="form-check-input "
      checked={Boolean(twoFactorSettings?.two_factor_enabled)}
      onChange={(e) =>
        setTwoFactorSettings({
          ...twoFactorSettings,
          two_factor_enabled: e.target.checked ? 1 : 0, // Ensure it's 1 or 0
        })
      }
    />
    <label className="form-label m-0">
      {twoFactorSettings?.two_factor_enabled ? t("2FAEnabled") : t("2FADisabled")}
    </label>
  </div>
</div>


        <button type="submit" className="btn btn-primary">{t("Update2FA")}</button>
      </form>
    </div>
  );
};

export default TwoFactorSettings;
