import { Modal, Button,Spinner } from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditAnswer.css';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { useLocation } from "react-router-dom";

const EditAnswers = ({  id, parentfetchAnswers }) => {
  const location = useLocation();
    const [isLoading, setIsLoading] = useState(false); // Loader state

  // console.log('lesson id is ' + id);
  // const { question_id } = useParams(question_id); // Assuming `id` is a valid variable available in scope
  const [answerid, setAnswerId] = useState(id); // Use camelCase for naming consistencyws
  const [show, setShow] = useState(false); // Correct syntax for declaring state
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(location.search);

  // const lesson_id = searchParams.get("lesson_id");
  // const { lesson_id } = useParams(); 
  const question_id = searchParams.get("question");
  useEffect(() => {
    if (id) {
      setAnswerId(id);
    }
  }, [id]);

  const fetchAnswers = async () => {

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/questions/${question_id}/answers/${answerid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        showToast(t("errorFetchingAnswerDetails"), "error");
        handleClose();
      }      

      const data = await response.json();
      setFormData({
        ...formData,
        ["answer_ar"]: data.answer.answer_ar,
        ["answer_en"]: data.answer.answer_en,
        ["is_correct"]: data.answer.is_correct
      });

    }
    catch (err) {
      showToast(t("somethingWentWrongRefresh"), "error");
      handleClose();

    }
  }

  useEffect(() => {
    // fetchQuestionTypes();
    fetchAnswers();
  }, []);


  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    answer_ar: "",
    answer_en: "",
    is_correct: ""
  });


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value, // Convert checkbox value to 1 or 0
    });
  };

  const handleUpdateAnswers = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Loader start

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/questions/${question_id}/answers/${answerid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        showToast(t("answerUpdatedSuccessfully"), "success");
        handleClose();
        parentfetchAnswers("");
      } else {
        showToast(t("failedToUpdateAnswer"), "error");
        handleClose();
      }
      } catch (error) {
        showToast(t("somethingWentWrongRefresh"), "error");
        handleClose();
      } finally {
        setIsLoading(false); // Loader stop
      }
      
  };


  return (
    <div>

      <div className="iconEdit" onClick={handleShow}>
        <img src="/images/edit.png" alt="Edit" />{t('Edit')}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="pageTitle">{t('EditAnswers')}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="addUserWrapper">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">{t('Answer(English)')}</label>
                <input type="text" name="answer_en" className="form-control" onChange={handleChange} value={formData.answer_en} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">{t('Answer(Arabic)')}</label>
                <input type="text" name="answer_ar" className="form-control" onChange={handleChange} value={formData.answer_ar} />
              </div>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_correct"
                id="flexCheckIndeterminate"
                onChange={handleChange}
                checked={formData.is_correct === 1} // Bind checkbox value
              />
              <label className="form-check-label" htmlFor="flexCheckIndeterminate">
                {t('Correct')}
              </label>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Close')}
          </Button>

           <button type="submit" className="btn btn-primary" disabled={isLoading} onClick={handleUpdateAnswers}>
                                            {isLoading ? (
                                                <>
                                                    <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                    {t('Update...')}
                                                </>
                                            ) : (
                                                t('UpdateAnswer')
                                            )}
                                        </button>
        </Modal.Footer>
      </Modal>
    </div>
  );

}

export default EditAnswers;