import React, { useState, useEffect, useTransition } from 'react';
import { api_url } from "../../constant/constant";  // Ensure api_url is defined
import { showToast } from '../ShowToast/ToastNotification';
import { useTranslation } from 'react-i18next';
const Captcha = () => {
  const [captchaSettings, setCaptchaSettings] = useState(null);  // Store captcha settings
  const [loading, setLoading] = useState(true); // Track loading state for captcha settings
  const [error, setError] = useState(null); // Track error if fetching settings fails
  const { t } = useTranslation();

  // Fetch captcha settings from API
  useEffect(() => {
    const getCaptchaSettings = async () => {
      const settings = await fetchCaptchaSettings();
      if (settings) {
        setCaptchaSettings(settings);  // Save the settings in the state
        setLoading(false);  // Set loading to false after the settings are fetched
      } else {
        setError('Failed to load captcha settings.'); // Set error if fetch fails
        setLoading(false); // Stop loading in case of an error
      }
    };
    getCaptchaSettings();
  }, []);  // This effect runs once when the component mounts

  // Fetch captcha settings from API
  const fetchCaptchaSettings = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/captcha-settings`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status === 'success') {
        return result.data;  // Return the data field from API response
      } else {
        showToast(t("failedToFetchCaptchaSettings"), "error");
      }
      } catch (error) {
        showToast(t("errorFetchingCaptchaSettings"), "error");
        return null;  // Return null in case of an error
      }
      
  };

  // Handle form submit to save updated settings
  const handleSave = async (e) => {
    e.preventDefault(); // Prevent default form submission

    const token = localStorage.getItem("userToken");
    try {
      const response = await fetch(`${api_url}/captcha-settings`, {
        method: 'PUT', // Use PUT to update existing settings
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(captchaSettings), // Send updated settings as the request body
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status === 'success') {
        showToast(t("captchaSettingsUpdated"), "success");
      } else {
        showToast(t("failedToSaveCaptchaSettings"), "error");
      }
      } catch (error) {
        console.error('Error saving captcha settings:', error);
        showToast(t("errorSavingCaptchaSettings"), "error");
      }
  };

  // Display loading state if captcha settings are still being fetched
  if (loading) {
    return <div>{t('Loadingcaptchasettings')}...</div>;
  }

  // Display error message if something went wrong
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", marginLeft: "19px", marginTop: "20px" }}>
    <h3>{t('CaptchaSettings')}</h3>
    <form onSubmit={handleSave}>
      <div className="row mb-3">
        <div className="col-sm-3">
          <label className="form-label">
            {t('LoginAttemptsThreshold')}
          </label>
          <input
            type="number"
            className="form-control"
            value={captchaSettings.login_attempts}
            onChange={(e) => setCaptchaSettings({ ...captchaSettings, login_attempts: Number(e.target.value) })}  // Ensure value is a number
          />
        </div>
  
        <div className="col-sm-3">
          <label className="form-label">
            {t('CodeValidDuration')}
          </label>
          <input
            type="number"
            className="form-control"
            value={captchaSettings.code_valid_duration}
            onChange={(e) => setCaptchaSettings({ ...captchaSettings, code_valid_duration: Number(e.target.value) })}  // Ensure value is a number
          />
        </div>
      </div>
  
      <div className="row mb-3">
      <div className="col-sm-3 d-flex align-items-center"style={{gap:'10px'}}>
  <input
    type="checkbox"
    className="form-check-input me-2" // Adds a small margin-right to create space between checkbox and label
    checked={captchaSettings.enabled_login}
    onChange={(e) => setCaptchaSettings({ ...captchaSettings, enabled_login: e.target.checked })}
  />
  <label className="form-label mb-0"> {/* 'mb-0' removes bottom margin from label */}
            {t('EnabledLogin')}
  </label>
</div>

  
<div className="col-sm-6 d-flex align-items-center" style={{gap:'10px'}}>
  <input
    type="checkbox"
    className="form-check-input me-2" // Adds a margin-right to create space between checkbox and label
    checked={captchaSettings.enabled_forgot_password}
    onChange={(e) => setCaptchaSettings({ ...captchaSettings, enabled_forgot_password: e.target.checked })}
  />
  <label className="form-label mb-0"> {/* 'mb-0' removes any bottom margin from the label */}
            {t('EnabledForgotPassword')}
  </label>
</div>

      </div>
  
      <button type="submit" className="btn btn-primary">{t('UpdateCaptcha')}</button>
    </form>
  </div>
  
  
  );
};

export default Captcha;
