import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { Tabs, Tab, Button } from "react-bootstrap";
import { api_url } from '../../constant/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import QuestionOption from '../../Components/QuestionOptionsModal/QuestionOptions'



const SurveyQuestionDetail = ({id}) => {
    console.log("id",id);
    const { t } = useTranslation();
    const { survey_id } = useParams();
    const [key, setKey] = useState("details");

    // State variables

    const navigate = useNavigate();

    // Handle delete user action
   

    // Fetch videos data with pagination





   

    return (
        <div className="p-4">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('QuestionOption')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                {/* <Tab eventKey="details" title={t('SurveyDetails')}>
                    <div>
                    </div>
                </Tab> */}

                {/* <Tab eventKey="videos" title="Videos">
                <div>
                    <Videos />
                </div>
            </Tab> */}

                <Tab eventKey="details" title={t('QuestionOption')}>
                    <div>
                        <QuestionOption id={id} />
                    </div>
                </Tab>
            </Tabs>

        </div>
    );
};

export default SurveyQuestionDetail;