import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button,Spinner } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
const AddPhishingCompaignUser = ({ parentfetchLcUserModal }) => {
  // const {question_id} = useParams(); // Get questions_id from the URL
      const [isLoading, setIsLoading] = useState(false); // Loader state
  
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const LcUserModal_id = searchParams.get("LcUserModal_id");
  // console.log("ID is:",LcUserModal_id);
  const { t } = useTranslation();
  const [data, setUsers] = useState([]);
  const [links, setLinks] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    user_ids: "",
  });


  const handleClose = () => {
    setShow(false); // Corrected: Set as boolean `false`
  };

  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    const { options } = event.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    setFormData({
      ...formData,
      user_ids: selectedValues, // Update with selected values
    });
  };
  const fetchLcUserModal = async (url) => {
    console.log("Fetching users from:", url);
    let page = 1;
    
    if (url) {
        const temp = url.split('?page=');
        page = temp[1] || 1; // Ensure a valid page number
    }

    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/users?page=${page}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        if (result?.data?.data?.length > 0) {
            setUsers(result.data.data);
            setLinks(result.links);  // Save pagination links
        } else {
            setUsers([]);
            showToast(t("noUsersFound"), "warning"); // Use translations
        }
    } catch (error) {
        console.error("Error fetching users:", error);
        showToast(t("failedToFetchUsers"), "error");
    }
};


  useEffect(() => {
    fetchLcUserModal('');
  }, []);

  const handleAddPhishingCompaignUser = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Loader start
  
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/phishing-campaigns/${LcUserModal_id}/attach-users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        showToast(t("selectedUsersAdded"), "success");
        handleClose();
        setFormData({ user_ids: "" });
        navigate(-1);
        setShow(false);
      } else {
        showToast(t("failedToAddUsers"), "error");
        handleClose();
      }
    } catch (error) {
      showToast(t("somethingWentWrong"), "error");
      handleClose();
    } finally {
      setIsLoading(false); // Loader stop
    }
  };
  

  return (
    <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", margin: '20px' }}>
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
      </div>

      <Tabs defaultActiveKey="Add Video" className="mb-3">
        <Tab eventKey="Add Video" title={t("AddUserstoPhishingCampaign")}>
          <form onSubmit={handleAddPhishingCompaignUser}>

            <div className="mb-3">
              <label className="form-label">{t('SelectPhishingCampaignUser')}</label>
              <select
                name="user_ids"
                className="form-control"
                onChange={handleChange}
                value={formData.user_ids} // Ensure `formData.user_ids` is an array
                multiple // Enables multi-select
              >
                <option value="" disabled>
                  {t('Select users')} {/* Adjust placeholder text */}
                </option>
                {data.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.username} ({user.email}) {/* Adjust based on actual field */}
                  </option>
                ))}
              </select>
            </div>
             <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                {isLoading ? (
                                                    <>
                                                        <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                        {t('Adding...')}
                                                    </>
                                                ) : (
                                                    t('AddPhishingCampaignUser')
                                                )}
                                            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AddPhishingCompaignUser;
