import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Spinner, Tab, Tabs } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { showToast } from "../ShowToast/ToastNotification";

const AddLcUserModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const LcUserModal_id = searchParams.get("LearningManager_id");

  const [isLoading, setIsLoading] = useState(false);
  const [data, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    user_ids: [], // Ensure it's always an array
  });
  const fetchLcUserModal = async () => {
    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/learning-campaigns-users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

        const result = await response.json();
        setUsers(result.data.data || []);
    } catch (error) {
        showToast(t("ErrorFetchingData"), "error");
    }
};

useEffect(() => {
    fetchLcUserModal();
}, []);

const handleChange = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    setFormData({ ...formData, user_ids: selectedValues });
};

const handleAddLcUserModal = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Ensure user_ids is an array
    if (!Array.isArray(formData.user_ids) || formData.user_ids.length === 0) {
        showToast(t("SelectAtLeastOneUser"), "error");
        setIsLoading(false);
        return;
    }

    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/learning-campaigns-users/${LcUserModal_id}/attach-users`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ user_ids: formData.user_ids }), // Ensure correct data format
        });

        const result = await response.json();
        console.log("API Response:", result);

        if (response.ok) {
            showToast(t("UsersAddedSuccess"), "success");
            setFormData({ user_ids: [] }); // Clear form after success
            navigate(-1);
        } else {
            showToast(t(result.message) || t("ErrorOccurred"), "error");
        }
    } catch (error) {
        showToast(t("SomethingWentWrong"), "error");
    } finally {
        setIsLoading(false);
    }
};

  return (
    <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px", margin: "20px" }}>
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
      </div>

      <Tabs defaultActiveKey="Add Video" className="mb-3">
        <Tab eventKey="Add Video" title={t("AddUserLC")}>
          <form onSubmit={handleAddLcUserModal}>
            <div className="mb-3">
              <label className="form-label">{t("SelectUser")}</label>
              <select name="user_ids" className="form-control" onChange={handleChange} multiple>
                {data.length === 0 ? (
                  <option disabled>{t("Nousersavailable")}</option>
                ) : (
                  data.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.username} ({user.email})
                    </option>
                  ))
                )}
              </select>
            </div>

            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" role="status" className="me-2" />
                  {t("Adding...")}
                </>
              ) : (
                t("AddUser")
              )}
            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AddLcUserModal;
