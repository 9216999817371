import { Modal, Button } from "react-bootstrap";
import React,{ useState } from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { Tab, Tabs } from "react-bootstrap";
import './AddPhishingCompaign';
const AddPhishingCompaign = ({ fetchPhishingCompaign }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        name_ar: "",
        name_en: "",
        start_date: "",
        end_date: "",
        start_time:"",
        save_submitted_data: false,
        enable_attachment: false,
    });


   const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    setFormData((prevData) => {
        let newData = { ...prevData, [name]: type === "checkbox" ? checked : value };

        // End date validation
        if (name === "end_date" && newData.start_date && new Date(value) <= new Date(newData.start_date)) {
            showToast(t("endDateError"), "error");
            return prevData;
        }

        // Start date validation (should be today or later)
        if (name === "start_date" && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
            showToast(t("startDateError"), "error");
            return prevData;
        }

        if (name === "end_time" && (!newData.start_time || value <= newData.start_time)) {
            showToast(t("endTimeError"), "error");
            return prevData;
        }
        

        return newData;
    });
};

    





    const handleAddPhishingCompaign = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(formData);
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/phishing-campaigns", {
                mode: "cors",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, // Replace token dynamically
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
    
            if (response.ok) {
                showToast(t("phishingCampaignAdded"), "success");
                handleClose();
                setFormData({
                    name_ar: "",
                    name_en: "",
                    start_date: "",
                    end_date: "",
                    start_time:"",
                    save_submitted_data: false,
                    enable_attachment: false,
                });
                navigate(-1);
                handleClose();
            }
            else {
                showToast(t("phishingCampaignFailed"), "error");
                handleClose();
            }
        }
        catch (error) {
            showToast(t("somethingWentWrong"), "error");
            handleClose();
        }finally {
            setLoading(false);
        }
    };
    



    return (
        <div className="addEmailContainer" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '26px 26px', margin: '20px' }}>

            <div className="d-flex justify-content-between mb-3">
                <div className="pageTitle">{t('AddPhishingCampaign')}</div>
                <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
            </div>

            <Tabs defaultActiveKey="AddPhishingCampaign" className="mb-3">
                <Tab eventKey="AddPhishingCampaign" title={t("AddPhishingCampaign")}>
                    <form onSubmit={handleAddPhishingCompaign} className="addEmailForm">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Namear')}</label>
                                    <input type="text" name="name_ar" className="form-control" onChange={handleChange} value={formData.name_ar} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Nameen')}</label>
                                    <input type="text" name="name_en" className="form-control" onChange={handleChange} value={formData.name_en} required />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('StartDate')}</label>
                                    <input type="date" name="start_date" className="form-control" onChange={handleChange} value={formData.start_date} required />                    </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('EndDate')}</label>
                                    <input type="date" name="end_date" className="form-control" onChange={handleChange} value={formData.end_date} required />                        </div>
                            </div>
                        </div>
                        
                            <div className="row">
                            <div className="col-md-6">
                            <div className="mb-3">
                                    <label className="form-label">{t('Start time')}</label>
                                    <input
                                        type="time"
                                        name="start_time"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.start_time}
                                        required
                                    />
                                </div>
                            </div>
                                <div className="col-md-6" style={{marginTop:'35px'}}>
                                    <div className="d-flex align-items-center gap-3">
                                        {/* Save Submitted Data Checkbox */}
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                name="save_submitted_data"
                                                className="form-check-input"
                                                // checked={formData.save_submitted_data === "true"}
                                                onChange={(e) => handleChange({
                                                    target: { name: "save_submitted_data", value: e.target.checked ? true : false }
                                                })}
                                            />
                                            <label className="form-check-label ms-2">{t('SaveSubmittedData')}</label>
                                        </div>

                                        {/* Enable Attachment Checkbox */}
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                name="enable_attachment"
                                                className="form-check-input"
                                                // checked={formData.enable_attachment === "true"}
                                                onChange={(e) => handleChange({
                                                    target: { name: "enable_attachment", value: e.target.checked ? true : false }
                                                })}
                                            />
                                            <label className="form-check-label ms-2">{t('EnableAttachment')}</label>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>

                            <div className="text-end mt-5">
    <button type="submit" className="btn btn-primary w-md-auto custom-button-spacing" disabled={loading}>
        {loading ? <span className="spinner-border spinner-border-sm me-2"></span> : null}
        {t('AddPhishingCompaign')}
    </button>
</div>


                    </form>
                </Tab>
            </Tabs>
        </div>
    );
}

export default AddPhishingCompaign;
