import React, { useState } from 'react';
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TermsAndConditions from './TermsAndConditions';

const TemsDetails = () => {
    const { id } = useParams(); // Ensure the route contains a parameter named 'id'

    const { t } = useTranslation();
    const [key, setKey] = useState("termsandconditions");
    // State variables



    return (
        <div className="p-4">

            <div className="pageTitle mb-4">{t('TermsAndConditions')}</div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >

                <Tab eventKey="termsandconditions" title={t('TermsAndConditions')}>
                    <div>
                        <TermsAndConditions />
                    </div>
                </Tab>

                {/* <Tab eventKey="customer" title={t('CustomersDetails')}>
                    <div>
                        <Customer />
                    </div>
                </Tab>

                <Tab eventKey="details" title={t("Settings")}>
                    <div>
                        <Company />
                    </div>
                </Tab>            */}

            </Tabs>

        </div>
    );
};

export default TemsDetails;