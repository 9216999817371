import { Button,Spinner } from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const EditPhishingCompaign = ({ id }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false); // Loader state
    const navigate = useNavigate();
    const [userId] = useState(id);
    const [selectFile, setSelectFile] = useState(null);
    const [showFileUpload, setShowFileUpload] = useState(false);

    const [formData, setFormData] = useState({
        name_ar: "",
        name_en: "",
        start_date: "",
        end_date: "",
        start_time:"",
        save_submitted_data: false,
        enable_attachment: false,
        attachment: null,
    });

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectFile(file); 
        }
    };
    

    const handleBrowseClick = () => {
        document.getElementById("fileInput").click();
    };

    const fetchPhishingCompaign = async () => {
        try {
            if (!userId) {
                showToast(t("userIdRequired"), "error");
                return;
            }            

            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/phishing-campaigns/${userId}`, {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                showToast(t("errorFetchingCampaignDetails"), "error");
                return;
            }
            
            const data = await response.json();
            
            if (!data.campaign) {
                showToast(t("noCampaignDataFound"), "error");
                return;
            }            

            const campaign = data.campaign;
            console.log("campaignstart_Date");
            setFormData({
                ...formData,
                name_ar: campaign.name_ar || "",
                name_en: campaign.name_en || "",
                start_date: data.campaign.start_date ? data.campaign.start_date.substring(0, 10) : "", 
                end_date: data.campaign.end_date ? data.campaign.end_date.substring(0, 10) : "",
                // template_id: campaign.template_id || null,
                start_time: data.campaign.start_time ? data.campaign.start_time : "",
                save_submitted_data: campaign.save_submitted_data || 0,
                enable_attachment: campaign.enable_attachment || 0,
                attachment: campaign.attachment || null,
            });

            setShowFileUpload(campaign.enable_attachment === 1);
        } catch (err) {
            console.error("Error Details:", err); // Log error details for debugging
            showToast(t("somethingWentWrong"), "error");
        }        
    };

    useEffect(() => {
        fetchPhishingCompaign();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            const isChecked = checked ? 1 : 0;
            setFormData({ ...formData, [name]: isChecked });

            if (name === "enable_attachment") {
                setShowFileUpload(isChecked === 1);
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

   const handleEditPhishingCompaign = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Ensure `start_time` is properly formatted as HH:MM
    let formattedStartTime = formData.start_time.trim().slice(0, 5);  // Ensures only HH:MM is taken

    // Check if time format is valid
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (!timeRegex.test(formattedStartTime)) {
        showToast(t("InvalidTimeFormat"), "error");
        setIsLoading(false);
        return;
    }

    try {
        const token = localStorage.getItem("userToken");

        const updateFormData = {
            name_ar: formData.name_ar.trim(),
            name_en: formData.name_en.trim(),
            start_date: formData.start_date , 
            end_date: formData.end_date ,
            start_time: formattedStartTime,  // Fixed Format
            save_submitted_data: formData.save_submitted_data === 1,
            enable_attachment: formData.enable_attachment === 1,
        };

        const response = await fetch(`${api_url}/phishing-campaigns/${userId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(updateFormData),
        });

        if (response.ok) {
            showToast(t("phishingCampaignUpdatedSuccess"), "success");
            navigate(-1);
        } else {
            const errorData = await response.json();
            showToast(errorData.message, "error");
        }
    } catch (error) {
        console.error("Error:", error);
        showToast(t("somethingWentWrong"), "error");
    } finally {
        setIsLoading(false);
    }
};

    
    
    

    return (
        <div className="py-4" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '0px 15px' }}>
            <form onSubmit={handleEditPhishingCompaign}>

                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Namear')}</label>
                            <input type="text" name="name_ar" className="form-control" onChange={handleChange} value={formData.name_ar} required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Nameen')}</label>
                            <input type="text" name="name_en" className="form-control" onChange={handleChange} value={formData.name_en} required />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('StartDate')}</label>
                            <input type="date" name="start_date" className="form-control" onChange={handleChange} value={formData.start_date} required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('EndDate')}</label>
                            <input type="date" name="end_date" className="form-control" onChange={handleChange} value={formData.end_date} required />
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                                    <label className="form-label">{t('Start time')}</label>
                                    <input
                                        type="time"
                                        name="start_time"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.start_time}
                                        required
                                    />
                                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                name="enable_attachment"
                                checked={formData.enable_attachment === 1}
                                onChange={handleChange}
                                id="enable_attachment"
                            />
                            <label className="form-check-label" htmlFor="enable_attachment">
                                {t("EnableAttachment")}
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                    <div className="form-check mb-3">
                    <OverlayTrigger
    placement="top"
    overlay={
        <Tooltip 
            id="tooltip-save-submitted-data" 
            style={{ maxWidth: "550px", whiteSpace: "normal", padding: "10px", textAlign: "left" }}
        >
            This option will enable saving all data that are submitted in a phishing campaign. 
            Make sure you have the authority before enabling this option.
        </Tooltip>
    }
>
    <span>
        <input
            type="checkbox"
            className="form-check-input"
            name="save_submitted_data"
            checked={formData.save_submitted_data === 1}
            onChange={handleChange}
            id="save_submitted_data"
        />
        <label className="form-check-label ms-2" htmlFor="save_submitted_data">
            {t("SaveSubmittedData")}
        </label>
    </span>
</OverlayTrigger>

                </div>

                    </div>
                </div>

                {/* File Upload Field (Only Show if Enabled) */}
                {showFileUpload && (
                    <div className="mb-3">
                        <label className="form-label">Upload PDF or ZIP File</label>
                        <input
                            id="fileInput"
                            type="file"
                            className="form-control"
                            accept=".pdf,.zip"
                            onChange={handleFileChange}
                        />
                        {selectFile && (
                            <p className="mt-2 text-success">Selected File: {selectFile.name}</p>
                        )}
                    </div>
                )}

<button type="submit" className="btn btn-primary" disabled={isLoading}>
                                    {isLoading ? (
                                        <>
                                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                                            {t('Update...')}
                                        </>
                                    ) : (
                                        t('UpdatePhishingCampaign')
                                    )}
                                </button>
            </form>
        </div>
    );
};

export default EditPhishingCompaign;
