import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Card } from "react-bootstrap"; // Import Bootstrap components

const EmailTempPreview = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ title: "", body: "" });

    useEffect(() => {
        const fetchData = () => {
            const storedEmail = localStorage.getItem("EditEmailTemp");
            if (storedEmail) {
                setFormData(JSON.parse(storedEmail));
            }
        };
        fetchData();

        window.addEventListener("storage", fetchData);
        return () => {
            window.removeEventListener("storage", fetchData);
        };
    }, []);

    return (
        <Container className="mt-4">

            {/* Card Container for Bounded Layout */}
            <div className="p-4">
            <h5 className="mb-4">{t("PreviewEmailTemplate")}</h5>
                <Card.Body>
                    <div className="mb-3">
                        <strong>{t("Title")}:</strong>
                        <p className="border rounded p-2 bg-light">{formData.title || "No data available"}</p>
                    </div>

                    <div className="mb-3">
                        <strong>{t("EmailBody")}:</strong>
                        <div 
                            className="border rounded p-3 bg-white overflow-auto" 
                            style={{ maxHeight: "400px", wordWrap: "break-word" }}
                            dangerouslySetInnerHTML={{ __html: formData.body || "<p>No content available</p>" }}
                        ></div>
                    </div>
                </Card.Body>
            </div>
        </Container>
    );
};

export default EmailTempPreview;
