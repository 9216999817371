import './Dashboard.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { api_url } from '../../constant/constant';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [campaignData, setCampaignData] = useState({
    planned: 0,
    completed: 0,
    active: 0,
  });
  const [phishingData, setPhishingData] = useState({
    planned: 0,
    completed: 0,
    active: 0,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const colors = ['#95A4FC', '#BAEDBD', '#1C1C1C', '#B1E3FF', '#A8C5DA', '#A1E3CB'];

  const data = [
    { name: 'Linux', uv: 1800 },
    { name: 'Mac', uv: 3000 },
    { name: 'iOS', uv: 2000 },
    { name: 'Android', uv: 2780 },
    { name: 'Other', uv: 900 },
    { name: 'Other', uv: 2000 },
  ];

  // Get the language from localStorage
  const lang = localStorage.getItem('lang') || 'en'; // Default to 'en' if not set

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${api_url}/dashboard`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 429) {
          localStorage.removeItem("userToken");
          navigate("/login");
          return;
        }
        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("userToken");
          navigate("/login");
          return;
        }
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          const text = await response.text();
          console.error('Non-JSON Response:', text);
          throw new Error(`Invalid API response: ${text}`);
        }

        const result = await response.json();
        if (result.success && result.data) {
          const learningData = result.data.learning_campaigns_data;
          const phishingData = result.data.phishing_campaigns_data;

          setCampaignData({
            planned: learningData.planned_learning_campaigns?.count || 0,
            completed: learningData.completed_learning_campaigns?.count || 0,
            active: learningData.active_learning_campaigns?.count || 0,
          });

          setPhishingData({
            planned: phishingData.planned_phishing_campaigns?.count || 0,
            completed: phishingData.completed_phishing_campaigns?.count || 0,
            active: phishingData.active_phishing_campaigns?.count || 0,
          });
        } else {
          throw new Error(result.message || 'Failed to fetch campaign data.');
        }
      } catch (err) {
        console.error('Fetch error:', err);
        setError(err.message || 'An error occurred while fetching the data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="dashboard-container p-4">
      {/* Heading */}
      <h1 className="textAwareness mb-4">{t('AwarenessManager')}</h1>

      {/* Bar Graph */}
      <div className="color" style={{ borderRadius: '16px' }}>
        <div className="">
          {/* Dropdown and Export Section */}
          <div className="row g-3 align-items-center">
            {/* First Dropdown */}
            <div className="col-md-5 col-lg-4 col-xl-4 col-xxl-2">
              <div className="dropdown-item">
                <h5 className="dropdown-item mb-2">{t('SelectDepartments')}</h5>
                <select className="form-select" style={{ display: 'flex', padding: '10px 14px', flexDirection: 'column', alignItems: 'flex-start', gap: '8', borderRadius: '8px' }} aria-label="Select Department">
                  <option defaultValue>{t('SelectDepartment')}</option>
                  <option value="Computer Department">{t('ComputerDepartment')}</option>
                  <option value="Bio Department">{t('BioDepartment')}</option>
                  <option value="Account Department">{t('AccountDepartment')}</option>
                </select>
              </div>
            </div>

            {/* Second Dropdown */}
            <div className="col-md-5 col-lg-4 col-xl-4 col-xxl-2">
              <div className="dropdown-item">
                <h5 className="dropdown-item mb-2">{t('SelectCampaign')}</h5>
                <select className="form-select" style={{ display: 'flex', padding: '10px 14px', flexDirection: 'column', alignItems: 'flex-start', gap: '8', borderRadius: '8px' }} aria-label="Select Campaign">
                  <option defaultValue>{t('SelectCampaign')}</option>
                  <option value="Computer Department">{t('ComputerDepartment')}</option>
                  <option value="Bio Department">{t('Bio Department')}</option>
                  <option value="Account Department">{t('AccountDepartment')}</option>
                </select>
              </div>
            </div>

            {/* Export Analytics Button */}
            <div className="col-md-4 col-lg-3 d-flex align-items-center">
              <button className="btn1 custom-btn d-flex align-items-center me-8 mt-3">
                <i className="bi bi-upload me-2"></i>
                {t('ExportAnalytics')}
              </button>
            </div>
          </div>

          {/* Advanced Search Filters */}
          <div className="row">
            <div className="Advanced" style={{ marginTop: '15px' }}>
              <i className="bi bi-gear me-2"></i>
              {t('AdvancedSearchFilters')}
            </div>
          </div>
        </div>
      </div>

      {/* Training Campaigns Section */}
      <div className="color1 section py-4 training-section" style={{ marginTop: '25px', background: '#EFF4F9', borderRadius: '16px' }}>
        <p className="Training col-md-4 col-lg-2">{t('TrainingCampaigns')}</p>
        <div className="d-flex">
          <div className="d-inline-flex align-items-center" style={{ marginRight: '20px' }}>
            <div className="button">{campaignData.planned}</div>
            <div>
              <p className="textComplete">{t('PlannedCampaigns')}</p>
              <p className="text1">{t('Trainingcampaignsthatusershavecompleted')}</p>
            </div>
          </div>

          <div className="d-inline-flex align-items-center" style={{ marginRight: '20px' }}>
            <div className="button">{campaignData.completed}</div>
            <div>
              <p className="textComplete">{t('CompletedCampaigns')}</p>
              <p className="text1">{t('Activetrainingcampaignsthatarealreadyrunning')}</p>
            </div>
          </div>

          <div className="d-inline-flex align-items-center">
            <div className="button">{campaignData.active}</div>
            <div>
              <p className="textComplete">{t('ActiveCampaigns')}</p>
              <p className="text1">{t('Plannedcampaignswhichwillbestarting')}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Phishing Campaigns Section */}
      <div className="color1 section py-4 training-section" style={{ marginTop: '25px', background: '#EFF4F9', borderRadius: '16px' }}>
        <p className="Training col-md-4 col-lg-2">{t('PhishingCampaigns')}</p>
        <div className="d-flex">
          <div className="d-inline-flex align-items-center" style={{ marginRight: '20px' }}>
            <div className="button">{phishingData.completed}</div>
            <div>
              <p className="textComplete">{t('CompletedPhishingCampaigns')}</p>
              <p className="text1">{t('Phishingcampaignsthatarecompleted')}</p>
            </div>
          </div>

          <div className="d-inline-flex align-items-center" style={{ marginRight: '20px' }}>
            <div className="button">{phishingData.active}</div>
            <div>
              <p className="textComplete">{t('ActivePhishingCampaigns')}</p>
              <p className="text1">{t('Activephishingcampaignsthatarealreadyrunning')}</p>
            </div>
          </div>

          <div className="d-inline-flex align-items-center">
            <div className="button">{phishingData.planned}</div>
            <div>
              <p className="textComplete">{t('PlannedPhishingCampaigns')}</p>
              <p className="text1">{t('Plannedphishingcampaignswhichwillstartsoon')}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Campaign Engagement Section */}
      <div className="color1 section" style={{ marginTop: '25px', background: '#EFF4F9', marginBottom: '40px', borderRadius: '16px' }}>
        <h1 className="Campaign Engagement">{t('CampaignEngagement')}</h1>
        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '40px', direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Bar dataKey="uv" fill="#8884d8" radius={9.932}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
          <div>
            <ul style={{ margin: 0, padding: 0, marginTop: '120px' }}>
              <li>{t('CommunityManagement15%')}</li>
              <li>{t('ConstructionDelivery41%')}</li>
              <li>{t('SafetySecurity11%')}</li>
              <li>{t('Other')}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;