import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ProfileDetails = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        email: "",
        username: "",
        firstname: "",
        lastname: "",
        phone_number: "",
        gender: "",
        avatar: ""
    });

    useEffect(() => {
        const storedUserData = JSON.parse(localStorage.getItem("userData"));
        if (storedUserData) {
            setFormData(storedUserData);
        }
    }, []);

    return (
        <div className="addEmailContainer" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '26px', margin: '20px' }}>
            <div className="d-flex justify-content-between mb-3">
                <h3>{t('ProfileDetails')}</h3>
                <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
            </div>
            <div className="container mt-4">
                {/* <h2>Profile Details</h2> */}
                <form>
                    <div className="row">



                        <div style={{ textAlign: "right" }}>
                            <label style={{ marginRight: "10px" }}>{t('ProfilePicture')}:</label> {/* Add Right Margin */}
                            <img
                                src={formData.avatar ? formData.avatar : "/images/profile.png"}
                                alt="Profile"
                                className="img-thumbnail"
                                style={{
                                    width: "120px",
                                    height: "120px",
                                    objectFit: "cover",
                                    borderRadius: "100px"
                                }}
                            />
                        </div>


                        <div className="col-md-6 mb-3">
                            <label>{t('Email')}:</label>
                            <input type="email" name="email" className="form-control" value={formData.email} disabled />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>{t('Username')}:</label>
                            <input type="text" name="username" className="form-control" value={formData.username} disabled />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label>{t('FirstName')}:</label>
                            <input type="text" name="firstname" className="form-control" value={formData.firstname} disabled />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>{t('LastName')}:</label>
                            <input type="text" name="lastname" className="form-control" value={formData.lastname} disabled />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label>{t('PhoneNumber')}:</label>
                            <input type="text" name="phone_number" className="form-control" value={formData.phone_number} disabled />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>{t('Gender')}:</label>
                            <select name="gender" className="form-control" value={formData.gender} disabled>
                                <option value="male">{t('Male')}</option>
                                <option value="female">{t('Female')}</option>
                            </select>
                        </div>
                    </div>




                </form>
            </div>
        </div>
    );
};

export default ProfileDetails;
