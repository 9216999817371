import { Button,Spinner } from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import {useNavigate } from 'react-router-dom';  // useNavigate ko import kiya hai


const EditLearningManager = ({ id, parentfetchLearningManager }) => {
    const navigate = useNavigate();  
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const { t } = useTranslation();
    const [campaign_types, setCampaignTypes] = useState([]);

    const fetchLearningManagerTypes = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/learning-campaigns/types', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setCampaignTypes(result.campaign_types || []);
        } catch (error) {
            console.error("Error fetching campaign types:", error);
            showToast(t("errorFetchingCampaignTypes"), "error");
          }          
    };


    const [show, setShow] = useState(false);


    const [userId] = useState(id);
    const [formData, setFormData] = useState({
        type: "",
        title_ar: "",
        title_en: "",
        description_ar: "",
        description_en: "",
        start_date: "",
        start_time: "",
        end_date: "",
        video_seek: "",
        success_rate:""
    });


    const fetchLearningManager = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/learning-campaigns/" + userId, {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                let errorMessage = "Error fetching campaign details."; // Default error
            
                try {
                    const result = await response.json();
                    if (result?.message) {
                        errorMessage = result.message; // API ka actual error message use karna
                    }
                } catch (error) {
                    console.error("Error parsing API response:", error);
                }
            
                if (response.status === 404) {
                    errorMessage = "Campaign not found. Please check the ID.";
                } else if (response.status === 403) {
                    errorMessage = errorMessage || "You do not have permission to perform this action.";
                } else if (response.status === 422) {
                    errorMessage = errorMessage || "The start time field must match the format H:i.";
                }
            
                showToast(errorMessage, "error");
                return;
            }
            
            

            const data = await response.json();

            setFormData({
                ...formData,
                type: data.learning_campaign.type,
                title_ar: data.learning_campaign.title_ar,
                title_en: data.learning_campaign.title_en,
                start_date: data.learning_campaign.start_date,
                start_time: data.learning_campaign.start_time,
                description_ar: data.learning_campaign.description_ar,
                description_en: data.learning_campaign.description_en,
                end_date: data.learning_campaign.end_date,
                video_seek: data.learning_campaign.video_seek,
                success_rate:data.learning_campaign.success_rate,
            });

        } catch (err) {
            showToast(t("somethingWentWrong"), "error");
          }          
    };

    useEffect(() => {
        fetchLearningManagerTypes();
        fetchLearningManager();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleEditLearningManager = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        console.log("Form Data:", formData);
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/learning-campaigns/${userId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
    
            let errorMessage = "Failed to update learning manager. Please try again.";
    
            if (!response.ok) {
                const result = await response.json();
               
    
                if (response.status === 404) {
                    errorMessage = "User not found. Please check the ID.";
                } else if (response.status === 403) {
                    errorMessage = result?.message;
                } else if (response.status === 422) {
                    errorMessage = result?.message;
                }
    
                showToast(errorMessage, "error");
                return;
            }
    
            showToast(t("learningManagerUpdated"), "success");
            navigate(-1);
            } catch (error) {
              console.error("Fetch Error:", error);
              showToast(t("somethingWentWrong"), "error");
            } finally {
              setIsLoading(false); // Loader stop
            }
            
    };
    

    return (
        <div className="py-4" style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'0px 15px'}}>
            <form onSubmit={handleEditLearningManager}>

                <div className="row">
                    <div className="col-md-6">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label">{t('Type')}</label>
                                <select name="type" className="form-select" onChange={handleChange} required value={formData.type}>
                                    <option></option>
                                    {campaign_types && campaign_types.map((campaign, index) => (
                                        <option value={campaign}>{campaign}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Titlear')}</label>
                            <input type="text" name="title_ar" className="form-control" onChange={handleChange} value={formData.title_ar} required />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Titleen')}</label>
                            <input type="text" name="title_en" className="form-control" onChange={handleChange} value={formData.title_en} required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Descriptionar')}</label>
                            <input type="text" name="description_ar" className="form-control" onChange={handleChange} value={formData.description_ar} required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Descriptionen')}</label>
                            <input type="text" name="description_en" className="form-control" onChange={handleChange} value={formData.description_en} required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('StartDate')}</label>
                            <input type="date" name="start_date" className="form-control" onChange={handleChange} value={formData.start_date} required />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('StartTime')}</label>
                            <input type="time" name="start_time" className="form-control" onChange={handleChange} value={formData.start_time} required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('EndDate')}</label>
                            <input type="date" name="end_date" className="form-control" onChange={handleChange} value={formData.end_date} required />
                        </div>
                    </div>
                    <div className="col-md-6">
    <div className="mb-3">
        <label className="form-label">{t('VideoSeek')}</label>
        <select
            name="video_seek"
            className="form-select"  // Bootstrap class for better UI
            onChange={handleChange}
            value={formData.video_seek}
            required
        >
            <option value="">{t('SelectVideoSeek')}</option>
            <option value="backward">{t('Backward')}</option>
            <option value="forward">{t('Forward')}</option>
            <option value="both">{t('Both')}</option>  
        </select>
    </div>
</div>


                    <div className="col-md-6">
    <div className="mb-3">
        <label className="form-label">{t('SuccessRate')}</label>
        {/* Range Slider */}
        <input
            type="range"
            className="form-range"
            name="success_rate"
            min="0"
            max="100"
            step="1"
            value={formData.success_rate || 0}
            onChange={(e) => setFormData(prev => ({ ...prev, success_rate: Number(e.target.value) }))}
        />
    </div>

    <div className="flex items-center gap-2">
        <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 h-1.5">
            <div 
                className="bg-blue-600 rounded-full h-1.5" 
                style={{ width: `${formData.success_rate || 0}%` }}
            />
        </div>
        {/* Success Rate Number */}
        <span className="text-sm font-medium text-gray-700">
            {formData.success_rate || 0}%
        </span>
    </div>
</div>
                    <div className="col-md-6">
                        <div className="mb-3">
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {/* <div className="mb-3">
                                <label className="form-label">Role</label>
                                <select className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Supersoft</option>
                                    <option value="2">Professional Service</option>
                                    <option value="3">Admin</option>
                                </select>
                            </div> */}
                    </div>
                    <div className="col-md-6">
                        {/* <div className="mb-3">
                                <label className="form-label">Status</label>
                                <select className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div> */}
                    </div>
                </div>

                 <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                    {isLoading ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                            {t('Update...')}
                                                        </>
                                                    ) : (
                                                        t('UpdateCampaignDetails')
                                                    )}
                                                </button>
            </form>
        </div>
    );

}

export default EditLearningManager;