import React, { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { showToast } from "../../Components/ShowToast/ToastNotification";
import { NavLink } from "react-router-dom";
import "./SystemLogsDetails.css";

const SystemLogsDetails = () => {
    const { t } = useTranslation();
    const [contentEn, setContentEn] = useState("");
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(false);

    // Fetch system logs
    const fetchSystemLogs = async (url) => {
        setLoading(true);
        let page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            page = temp[1];
        }
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/logs?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                showToast(t("failedtofetchdata"), "error");
                return;
            }
            
            const data = await response.json();
            
            if (data.data) {
                setContentEn(data.data.join("\n") || "");
                setLinks(data.links);
            } else {
                showToast(t("nosystemlogsfound"), "warning");
            }
            } catch (err) {
                showToast(t("errorfetchingdata"), "error");
            } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSystemLogs('');
    }, []);

    return (
        <div className="py-4 d-flex flex-column">
            {loading ? (
                <div className="d-flex justify-content-center align-items-center flex-grow-1">
                    <div className="spinner-border" role="status" style={{ borderTopColor: "#6897CB" }}></div>
                </div>
            ) : (
                <div className="container">
                    <div className="row pb-2">
                        {/* Simple Textarea for Logs */}
                        <div className="col-12">
                            <label className="form-label"><strong>{t("systemlogs")}</strong></label>
                            <textarea
                                value={contentEn}
                                onChange={(e) => setContentEn(e.target.value)}
                                className="form-control"
                                rows={10}
                                style={{ resize: "vertical", overflowY: "auto" }}
                                readOnly
                            />
                        </div>
                    </div>

                    {/* Pagination Buttons */}
                    <div className="navigationWrapper">
                        <ul>
                            {links.map((link, index) => (

                                <li key={index}
                                    className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                                    onClick={link.url !== null ? () => fetchSystemLogs(link.url) : undefined}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: link.label }} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SystemLogsDetails;
