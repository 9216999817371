import { Button, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { showToast } from "../ShowToast/ToastNotification";
import { useNavigate } from "react-router-dom";

const EditPhishingCompaignSetting = ({ id, parentfetchLearningManager }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false); // Loader state
    const navigate = useNavigate();

    const [formData, setFormData] = useState({});
    const [servers, setServers] = useState([]);

    useEffect(() => {
        const fetchServers = async () => {
            try {
                const token = localStorage.getItem("userToken");
                if (!token) {
                    console.error("No token found in localStorage!");
                    showToast("No token found in localStorage!", "error");
                    return;
                }
                const response = await fetch(`${api_url}/email-servers`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch servers.");
                }

                const data = await response.json();
                setServers(data.data || []);
            } catch (error) {
                console.error("Error fetching servers:", error);
                showToast(t("errorFetchingServers"), "error");
            }
        };

        fetchServers();
    }, []);

    const fetchPhishingCompaignSetting = async () => {
        try {
            if (!id) {
                showToast(t("userIdRequired"), "error");
                return;
            }

            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/phishing-campaign-settings/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const data = await response.json();

                showToast(data.message, "error");
                return false;
            }

            const data = await response.json();

            // if (!data) {
            //     showToast("No campaign data found.", "error");
            // }

            setFormData({
                campaign_id: data.campaign_id || "",
                server_id: data.server_id || "",
                template_id: data.template_id || "",
                fake_domain: data.fake_domain || "",
                execute_url: data.execute_url || "",
                from_email_address: data.from_email_address || "",
                from_name: data.from_name || "",
                email_subject: data.email_subject || "",
                is_auto_send: String(data.is_auto_send) || "false", // Ensure it's a string
                server_name: data.server_name || "",
                template_name: data.template_name || "",
            });


        } catch (err) {
            showToast(t("somethingWentWrong"), "error");
          }          
    };

    useEffect(() => {
        fetchPhishingCompaignSetting();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? String(checked) : value, // Ensure checkboxes are stored as strings
        });
    };

    const handleEditPhishingCompaignSetting = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/phishing-campaign-settings/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                showToast(t("phishingCampaignSettingUpdated"), "success");
                fetchPhishingCompaignSetting();
            } else {
                showToast(t("failedToUpdatePhishingCampaignSetting"), "error");
            }
            } catch (error) {
                showToast(t("somethingWentWrong"), "error");
            } finally {
            setIsLoading(false); // Loader stop
        }
    };

    return (

        <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", marginTop: "20px" }}>

            <div >
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("FakeDomain")}</label>
                            <input
                                type="text"
                                name="fake_domain"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.fake_domain}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("Server")}</label>
                            <select name="server_id" className="form-control" onChange={handleChange} value={formData.server_id}>
                                <option value="">{t("SelectServer")}</option>
                                {servers.map((server) => (
                                    <option key={server.id} value={server.id}>
                                        {server.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {/* <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t("ExecuteURL")}</label>
                        <input
                            type="text"
                            name="execute_url"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.execute_url}
                            required
                        />
                    </div>
                </div> */}
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("FormEmailAddress")}</label>
                            <input
                                type="email"
                                name="from_email_address"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.from_email_address}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("FormName")}</label>
                            <input
                                type="text"
                                name="from_name"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.from_name}
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("EmailSubject")}</label>
                            <input
                                type="text"
                                name="email_subject"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.email_subject}
                                required
                            />
                        </div>
                    </div>
                    {/* Template Name Input */}
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("TemplateName")}</label>
                            <input
                                type="text"
                                name="template_name"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.template_name}
                                required
                            />
                        </div>
                    </div>
                </div>

                {/* Is Auto Send Checkbox */}
                <div className="form-check">
                    <input
                        type="checkbox"
                        name="is_auto_send"
                        className="form-check-input"
                        checked={formData.is_auto_send === "true"}
                        onChange={handleChange}
                        required
                    />
                    <label className="form-check-label ms-2">{t("IsAutoSend")}</label>
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="btn btn-primary mt-3"
                    disabled={isLoading}
                    onClick={handleEditPhishingCompaignSetting} // Ensure this is correctly added
                >
                    {isLoading ? (
                        <>
                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                            {t("Updating...")}
                        </>
                    ) : (
                        t("UpdatePhishingCampaignSetting")
                    )}
                </button>

            </div>
        </div>

    );
};

export default EditPhishingCompaignSetting;
