import { Modal, Button, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './AddLesson.css';
import { useTranslation } from 'react-i18next';
import { showToast } from '../ShowToast/ToastNotification';
import { Tab, Tabs } from "react-bootstrap";
const AddLesson = ({ parentfetchLessons }) => {
    const { t } = useTranslation();
    const [lesson_types, setLessonTypes] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const fetchLessonTypes = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/lesson-types", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (!response.ok) {
                showToast(t("httpError"), "error");
                throw new Error(response.message || "HTTP error!");
            }
    
            const result = await response.json();
            setLessonTypes(result);
        } catch (error) {
            showToast(t("fetchError"), "error");
            handleClose();
        }
    };
    

    useEffect(() => {
        fetchLessonTypes();
    }, []);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        title_ar: "",
        title_en: "",
        description_ar: "",
        description_en: "",
        code: "",
        lesson_type_id: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddLesson = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start
    
        console.log(formData);
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/lessons", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
    
            if (response.ok) {
                showToast(t("lessonAdded"), "success");
                handleClose();
                setFormData({
                    title_ar: "",
                    title_en: "",
                    description_ar: "",
                    description_en: "",
                    code: "",
                    lesson_type_id: ""
                });
                setShow(false);
                navigate(-1);
            } else {
                showToast(t("lessonAddFailed"), "error");
                handleClose();
            }
        } catch (error) {
            showToast(t("genericError"), "error");
            handleClose();
        } finally {
            setIsLoading(false); // Loader stop
        }
    };
    

    return (
        <div className="addEmailContainer" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '26px 26px', margin: '20px' }}>
            <div className="d-flex justify-content-between mb-3">
                <div className="pageTitle">{t('AddLesson')}</div>
                <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
            </div>

            <Tabs defaultActiveKey="Add Lesson" className="mb-3">
                <Tab eventKey="Add Lesson" title={t("AddLesson")}>
                    <form onSubmit={handleAddLesson} className="Add Lesson">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Title(English)')}</label>
                                    <input type="text" name="title_en" className="form-control" onChange={handleChange} value={formData.title_en} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Title(Arabic)')}</label>
                                    <input type="text" name="title_ar" className="form-control" onChange={handleChange} value={formData.title_ar} required />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Description(English)')}</label>
                                    <textarea type="text" name="description_en" className="form-control" onChange={handleChange} required>{formData.description_en}</textarea>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Description(Arabic)')}</label>
                                    <textarea type="text" name="description_ar" className="form-control" onChange={handleChange} required>{formData.description_ar}</textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Code')}</label>
                                    <input type="number" name="code" className="form-control" onChange={handleChange} value={formData.code} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('LessonType')}</label>
                                    <select name="lesson_type_id" className="form-select" onChange={handleChange} required>
                                        <option></option>
                                        {lesson_types && lesson_types.map((lesson, index) => (
                                            <option value={lesson.id}>{lesson.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="text-end">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner animation="border" size="sm" role="status" className="me-2" />
                                        {t('Adding...')}
                                    </>
                                ) : (
                                    t('AddLesson')
                                )}
                            </button>
                        </div>
                    </form>
                </Tab>
            </Tabs>
        </div>
    );

}

export default AddLesson;