import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { Tabs, Tab, Button } from "react-bootstrap";
import { api_url } from '../../constant/constant';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditPhishingCompaignSetting from '../../Components/EditPhishingCompaignSettingModal/EditPhishingCompaignSetting';
const PhishingCompaignDetail = () => {
    const { id } = useParams(); // Ensure the route contains a parameter named 'id'

    const { t } = useTranslation();
    const [key, setKey] = useState("details");
    const navigate = useNavigate();
    // State variables
    const [data, setVideos] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');

    // Handle delete user action

    // Fetch videos data with pagination







    // Define table columns


    // Create a table instance


    return (
        <div className="p-4">

            <div className="d-flex justify-content-between mb-3">
                <div className="pageTitle">{t('PhishingCampaignSettingDetail')}</div>
                <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="details" title={t('EditPhishingCampaignSetting')}>
                    <div>
                        <EditPhishingCompaignSetting id={id} />
                    </div>
                </Tab>



            </Tabs>

        </div>
    );
};

export default PhishingCompaignDetail;