import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { showToast } from '../../Components/ShowToast/ToastNotification';

const LockedUsers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchVal, setSearchVal] = useState('');
    const [data, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchLockedUsers();
    }, []);

    const fetchLockedUsers = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/locked-accounts', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setUsers(result.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
            showToast(t("errorfetchinglockedusers"), "error");
        } finally {
            setLoading(false);
        }
    };

    const unlockUser = async (user_Id) => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + `/unlock-account/${user_Id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            showToast(t("userunlockedsuccessfully"), "success");
            fetchLockedUsers();
            } catch (error) {
                console.error('Error unlocking user:', error);
                showToast(t("errorunlockinguser"), "error");
            }            
    };

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'firstname',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_user.svg" alt="First Name" />{t('FirstName')}
                    </div>
                ),
                colName: t('FirstName'),
            },
            {
                accessorKey: 'lastname',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_user.svg" alt="Last Name" />{t('LastName')}
                    </div>
                ),
                colName: t('LastName'),
            },
            {
                accessorKey: 'email',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-envelope"></i>{t('Email')}
                    </div>
                ),
                colName: t('Email'),
            },
            {
                accessorKey: 'status',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_loader.svg" alt="Status" />{t('Status')}
                    </div>
                ),
                cell: ({ getValue, row }) => (
                    <span className={getValue() === 'locked' ? "badge bg-danger" : "badge bg-secondary"}>
                        {getValue() === 'locked' ? t('Locked') : t('Inactive')}
                    </span>
                ),
            },
            {
                accessorKey: 'action',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Action" />{t('Action')}
                    </div>
                ),
                cell: ({ row }) => (
                    <button 
                        className="btn btn-primary btn-sm"
                        onClick={() => unlockUser(row.original.id)}
                    >
                        {t('Unlock')}
                    </button>
                ),
            }
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">
            <div className="pageTitle mb-4">{t('LockedUsers')}</div>

            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('SearchUsers')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("Searchbyname")}
                                aria-label="Search by user"
                                value={searchVal}
                                onChange={(e) => setSearchVal(e.target.value)}
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <div className="spinner-border" role="status" />
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th key={header.id}>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default LockedUsers;
