import React, { useEffect, useState, useMemo } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { Modal, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const Certificate = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [links, setLinks] = useState([]);
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedCertificateManagerId, setSelectedCertificateManagerId] = useState(null);
    const { id } = useParams();
    console.log("id:", id);
    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/certificate-templates/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                showToast(t("learningmanagerdeletesuccess"), "success");
                fetchCertificateimages('');
                setShowModal(false);
            } else {
                showToast(t("faileddeletecertificatemanager"), "error");
                setShowModal(false);
            }
        } catch (error) {
            setShowModal(false);
            showToast(t("somethingwentwrong"), "error");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const fetchCertificateimages = async (url) => {
        setLoading(true);
        // if (url !== '') {
        //     const temp = url.split('?page=');
        // }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + `/certificate-templates`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setData(result.data || []);  // Set data from API
            setLinks(result.data.links || []);  // Set pagination links from API response

        } catch (error) {
            console.error('Error fetching data:', error);
            showToast(t("errorfetchingdata"), "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCertificateimages();  // Call fetch function on load
    }, []);

    useEffect(() => {
        if (searchValue) {
            setData(prevData => prevData.filter(item =>
                item.title.toLowerCase().includes(searchValue.toLowerCase())  // Filter based on title
            ));
        } else {
            fetchCertificateimages();  // Re-fetch if search is cleared
        }
    }, [searchValue]);

    const columns = useMemo(() => [
        { accessorKey: 'id', header: () => t('ID'), colName: t('ID') },
        {
            accessorKey: 'title', header: () => t('Title'), colName: t('Title'),

        },

        { accessorKey: 'language', header: () => t('Language'), colName: t('Language'), },
        {
            accessorKey: 'id',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                </div>
            ),
            cell: ({ getValue }) => {
                const id = getValue();
                return (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <div className="iconDel" onClick={(e) => { e.stopPropagation(); setSelectedCertificateManagerId(id); setShowModal(true) }} >
                            <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                        </div>
                    </div>
                );
            },
            colName: t('Action'),
        },
    ], [t]);

    const handleCertificate = (id) => {
        navigate(`/CertificateManagerDetails/${id}/CertificateManagerDetails`)
    }

    const table = useReactTable({ data, columns, getCoreRowModel: getCoreRowModel() });

    return (
        <div className="Certificate" style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", marginLeft: "19px", marginTop: "20px" }}>
               <div className="d-flex justify-content-between mb-3">
                                <div className="pageTitle">{t('CertificateMenagement')}</div>
                                <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
                            </div> 
            <div>
                <div className="primaryBlueBox p-5 mb-4">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="form-label">{t('CertificateMenagement')}</label>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder={t("SearchCertificate")} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                    <i className="bi bi-search"></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-1">
                            <div className="vrLine"></div>
                        </div>
                        <div className="col-md-7">
                            <NavLink className="btnWhiteGradient mt-3" to={`/AddCertificateManager?Id=${id}`}>
                                <i className="bi bi-envelope me-2 mt-3 h6"></i> {t('AddCertificateManager')}
                            </NavLink>
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div className='d-flex justify-content-center align-items-center my-5'>
                        <div className='spinner-border' role='status' style={{
                            borderTopColor: "#6897CB",
                            borderBottomColor: "#6897CB",
                            borderLeftColor: "#6897CB",
                            marginTop: "50px"
                        }}></div>
                    </div>
                ) : (
                    <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                        {window.innerWidth < 768 ? (
                            table.getRowModel().rows.map(row => (
                                <div key={row.id} className="stacked-row"
                                    onClick={row.id.includes('id') === false ? () => handleCertificate(row.original.id) : null}>
                                    {row.getVisibleCells().map(cell => (
                                        <div key={cell.id} className="stacked-cell">
                                            <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </div>
                                    ))}
                                </div>
                            ))
                        ) : (
                            <table className="table table-bordered table-hover">
                                <thead>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <th key={header.id}>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id}
                                                    className='rowAnchor'
                                                    onClick={cell.id.includes('id') === false ? () => handleCertificate(row.original.id) : null}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                )}

                {/* Pagination Links */}
                <div className="navigationWrapper">
                    <ul className="pagination">
                        {links.map((link, index) => (
                            <li key={index} className={`page-item ${link.active ? 'active' : ''} ${link.url === null ? 'disabled' : ''}`}>
                                <button
                                    className="page-link"
                                    onClick={() => link.url !== null && fetchCertificateimages(link.url)}  // Fetch new page when clicked
                                    dangerouslySetInnerHTML={{ __html: link.label }}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("ConfirmDelete")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {t("Areyousureyouwanttodeletethiscertificatemanager?")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            {t("Cancel")}
                        </Button>
                        <Button variant="danger" onClick={() => handleDelete(selectedCertificateManagerId)} disabled={loading}>
                            {loading ? t("Deleting...") : t("Delete")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Certificate;
