import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import DetailLicense from '../../Components/DetailLicenseModal/DetailLicense';
import { useTranslation } from 'react-i18next';
import AddLicenseManager from '../../Components/AddLicenseManager/AddLicenseManager';
import './License.css';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { NavLink } from 'react-router-dom';


const License = () => {

    const [data, setUsers] = useState([]);
    const [links, setLinks] = useState([]);
    const { t } = useTranslation();
    const [searchVal, setSearchVal] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchLicense = async (url) => {
        setLoading(true);
        console.log(url);
        var page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            page = temp[1];
        }


        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/licenses`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            ;

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setUsers(result.data);
            setLinks(result.links);
        } catch (error) {
            console.error('Error fetching data:', error);
            showToast(t("errorfetchingdata"), "error");
            setLoading(false);
            //localStorage.removeItem("userToken");
            //localStorage.removeItem("userData");
            //window.location = "/login";
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (searchVal !== '') {
            const filteredData = data.filter((item) =>
                item.customer_name.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.system_license_max_users.toString().toLowerCase().includes(searchVal.toLowerCase()) ||
                item.system_license_start_date.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.system_license_expiration_date.toLowerCase().includes(searchVal.toLowerCase())
            );
            setUsers(filteredData); // Update filtered data
        }
        else {
            fetchLicense(''); // Update filtered data
        }
    }, [searchVal]);

    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'customer_name',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                       <i class="bi bi-person-vcard"></i>{t('Client')}
                    </div>
                ),
                colName:t('Client'),

            },
            {
                accessorKey: 'system_license_max_users',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i class="bi bi-people"></i>{t('Users')}
                    </div>
                ),
                colName: t('Users'),
            },
            {
                accessorKey: 'system_license_start_date',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                       <i class="bi bi-align-start"></i>{t('StartDate')}
                    </div>
                ),
                colName: t('StartDate'),
                cell: ({row}) => {
                    const rawData = row.original.system_license_start_date;
                    const formattedDate = new Date(rawData).toLocaleDateString('en-US' , {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric"
                    });
                    return formattedDate;
                }
            },

            {
                accessorKey: 'system_license_expiration_date',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i class="bi bi-align-end"></i>{t('ExpirationDate')}
                    </div>
                ),
                colName: t('ExpirationDate'),
                cell: ( {row} ) => {
                    const rawData = row.original.system_license_expiration_date;
                    const formattedDate = new Date(rawData).toLocaleDateString('en-US', {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric"
                    });
                    return formattedDate;
                }

            },
            //
            {
                accessorKey: 'is_active',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-check-circle"></i>{t('Active')}
                    </div>
                ),
                colName:t('Active'),

            },
            {
                accessorKey: 'id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue();
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <DetailLicense id={id} />
                            {/* <div className="iconDel">
                        <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')} 
                    </div> */}
                        </div>
                    );
                },
                colName:t('Action'),
            },


        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">
            <div className="pageTitle mb-4">{t('LicenseManager')}</div>
            <div className="primaryBlueBox p-51 mb-4">
                <div className="row">
                    <div className="col-md-4" >
                        <label className="form-label">{t('SearchLicenseManager')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("Searchbyclient")}
                                aria-label=" License Manager"
                                value={searchVal} // Bind input value to searchVal state
                                onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        {/* <AddLicenseManager parentFetchLicenses={fetchLicense} /> */}
                        <NavLink className="btnWhiteGradient mt-3" to="/Add-License-Manager">
                                                   <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddLicenseManager')}
                                      </NavLink>
                    </div>
                </div>
            </div>

          
            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>
                    </div>
                </div>
            ) : (
                <div className="bg-light px-3 py-3 userTableWrapper resTable">

                    {window.innerWidth < 768 ? (
                        // Render stacked view for small screens
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row">
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        {cell.column.columnDef.colName != "ID" ? (
                                            <strong>{cell.column.columnDef.colName}: </strong>
                                        ) : ('')}

                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover">
                                <thead className="thead-dark">
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <th key={header.id}>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                </div>
            )}




            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchLicense(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
}

export default License;