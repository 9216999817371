import React, { useState } from 'react';
import { Tabs, Tab, Button } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditPolice from '../../Components/EditPoliceModal/EditPolice';
import PoliceUser from'../../Components/PoliceUserModel/PoliceUser';

 const PoliceDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [key, setKey] = useState("details");
 
    return (
        <div className="p-4">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('PolicyDetails')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="details" title={t('EditPolicy')}>
                     <EditPolice id={id} />
                </Tab>
                <Tab eventKey="PolicyUser" title={t('PolicyUser')}>
                     <PoliceUser id={id} />
                </Tab>
            </Tabs>
        </div>
    );
};

export default PoliceDetail;
