import React, { useEffect, useState } from "react";
import { api_url } from "../../constant/constant";
import { showToast } from "../ShowToast/ToastNotification";
import { useTranslation } from "react-i18next";
const CertificateList = ({ LearningManager_id }) => {
    // console.log("learningCampaignId",learningCampaignId);
  const [certificates, setCertificates] = useState([]);
//   const [learningCampaignId, setLearningCampaignId] = useState(null);
    const { t } = useTranslation();

  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState("");
  const [loading, setLoading] = useState(false);
  const [fromName, setFromName] = useState("");
  const [contexts, setContexts] = useState([]);  
  const [context, setContext] = useState("");
  useEffect(() => {
    fetchEmailServers();
    fetchTemplates();
    fetchContexts();
  }, []);

  const fetchContexts = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/certificate-campaigns/contexts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  
      const result = await response.json();
      console.log("Contexts API Data:", result); 
      if (result.status === "success" && typeof result.data === "object") {
        const formattedContexts = Object.entries(result.data).map(([key, value]) => ({
          value: key,
          label: key
        }));
        console.log("Formatted Contexts:", formattedContexts); 
        setContexts(formattedContexts);
      }
    } catch (error) {
      console.error("Failed to fetch contexts:", error);
      showToast(t("failedToFetchContexts"), "error");
    }    
  };
  

  const fetchTemplates = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/certificate-templates`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const result = await response.json();
      if (result.status === "success" && Array.isArray(result.data)) {
        setTemplates(result.data);
      }
    } catch (error) {
      console.error("Failed to fetch templates:", error.message);
    }
  };

  const fetchEmailServers = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/email-servers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const result = await response.json();
      setServers(result.data.length > 0 ? result.data : []);
    } catch (error) {
      console.error("Error fetching email servers:", error);
      setServers([]);
    }
  };

  const handleAssignCertificate = async () => {
    console.log("Selected Template:", selectedTemplate);
    console.log("Selected Server:", selectedServer);
  
    if (!selectedTemplate || !selectedServer) {
      showToast(t("pleaseSelectTemplateAndServer"), "error");
      return;
    }    
    
    setLoading(true);
  
    const requestData = {
      certificate_template_id: selectedTemplate.id,
      learning_campaign_id: LearningManager_id,
      email_server_id: selectedServer,
      context: context,
    };
  
    console.log("Request Data:", requestData);
  
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/certificate-campaigns/assign`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });
  
      const result = await response.json();
      console.log("API Response:", result);
  
      if (response.ok && result.status === "success") {
        showToast(t("certificateAssignedSuccessfully"), "success");
      } else {
        showToast(t("failedToAssignCertificate"), "error");
      }
      } catch (error) {
        console.error("Error assigning certificate:", error);
        showToast(t("errorAssigningCertificate"), "error");
      } finally {
        setLoading(false);
      }
  };
  

  return (
    <div  style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'0px 15px'}}>

      {/* Dropdowns in One Line */}
      <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
        {/* Certificate Templates Dropdown */}
        <div className="mb-3" style={{ flex: 1 }}>
          <label className="form-label">{t("CertificateTemplate")}</label>
          <select
            className="form-select"
            value={selectedTemplate ? selectedTemplate.id : ""}
            onChange={(e) => {
              const templateId = parseInt(e.target.value, 10);
              if (!isNaN(templateId)) {
                const selected = templates.find((temp) => temp.id === templateId);
                setSelectedTemplate(selected || null);
              }
            }}
          >
            <option value="">{t("SelectTemplate")}</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.title}
              </option>
            ))}
          </select>
        </div>

        {/* Email Server Selection */}
        <div className="mb-3" style={{ flex: 1 }}>
          <label className="form-label">{t("SelectServer")}</label>
          <select
            className="form-select"
            value={selectedServer}
            onChange={(e) => setSelectedServer(e.target.value)}
          >
            <option value="">{t("SelectServer")}</option>
            {servers.map((server, index) => (
              <option key={index} value={server.id}>
                {server.name}
              </option>
            ))}
          </select>
        </div>

        {/* Context Input */}
     
      </div>
      <div className="mb-3" style={{ flex: 1 }}>
        <label className="form-label">{t("Context")}</label>
        <select
          className="form-select"
          value={context}
          onChange={(e) => setContext(e.target.value)}
        >
          <option value="">{t("SelectContext")}</option>
          {contexts.map((ctx, index) => (
            <option key={index} value={ctx.value}>
              {ctx.label}
            </option>
          ))}
        </select>

      </div>


      {/* Assign Button */}
      <button
        className="btn btn-primary my-3"
        onClick={handleAssignCertificate}
        disabled={loading}
        style={{ marginTop: "10px" }}
      >
        {loading ? t("Assigning...") : t("AssignCertificate")}
      </button>
    </div>
  );
};

export default CertificateList;
