import { Modal, Button,Spinner  } from "react-bootstrap";
import React,{ useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
const AddPhishingCompaignSetting = () => {
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const campaignId  = searchParams.get("id");
        const [users, setUsers] = useState([]);
          const navigate = useNavigate();  
          const [servers, setServers] = useState([]);
 const [selectedServer, setSelectedServer] = useState("");
    // const LM_Id = id?.id;
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        campaign_id: campaignId || "",
        server_id: "",
        template_id: "",
        fake_domain: "",
        is_auto_send: 0,
        // save_submitted_data: "",
        zip_file: null,
        from_email_address: "",
        from_name: "",
        email_subject: "",
    });

    const handleChange = (e) => {
        const { name, type, value, files } = e.target;
    
        setFormData((prev) => ({
            ...prev,
            [name]: type === "file" && files.length > 0 ? files[0] : value, 
        }));
    };
    
    
    // Server select ka onChange event update karein:
    const handleServerChange = (e) => {
        const value = e.target.value;
        setSelectedServer(value);
        setFormData(prev => ({ ...prev, server_id: value }));
    };
    
      useEffect(() => {
        fetchEmailServers('');
        fetchEmailTemplate('');
      }, []);
      const fetchEmailTemplate = async (url) => {
        setLoading(true);
        let page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            page = temp[1];
        }
    
        try {
            const token = localStorage.getItem("userToken");
            console.log("Fetching templates with token:", token); // Debugging
    
            const response = await fetch(`${api_url}/email-templates?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
    
            console.log("Response status:", response.status); // Debugging
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const result = await response.json();
            console.log("Fetched templates:", result); // Debugging
    
            if (result?.data?.data?.length > 0) {
                setUsers(result.data.data);
            } else {
                setUsers([]);
                showToast(t("noEmailTemplatesFound"), "error");
            }
        } catch (error) {
            console.error('Error fetching templates:', error);
            showToast(t("failedToFetchTemplates"), "error");
        } finally {
            setLoading(false);
        }
    };
    
    
    const fetchEmailServers = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-servers`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const result = await response.json();
    
            if (result.data.length > 0) {
                setServers(result.data);
            } else {
                setServers([]);
                showToast(t("noEmailServersAvailable"), "error");
            }
        } catch (error) {
            console.error("Error fetching email servers:", error);
            showToast(t("failedToFetchEmailServers"), "error");
            setServers([]);
        }
    };
    
    const handleAddPhishingCompaignSetting = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start
        setLoading(true);
    
        try {
            const token = localStorage.getItem("userToken");
            const formDataToSend = new FormData(); 
    
            formDataToSend.append("campaign_id", formData.campaign_id);
            formDataToSend.append("server_id", formData.server_id);
            formDataToSend.append("template_id", formData.template_id);
            formDataToSend.append("fake_domain", formData.fake_domain);
            formDataToSend.append("is_auto_send", formData.is_auto_send);
            formDataToSend.append("from_email_address", formData.from_email_address);
            formDataToSend.append("from_name", formData.from_name);
            formDataToSend.append("email_subject", formData.email_subject);
    
            if (formData.zip_file) {
                formDataToSend.append("zip_file", formData.zip_file);
            }
    
            const response = await fetch(api_url + "/phishing-campaign-settings", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`, 
                },
                body: formDataToSend,
            });
    
            const responseData = await response.json();
            if (response.ok) {
                showToast(t("phishingCampaignSettingAdded"), "success");
                handleClose();
                setFormData({
                    campaign_id: "",
                    server_id: "",
                    template_id: "",
                    fake_domain: "",
                    is_auto_send: 0,
                    zip_file: null,
                    from_email_address: "",
                    from_name: "",
                    email_subject: "",
                });
                navigate(-1);
            } else {
                showToast(responseData.message || t("failedToAddCampaignSetting"), "error");
            }
        } catch (error) {
            console.error("Error:", error);
            showToast(t("somethingWentWrong"), "error");
        } finally {
            setIsLoading(false); // Loader stop
        }
    };
    

    return (
        <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px", margin: "20px" }}>
        <div className="d-flex justify-content-end mb-3">
            <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
        </div>
    
        <Tabs defaultActiveKey="Add Video" className="mb-3">
            <Tab eventKey="Add Video" title={t("AddPhishingCampaignSetting")}>
                <form onSubmit={handleAddPhishingCompaignSetting}>
                    <Modal.Body className="adddepatmentWrapper">
                        {/* Row 1: Select Server & Fake Domain */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t("SelectServer")}</label>
                                    <select className="form-select" value={formData.server_id} onChange={handleServerChange}>
                                        <option value="">{t("SelectServer")}</option>
                                        {servers.length > 0 ? (
                                            servers.map((server) => (
                                                <option key={server.id} value={server.id}>
                                                    {server.name}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">{t("Noserversavailable")}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-3">
                                    <label className="form-label">{t('TemplateId')}</label>
                                    <select className="form-select" name="template_id" value={formData.template_id} onChange={handleChange} required>
                                        <option value="">{t("SelectTemplate")}</option>
                                        {users.length > 0 ? (
                                            users.map((template) => (
                                                <option key={template.id} value={template.id}>
                                                    {template.title}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">{t("Notemplatesavailable")}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
    
                        {/* Row 2: Template ID & Is Auto Send */}
                        <div className="row">
                            <div className="col-md-6">
                            <div className="mb-3">
                                    <label className="form-label">{t('FakeDomain')}</label>
                                    <input type="text" autoComplete="off" name="fake_domain" className="form-control" onChange={handleChange} value={formData.fake_domain} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-3">
                                    <label className="form-label">{t('ZipFile')}</label>
                                    <input
                                        type="file"
                                        name="zip_file"
                                        className="form-control"
                                        accept=".zip"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
    
                        {/* Row 3: Zip File & Form Email Address */}
                        <div className="row">
                            <div className="col-md-6">
                            <div className="mb-3">
                                    <label className="form-label">{t('EmailSubject')}</label>
                                    <input type="text" autoComplete="off" name="email_subject" className="form-control" onChange={handleChange} value={formData.email_subject} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('FormEmailAddress')}</label>
                                    <input type="email" autoComplete="off" name="from_email_address" className="form-control" onChange={handleChange} value={formData.from_email_address} required />
                                </div>
                            </div>
                        </div>
    
                        {/* Row 4: From Name & Email Subject */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('FormName')}</label>
                                    <input type="text" name="from_name" className="form-control" onChange={handleChange} value={formData.from_name} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-3">
                                    <label className="form-label" style={{marginTop:'34px'}}>
                                        <input
                                            type="checkbox"
                                            name="is_auto_send"
                                            className="form-check-input me-2"
                                            onChange={(e) => handleChange({
                                                target: { name: 'is_auto_send', value: e.target.checked ? "1" : "0" }
                                            })}
                                            checked={formData.is_auto_send === "1"}
                                        />
                                        {t('IsAutoSend')}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
    
                    <Modal.Footer>
                    <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                    {isLoading ? (
                                        <>
                                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                                            {t('Adding...')}
                                        </>
                                    ) : (
                                        t('AddPhishingCampaignSetting')
                                    )}
                                </button>
                    </Modal.Footer>
                </form>
            </Tab>
        </Tabs>
    </div>
    
    );
}

export default AddPhishingCompaignSetting;
