import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './EmailTemplates.css';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { Modal , Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const EmailTemplates = () => {
    const navigate = useNavigate();

    const [data, setUsers] = useState([]);
    const [links, setLinks] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [toastData, setToastData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedEmailId, setSelectedEmailId] = useState(null);
    

    // Close toast notification after a certain timeout
    const closeToast = () => {
        setToastData(null);
    };

    
    const handleDelete = async (Id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-templates/${Id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            if (response.ok) {
                showToast(t("emailtemplatedeletedsuccessfully"), "success");
                fetchEmailTemplate('');
                setShowModal(false);
            } else {
                setShowModal(false);
                showToast(t("failedtodeleteemailtemplate"), "error");
            }
            } catch (error) {
                setShowModal(false);
                showToast(t("somethingwentwrongpleaserefresh"), "error");
                setLoading(false);
            } finally {
            setLoading(false);

        }
    }

    useEffect(() => {
        if (searchVal !== '') {
            const filteredData = data.filter((item) =>
                item.title.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.created_at.toLowerCase().includes(searchVal.toLowerCase())
            );
            setUsers(filteredData);
        } else {
            fetchEmailTemplate('');
        }
    }, [searchVal]);

    const fetchEmailTemplate = async (url) => {
        setLoading(true);
        var page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            page = temp[1];
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-templates?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setUsers(result.data.data);
            console.log("result:",result);
            setLinks(result.data.links);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEmailTemplate('');
        localStorage.removeItem("hasFetched");
    }, []);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'title',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-card-text"></i>{t('Title')}
                    </div>
                ),
                colName:  t('Title'),
            },
            
            {
                accessorKey: 'subject',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-journal-bookmark"></i>{t('Subject')}
                    </div>
                ),
                colName:  t('Subject'),
            },
            {
                accessorKey: 'created_at',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_clock.png" alt="Created At" />{t('CreatedAt')}
                    </div>
                ),
                colName: t('CreatedAt'),
                cell: ({ row }) => {
                    const rawDate = row.original.created_at;
                    const formattedDate = new Date(rawDate).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                    });
                    return formattedDate;
                },
            },
            {
                accessorKey: 'id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue();
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            {/* <NavLink className="iconPreview" to={`/previewemailtemplate/${id}`} >
                                <i class="bi bi-search"></i> {t('Preview')}
                            </NavLink> */}
                            {/* <NavLink className="iconEdit" to={`/editemailtemplate/${id}`} >
                                <img src="/images/edit.png" alt="Add Email" /> {t('Edit')}
                            </NavLink> */}
                            <div className="iconDel" onClick={(e) => { e.stopPropagation(); setSelectedEmailId(id);setShowModal(true)
                                setLoading(true);
                                setLoading(false);
                            }}>
                                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: t('Action'),
            },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    const handleLessonRowClick = (id) => {
        navigate(`/EmailTemplatesDetail/${id}/EmailTemplatesDetail`);
    };
    return (
        <div className="p-4">
            <div className="pageTitle mb-4">{t('AllEmailTemplates')}</div>

            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('SearchEmailTemplate')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("Searchbytitle")}
                                aria-label="Search by name"
                                value={searchVal}
                                onChange={(e) => setSearchVal(e.target.value)}
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        {/* <label style={{ marginTop: '10px' }}>{t('Add New Email Template')}</label> */}
                        {/* <br /> */}
                        <NavLink className="btnWhiteGradient mt-3" to="/addemailtemplate">
                        <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddEmailTemplate')}
                        </NavLink>
                    </div>
                </div>
            </div>

         

            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}></div>
                </div>
            ) : (
                <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                                   {window.innerWidth < 768 ? (
                                       table.getRowModel().rows.map(row => (
                                           <div key={row.id} className="stacked-row"
                                               onClick={row.id.includes('id') === false ? () => handleLessonRowClick(row.original.id) : null}>
                                               {row.getVisibleCells().map(cell => (
                                                   <div key={cell.id} className="stacked-cell">
                                                       <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                                       {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                   </div>
                                               ))}
                                           </div>
                                       ))
                                   ) : (
                                       <table className="table table-bordered table-hover">
                                           <thead>
                                               {table.getHeaderGroups().map(headerGroup => (
                                                   <tr key={headerGroup.id}>
                                                       {headerGroup.headers.map(header => (
                                                           <th key={header.id}>
                                                               {flexRender(header.column.columnDef.header, header.getContext())}
                                                           </th>
                                                       ))}
                                                   </tr>
                                               ))}
                                           </thead>
                                           <tbody>
                                               {table.getRowModel().rows.map(row => (
                                                   <tr key={row.id}>
                                                       {row.getVisibleCells().map(cell => (
                                                           <td key={cell.id}
                                                               className='rowAnchor'
                                                               onClick={cell.id.includes('id') === false ? () => handleLessonRowClick(row.original.id) : null}>
                                                               {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                           </td>
                                                       ))}
                                                   </tr>
                                               ))}
                                           </tbody>
                                       </table>
                                   )}
                               </div>
            )}


            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (
                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchEmailTemplate(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                <Modal.Title>{t("ConfirmDelete")}</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            {t("Areyousureyouwanttodeletethisemailtemplate?")}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="danger" onClick={()=>handleDelete(selectedEmailId)} disabled={loading}>
                                {loading ? t("Deleting...") : t("Delete")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
        </div>
    );
}

export default EmailTemplates;
