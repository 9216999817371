import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { api_url } from '../../constant/constant';
import { NavLink } from 'react-router-dom';

const CertificateManager = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState([]);

    const fetchCertificateImages = async (url = '') => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + `/certificate/get-images`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setData(result.data || []);
            setLinks(result.data.links || []);
        } catch (error) {
            console.error('Error fetching data:', error);
            showToast(t("errorfetchingdata"), "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCertificateImages();
    }, []);

    useEffect(() => {
        if (searchValue) {
            setData(prevData => prevData.filter(item =>
                item.title?.toLowerCase().includes(searchValue.toLowerCase())
            ));
        } else {
            fetchCertificateImages();
        }
    }, [searchValue]);

    return (
        <div className="p-4">
            <style>
                {`
                .certificate-card {
                    transition: transform 0.3s ease-in-out;
                }
                .certificate-card:hover {
                    transform: scale(1.05);
                }
                .certificate-image {
                    border: 1px solid  #EAEAEA;
                    border-radius: 10px;
                    padding: 5px;
                    background-color: white;
                }
                `}
            </style>

            <div className="pageTitle mb-4">{t('UploadCertificateMenagement')}</div>
            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('CertificateMenagement')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("SearchCertificate")}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        <NavLink className="btnWhiteGradient mt-3" to="/UploadCertificate">
                            <i className="bi bi-envelope me-2 mt-3 h6"></i> {t('UploadCertificate')}
                        </NavLink>
                    </div>
                </div>
            </div>

            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}></div>
                </div>
            ) : (
                <div className="row">
                    {data.map(item => (
                        <div key={item.id} className="col-md-4 mb-4">
                            <div className="card shadow-sm border-0 rounded certificate-card"
                                onClick={() => navigate(`/Certificate/${item.id}/Certificate`)}
                                style={{ cursor: 'pointer' }}>
                                <img src={item.image_path} alt="Certificate" className="card-img-top certificate-image"
                                    style={{ height: '200px', objectFit: 'cover' }} />
                                <div className="card-body text-center">
                                    <h6 className="card-title">{t("Certificate ID")}: {item.id}</h6>
                                   
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Pagination */}
            <div className="navigationWrapper">
                <ul className="pagination">
                    {links.map((link, index) => (
                        <li key={index} className={`page-item ${link.active ? 'active' : ''} ${link.url === null ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => link.url !== null && fetchCertificateImages(link.url)}
                                dangerouslySetInnerHTML={{ __html: link.label }}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CertificateManager;
