import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Button } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditEmailTemplate from '../../Components/EditEmailTemplate/EditEmailTemplate';
import EmailTempPreview from '../../Components/EditEmailTemplate/EmailTempPreviewModel/EmailTempPreview';

const EmailTemplatesDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [key, setKey] = useState("editemail");
    return (
        <div className="p-4">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('EmailTemplatesDetail')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>
            
            <Tabs 
                id="controlled-tab-example"
                activeKey={key} 
                onSelect={(k) => setKey(k)}
                defaultActiveKey=""  
                className="mb-3"
            >
                <Tab eventKey="editemail" title={t('EditEmailTemplate')}>
                    {key === "editemail" && <EditEmailTemplate />} 
                </Tab>
                
                { <Tab eventKey="preview" title={t('Preview')}>
                    {key === "preview" && <EmailTempPreview />}  
                </Tab> }
            </Tabs>
        </div>
    );
};

export default EmailTemplatesDetail;
