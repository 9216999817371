import { Modal, Button, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { showToast } from "../ShowToast/ToastNotification";
import { Tab, Tabs } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./addcertificate.css";
import { useSearchParams } from "react-router-dom";

const AddCertificateManager = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState("");
    const [searchParams] = useSearchParams();
    const id = searchParams.get("Id");
    const [formData, setFormData] = useState({
        title: "",
        html_content: `<div class="certificate-container" style="text-align:center; padding:20px;">
        <p class="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
        <h3>{{firstname}} {{lastname}}</h3>
        <pclass="ql-align-center"><br></p>
        <pclass="ql-align-center"><br></p>
            <p>{{lesson_name}}</p>
                <pclass="ql-align-center"><br></p>
             <p> {{achievement_date}}</p>
              <pclass="ql-align-center"><br></p>
                <pclass="ql-align-center"><br></p>
                <pclass="ql-align-center"><br></p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{certificate_number}}</p>
        </div>`,
        language: "en",
        background_id:id
    });

    useEffect(() => {
        const fetchBackgroundImage = async () => {
            try {
                const token = localStorage.getItem("userToken");
                const response = await fetch(`${api_url}/certificate/get-images/${id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
    
                const res = await response.json(); 
    
                if (res?.data?.image_path) {
                    console.log("image path:",res?.data.image_path);
                    setBackgroundImage(res?.data?.image_path);
                }
            } catch (error) {
                console.error("Error fetching background image:", error);
            }
        };
    
        if (id) fetchBackgroundImage();
    }, [id]);
    

    useEffect(() => {
        if (backgroundImage) {
            const editor = document.querySelector("#addCertificate .ql-editor");
            if (editor) {
                editor.style.backgroundImage = `url(${backgroundImage})`;
            }
        }
    }, [backgroundImage]);

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            [{ font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["blockquote", "code-block"],
            ["link", "image", "video"],
            ["clean"],
        ],
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAddCertificate = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!formData.html_content.trim()) {
            showToast(t("HtmlContentRequired"), "error");
            setIsLoading(false);
            return;
        }
        const updatedHtmlContent = 
        formData
        .html_content
        .replace(/<p(.*?)>/g, '<p style="margin:0px; text-align:center;"$1>')
        .replace(/<h3(.*?)>/g, '<h3 style="text-align:center;margin-top:40px;"$1>');
    
        const wrappedHtmlContent = `
        <div style="background-image: url('${backgroundImage}'); 
                background-repeat: no-repeat;height:100vh;">
            ${updatedHtmlContent}
        </div>`;
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/certificate-templates", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ 
                    ...formData, 
                    html_content: wrappedHtmlContent // Send modified content
                }),
            });

            if (response.ok) {
                showToast(t("CertificateAddedSuccessfully"), "success");
                navigate(-1);
            } else {
                showToast(t("FailedToCreateCertificate"), "error");
            }
        } catch (error) {
            showToast(t("SomethingWentWrong"), "error");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="addCertificateContainer" style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", marginLeft: "19px", marginTop: "20px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('AddCertificate')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>

            <Tabs defaultActiveKey="AddCertificateManager" className="mb-3">
                <Tab eventKey="AddCertificateManager" title={t("AddCertificate")}>
                    <form onSubmit={handleAddCertificate} className="row g-3">
                        <div className="col-md-6">
                            <label className="form-label">{t("Title")}</label>
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.title}
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">{t("Language")}</label>
                            <select
                                name="language"
                                className="form-select"
                                onChange={handleChange}
                                value={formData.language}
                                required
                            >
                                <option value="en">{t("English")}</option>
                                <option value="ar">{t("Arabic")}</option>
                            </select>
                        </div>

                        <div className="col-12">
                            <label className="form-label">{t("HTMLContent")}</label>
                            <div className="quill-editor-container">
                                <ReactQuill
                                    className="ReactQuill"
                                    id="addCertificate"
                                    value={formData.html_content}
                                    onChange={(content) => setFormData({ ...formData, html_content: content })}
                                    modules={modules}
                                    style={{ minHeight: "200px", backgroundColor: "transparent" }}
                                />
                            </div>
                        </div>
                        {/* <div className="col-12">
                            <label className="form-label">{t("HTMLContent")}</label>
                            <div 
                                className="quill-editor-container"
                                style={{
                                    backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    padding: "20px", // To ensure content is readable
                                    minHeight: "300px", // Adjust as needed
                                    border: "1px solid #ccc", // Optional for better visibility
                                    borderRadius: "5px"
                                }}
                            >
                            <ReactQuill
                                className="ReactQuill"
                                id="addCertificate"
                                value={formData.html_content}
                                onChange={(content) => setFormData({ ...formData, html_content: content })}
                                modules={modules}
                                style={{ minHeight: "200px", backgroundColor: "transparent" }}
                            />
                        </div> */}
                    {/* </div> */}

                        <div className="mt-2">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner animation="border" size="sm" role="status" className="me-2" />
                                        {t('Adding...')}
                                    </>
                                ) : (
                                    t('AddCertificate')
                                )}
                            </button>
                        </div>
                    </form>
                </Tab>
            </Tabs>
        </div>
    );
};

export default AddCertificateManager;
