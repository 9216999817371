import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import './AddSurveyQuestion.css';
import { showToast } from '../ShowToast/ToastNotification';
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const AddSurveyQuestion = ({ parentfetchQuestion }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const survey_id = searchParams.get("survey_id");
  const [question_types, setQuestionTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    question_text_en: "",
    question_text_ar: "",
    question_type: ""
  });

  const fetchQuestionTypes = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(api_url + '/question-types', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result); // Verify API response
      setQuestionTypes(result.data || result);
    } catch (error) {
      console.error('Error fetching data:', error);
      showToast(t("errorFetchingData"), "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestionTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddSurveyQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/surveys/${survey_id}/questions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        showToast(t("surveyQuestionAddedSuccessfully"), "success");
        handleClose();
        setFormData({
          questionTextEn: "",
          questionTextAr: "",
          questionType: "",
        });
        navigate(-1);
      } else {
        showToast(t("failedToAddSurveyQuestion"), "error");
        handleClose();
      }
      } catch (error) {
        showToast(t("somethingWentWrong"), "error");
        handleClose();
      } finally {
        setLoading(false);
      }
      
  };

  return (
    <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", margin: "20px", marginTop: "20px" }}>
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
      </div>

      <Tabs defaultActiveKey="AddSurveyQuestion" className="mb-3">
        <Tab eventKey="AddSurveyQuestion" title={t("AddSurveyQuestion")}>
          <form onSubmit={handleAddSurveyQuestion}>
            <Modal.Body>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <div className="spinner-border loader-api" role="status" style={{
                    borderTopColor: "#6897CB",
                    borderBottomColor: "#6897CB",
                    borderLeftColor: "#6897CB",
                    marginTop: "50px"
                  }}></div>
                </div>
              ) : (
                <>
                  <div className="mb-3">
                    <label className="form-label">{t('Question(English)')}</label>
                    <input
                      type="text"
                      name="question_text_en"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.question_text_en}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">{t('Question(Arabic)')}</label>
                    <input
                      type="text"
                      name="question_text_ar"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.question_text_ar}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">{t('QuestionType')}</label>
                      <select name="question_type" className="form-select" onChange={handleChange} required>
                        <option value="">{t('SelectQuestionType')}</option>
                        {question_types && question_types.map((question, index) => (
                          <option key={index} value={question.value}>{question.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>

            <Modal.Footer>

              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? t('Adding...') : t('AddSurveyQuestion')}
              </Button>
            </Modal.Footer>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AddSurveyQuestion;
