import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { api_url } from "../../constant/constant";
import './AddVideo.css';
import { useTranslation } from 'react-i18next';
import { showToast } from '../ShowToast/ToastNotification';
import { Tab, Tabs } from "react-bootstrap";

import { useLocation } from "react-router-dom";

const AddVideo = ({ parentfetchVideo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lesson_id = searchParams.get("lesson_id"); // Get the lesson_id
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    language: "en",
    video: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      video: e.target.files[0],
    });
  };

  const handleAddVideo = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Loader start

    console.log("language:", formData.language);
    const data = new FormData();
    data.append("language", formData.language);
    data.append("video", formData.video);

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/lessons/${lesson_id}/videos`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        body: data
      });

      const responseData = await response.json(); // Convert response to JSON

      if (response.ok) {
        showToast(t("videoAddedSuccessfully"), "success");
        handleClose();
        setFormData({
          language: "en",
          video: null
        });
        navigate(-1);
      } else {
        if (responseData.status === "error") {
          showToast(t(responseData.message) || responseData.message, "error");
        } else {
          showToast(t("failedToAddVideo"), "error");
        }
      }
      } catch (error) {
        showToast(t("somethingWentWrong"), "error");
      } finally {
        setIsLoading(false); // Loader stop
      }
      
  };



  return (
    <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", marginLeft: "19px", marginTop: "20px" }}>
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
      </div>

      <Tabs defaultActiveKey="Add Video" className="mb-3">
        <Tab eventKey="Add Video" title={t("AddVideo")}>
          <form onSubmit={handleAddVideo}>

            <div className="mb-3">
              <label className="form-label">{t('Language')}</label>
              <select className="form-select" aria-label="Language" value={formData.language} name="language" onChange={handleChange} required >
                <option value="en">{t('English')}</option>
                <option value="ar">{t('Arabic')}</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">{t('Video')}</label>
              <input
                type="file"
                className='form-control'
                accept="video/*"
                onChange={handleFileChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" role="status" className="me-2" />
                  {t('Adding...')}
                </>
              ) : (
                t('AddVideo')
              )}
            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AddVideo;
