import React, { useState } from 'react';
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserPoliciesDetails from './UserPoliciesDetails';
const UserPolicies = () => {
    const { id } = useParams(); // Ensure the route contains a parameter named 'id'

    const { t } = useTranslation();
    const [key, setKey] = useState("UserPolicies");
    // State variables



    return (
        <div className="p-4">

            <div className="pageTitle mb-4">{t('UserPolicies')}</div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >

                <Tab eventKey="UserPolicies" title={t('UserPolicies')}>
                    <div>
                        <UserPoliciesDetails />
                    </div>
                </Tab>

            </Tabs>

        </div>
    );
};

export default UserPolicies;