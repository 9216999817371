import { Modal, Button ,Spinner} from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditLDAPServer.css';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";



const EditLearningManager = ({ id, parentfetchLDAPServer }) => {
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const ldap_id = id;
    const { t } = useTranslation();
    const [Security_types, setSecurity_types] = useState([]);


    const fetchldapsecuritytypes = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/ldapsecuritytypes', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result); // Verify the API response
            setSecurity_types(result.data || result); // Adjust based on API structure
        } catch (error) {
            console.error("Error fetching data:", error);
            showToast(t("errorFetchingData"), "error");
          }          
    };
    const [Server_types1, fetchserverTypes1] = useState([]);
    const fetchldapserverTypes1 = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/ldapservertypes', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result); // Verify the API response
            fetchserverTypes1(result.data || result); // Adjust based on API structure
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchLDAPServer = async () => {

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/ldap_servers/${ldap_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                showToast(t("errorFetchingLdapServerDetails"), "error");
              }
              

            const res = await response.json();
            const data=res.data;

            setFormData({
                ...formData,
                ["title"]: data.title,
                ["host"]: data.host,
                ["port"]: data.port,
                ["server_type"]: data.server_type,
                ["security_type"]: data.security_type,
                ["bind_dn"]: data.bind_dn,
                ["password"]: data.password,
                ["base"]: data.base,
                ["filter"]: data.filter,
                ["map_user_name"]: data.map_user_name,
                ["map_first_name"]: data.map_first_name,
                ["map_last_name"]: data.map_last_name,
                ["map_email"]: data.map_email,
                ["map_department"]: data.map_department,
                ["map_phone_number"]: data.map_phone_number,
                ["map_group"]: data.map_group,
                ["enable_group_sync"]: data.enable_group_sync,
                ["map_group_filter"]: data.map_group_filter,
            });

        }
        catch (err) {
            showToast(t("somethingWentWrongRefresh"), "error");
          }          
    }

    useEffect(() => {
        // fetchQuestionTypes();
        fetchldapserverTypes1();
        fetchLDAPServer();
        fetchldapsecuritytypes();

    }, []);


    // const handleClose = () => {
    //     setToastData({ msg: "Error fetching LDAP Server details.", type: "error" });
    //     setTimeout(closeToast, 3000);
    // };
    // const handleShow = () => {
    //     fetchldapsecuritytypes();
    //     fetchldapserverTypes1();
    //     fetchLDAPServer();
    // }

    const [formData, setFormData] = useState({
        title: "",
        host: "",
        port: "",
        server_type: "",
        security_type: "",
        bind_dn: "",
        password: "",
        base: "",
        filter: "",
        map_user_name: "",
        map_first_name: "",
        map_last_name: "",
        map_email: "",
        map_department: "",
        map_phone_number: "",
        map_group: "",
        enable_group_sync: "",
        map_group_filter: "",

    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };



    const handleUpdateLDAPServer = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/ldap_servers/${ldap_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });


            if (response.ok) {
                showToast(t("ldapServerUpdatedSuccessfully"), "success");
              } else {
                showToast(t("failedToUpdateLdapServer"), "error");
              }
              } catch (error) {
                showToast(t("somethingWentWrongRefresh"), "error");
              } finally {
                setIsLoading(false); // Loader stop
              }              
    }

    return (
        <div className="py-4" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '0px 15px' }}>
            <form onSubmit={handleUpdateLDAPServer}>

                <div className="row">
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('Title')}</label>
                            <input type="text" name="title" className="form-control" onChange={handleChange} value={formData.title} required />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('Host')}</label>
                            <input type="text" name="host" className="form-control" onChange={handleChange} value={formData.host} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('Port')}</label>
                            <input type="number" name="port" className="form-control" onChange={handleChange} value={formData.port} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('ServerType')}</label>
                            <select name="server_type" className="form-select" onChange={handleChange} value={formData.server_type} required>
                                <option></option>
                                {Server_types1 && Server_types1.map((Server, index) => (
                                    <option value={Server}>{Server}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('SecurityType')}</label>
                            <select name="security_type" className="form-select" onChange={handleChange} required value={formData.security_type}>
                                <option></option>
                                {Security_types && Security_types.map((Security, index) => (
                                    <option value={Security}>{Security}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('BindDn')}</label>
                            <input type="text" name="bind_dn" className="form-control" onChange={handleChange} value={formData.bind_dn} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('Password')}</label>
                            <input type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('Base')}</label>
                            <input type="text" name="base" className="form-control" onChange={handleChange} value={formData.base} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('Filter')}</label>
                            <input type="text" name="filter" className="form-control" onChange={handleChange} value={formData.filter} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('MapUserName')}</label>
                            <input type="text" name="map_user_name" className="form-control" onChange={handleChange} value={formData.map_user_name} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('MapFirstName')}</label>
                            <input type="text" name="map_first_name" className="form-control" onChange={handleChange} value={formData.map_first_name} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('MapLastName')}</label>
                            <input type="text" name="map_last_name" className="form-control" onChange={handleChange} value={formData.map_last_name} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('MapEmail')}</label>
                            <input type="text" name="map_email" className="form-control" onChange={handleChange} value={formData.map_email} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('MapDepartment')}</label>
                            <input type="text" name="map_department" className="form-control" onChange={handleChange} value={formData.map_department} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('MapPhoneNumber')}</label>
                            <input type="tel" name="map_phone_number" className="form-control" onChange={handleChange} value={formData.map_phone_number} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('MapGroup')}</label>
                            <input type="text" name="map_group" className="form-control" onChange={handleChange} value={formData.map_group} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">{t('MapGroupFilter')}</label>
                            <input type="text" name="map_group_filter" className="form-control" onChange={handleChange} value={formData.map_group_filter} required />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3 d-flex align-items-center" style={{ padding: '0 5px', marginTop: '20px', fontSize: 'large' }}>
                            <input
                                type="checkbox"
                                name="enable_group_sync"
                                className="form-check-input custom-checkbox"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        enable_group_sync: e.target.checked ? true : false,
                                    })
                                }
                                checked={formData.enable_group_sync === true}
                            />
                            <label className="form-label ms-2 mt-2 custom-label">{t('EnableGroupSync')}</label>
                        </div>
                    </div>
                </div>

                 <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                    {isLoading ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                            {t('Updateing...')}
                                                        </>
                                                    ) : (
                                                        t('UpdateLDAPServer')
                                                    )}
                                                </button>
            </form>
        </div>
    );

}

export default EditLearningManager;