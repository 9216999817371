import React, { useState, useEffect ,useRef} from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../ShowToast/ToastNotification";
import { Button, Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EmailTemplate.css";

const EditEmailTemplate = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ title: "", body: "" });
    const [isLoading, setIsLoading] = useState(true);
    const [showHtml, setShowHtml] = useState(false);
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            [{ font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["blockquote", "code-block"],
            ["link", "image", "video"],
            ["clean"],
        ],
    };

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("EditEmailTemp"));
        const hasFetched = localStorage.getItem("hasFetched"); 
        console.log("hasFetched:", hasFetched);
        if (!hasFetched) { // Only fetch if not fetched before
            fetchEmailTemplates();
        }else{
            if (storedData && storedData.id === id) {
                setFormData(storedData); 
                setIsLoading(false);
            }
        }
    }, []);

    const fetchEmailTemplates = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem("userToken");

            const response = await fetch(`${api_url}/email-templates/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const { data } = await response.json();
                const newData = { id, title: data.title || "", body: data.body || "" };
                
                setFormData(newData);
                localStorage.setItem("EditEmailTemp", JSON.stringify(newData)); 
                localStorage.setItem("hasFetched", "true"); // Mark as fetched
            } else {
                throw new Error("Failed to fetch data");
            }
        } catch (error) {
            showToast(t("somethingWentWrongRefresh"), "error");
          } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevState) => {
            const updatedFormData = { ...prevState, [name]: value, id };
            localStorage.setItem("EditEmailTemp", JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };

    const handleEditorChange = (content) => {
        setFormData((prevState) => {
            const updatedFormData = { ...prevState, body: content, id };
            localStorage.setItem("EditEmailTemp", JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-templates/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                showToast(t("emailTemplateUpdatedSuccess"), "success");
                localStorage.removeItem("EditEmailTemp");
                localStorage.removeItem("hasFetched");
                navigate(-1);
              } else {
                showToast(t("failedToUpdateEmailTemplate"), "error");
              }
              } catch (error) {
                showToast(t("somethingWentWrongRefresh"), "error");
              }finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="m-3">
            <form onSubmit={handleSubmit} className="d-flex flex-column">
                <div className="mb-3">
                    <label>{t("Title")}</label>
                    <input
                        type="text"
                        name="title"
                        className="form-control"
                        value={formData.title}
                        onChange={handleChange}
                        required
                    />
                </div>

                {!isLoading && (
                    <>
                        <div className="d-flex justify-content-between mb-2">
                            <label>{t("EmailBody")}</label>
                            <Button
                                type="button"
                                onClick={() => setShowHtml(!showHtml)}
                                className="btn btn-secondary"
                            >
                                {showHtml ? t("SwitchtoEditor") : t("ViewHTMLSource")}
                            </Button>
                        </div>

                        {/* Editor container */}
                        <div className="email-editor-container">
                            {showHtml ? (
                                <textarea
                                    value={formData.body}
                                    onChange={(e) => handleEditorChange(e.target.value)}
                                    className="form-control"
                                />
                            ) : (
                                <ReactQuill
                                    value={formData.body}
                                    onChange={handleEditorChange}
                                    modules={modules}
                                    className="quill-editor"
                                />
                            )}
                        </div>
                    </>
                )}
            </form>

            <div className="button-container">
                {!showHtml && (
                    <button 
                        type="button" 
                        className="btn btn-primary update-btn" 
                        disabled={isLoading} 
                        onClick={handleSubmit}  
                    >
                        {isLoading ? (
                            <>
                                <Spinner animation="border" size="sm" role="status" className="me-2" />
                                {t('Update...')}
                            </>
                        ) : (
                            t('UpdateEmailTemplate')
                        )}
                    </button>
                )}
            </div>
        </div>
    );
};

export default EditEmailTemplate;
