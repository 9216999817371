import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useParams } from 'react-router-dom';
import AddPolice from '../../Components/AddPoliceModal/AddPolice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { NavLink } from 'react-router-dom';

const Police = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // State variables
    const [data, setLessons] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedPoliceId, setSelectedPoliceId] = useState(null);

    // Handle navigation to Question page with lesson id
    const handleQuestionClick = (id) => {
        navigate(`/question/${id}`); // Pass lesson id as a route parameter
    };

    // Handle delete user action
    const handleDelete = async (id) => {
        setLoading(true);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/policies/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                showToast(t("policydeletedsuccessfully"), "success");
                fetchPolice('');
                setShowModal(false);
            } else {
                setShowModal(false);
                showToast(t("failedtodeletepolicy"), "error");
            }
            } catch (error) {
                setShowModal(false);
                showToast(t("somethingwentwrong"), "error");
                setLoading(false);
            } finally {
            setLoading(false);
        }

    }
    useEffect(() => {

        if (searchVal !== '') {
            const filteredData = data.filter((item) =>
                item.name_en.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.name_ar.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.content_en.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.content_ar.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.version.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.policy_type.toLowerCase().includes(searchVal.toLowerCase())||
                item.pdf_file.toLowerCase().includes(searchVal.toLowerCase())
            );
            setLessons(filteredData); // Update filtered data
        } else {
            fetchPolice(''); // Update filtered data
        }
    }, [searchVal]);
    // Fetch questions data with pagination
    const fetchPolice = async (url) => {
        setLoading(true);
        let page = 1;

        // Determine page from URL if provided
        if (url) {
            const temp = url.split('?page=');
            page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
        }

        try {
            const token = localStorage.getItem("userToken");

            // Fetch data from the API
            const response = await fetch(`${api_url}/policies?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            // Extract 'current_page' and 'data'
            // const { current_page, data } = result;

            console.log('Current Page:', result.data.current_page); // Log current page
            console.log('status:', data.data); // Log data array

            // Use the data as needed
            setLessons(result.data.data); // Assuming setLessons is a state function for data


        } catch (error) {
            console.error('Error fetching data:', error);
            showToast(t("errorfetchingdata"), "error");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchPolice('');
    }, []);

    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Name(English)')}
                    </div>
                ),
                colName: t('Name(English)'),
                colName: t('Name(English)'),
            },
            {
                accessorKey: 'name_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Name(Arabic)')}
                    </div>
                ),
                colName: t('Name(Arabic)'),
                colName: t('Name(Arabic)'),
            },
            {
                accessorKey: 'content_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Content_en(English)')}
                    </div>
                ),
                colName: t('Content_en(English)'),
                cell: ({ getValue }) => {
                    const content = getValue();
                    return content && content.length > 0 ? (
                        <span>{content.length > 200 ? `${content.substring(0, 200)}......` : content}</span>
                    ) : null; // Show nothing if content_en is empty
                },
            },
            {
                accessorKey: 'content_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Content_ar(Arabic)')}
                    </div>
                ),
                colName: t('Content_ar(Arabic)'),
                cell: ({ getValue }) => {
                    const content = getValue();
                    return content && content.length > 0 ? (
                        <span>{content.length > 200 ? `${content.substring(0, 200)}......` : content}</span>
                    ) : null; // Show nothing if content_en is empty
                },
            },
            {
                accessorKey: 'version',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Version')}
                    </div>
                ),
                colName: t('Version'),
            },
            {
                accessorKey: 'policy_type',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i className="bi bi-clipboard-data"></i>{t('PolicyType')}
                    </div>
                ),
                colName: t('PolicyType'),
                colName: t('PolicyType'),
            },

            {
                accessorKey: 'id', // Correct accessor key
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue(); // Extract the value of the 'id' column
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>


                            {/* <EditPolice id={id} parentfetchPolice={fetchPolice} /> */}

                            {/* Delete button */}
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <div className="iconDel" onClick={(e) => { e.stopPropagation(); setSelectedPoliceId(id); setShowModal(true) }}>
                                    <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                                </div>
                            </div>
                        </div>
                    );
                },
                colName: t('Action'), // Correct property spelling
                colName: t('Action'), // Correct property spelling
            },

        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    const handleSurveyRowClick = (id) => {
        navigate(`/PoliceDetail/${id}/PoliceDetail`)
    }
    return (
        <div className="p-4">
            <div className="pageTitle mb-4">{t('AllPolicies')}</div>
            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('SearchPolicy')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("Searchbyname")}
                                aria-label="Search Policy"
                                value={searchVal} // Bind input value to searchVal state
                                onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        {/* <AddPolice parentfetchPolice={fetchPolice} /> */}
                           <NavLink className="btnWhiteGradient mt-3" to="/Add-Police">
                                     <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddPolicy')}
                           </NavLink>
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center  mt-5">
                    <div className="spinner-border loader-api" role="status" style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>
                    </div>
                </div>
            ) : (

                <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                    {window.innerWidth < 768 ? (
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row"
                                onClick={row.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <table className="table table-bordered table-hover">
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}
                                                className='rowAnchor'
                                                onClick={cell.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchPolice(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>
                          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                                        <Modal.Header closeButton>
                                        <Modal.Title>{t("ConfirmDelete")}</Modal.Title>
                                            </Modal.Header>
                                                <Modal.Body>
                                                    {t("Areyousureyouwanttodeletethispolicy?")}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                                                        {t("Cancel")}
                                                    </Button>
                                                    <Button variant="danger" onClick={()=>handleDelete(selectedPoliceId)} disabled={loading}>
                                                        {loading ? t("Deleting...") : t("Delete")}
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
        </div>
    );
};

export default Police;
