import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useParams } from 'react-router-dom';
import AddQuestion from '../../Components/AddQuestionModal/AddQuestion';
// import EditQuestion from '../../Components/EditQuestionModal/EditQuestion';
// import AnswersModal from '../../Components/AnswersModal/AnswersModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { showToast } from '../../Components/ShowToast/ToastNotification';


const Questions = ({ id }) => {

    const lesson_id = id
    const { t } = useTranslation();
    const navigate = useNavigate();
    // State variables
    const [data, setLessons] = useState([]);
     const [searchVal, setSearchVal] = useState('');
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    // Handle navigation to Question page with lesson id
    const handleQuestionClick = (id) => {
        navigate(`/question/${id}`); // Pass lesson id as a route parameter
    };


    // Handle delete user action
    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/questions/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                showToast(t("questiondeletedsuccessfully"), "success");
                setShowModal(false);
                fetchQuestions('');
            } else {
                showToast(t("failedtodeletequestion"), "error");
            }
            } catch (error) {
                setShowModal(false);
                showToast(t("somethingwentwrong"), "error");
                setLoading(false);
            } finally {
            setLoading(false);
        }

    }
    useEffect(() => {
            if (searchVal !== '') {
                const filteredData = data.filter((item) =>
                    item.question_en.toLowerCase().includes(searchVal.toLowerCase()) ||
                    item.question_ar.toLowerCase().includes(searchVal.toLowerCase())
                );
                setLessons(filteredData);
            } else {
                fetchQuestions('');
            }
        }, [searchVal]);

    // Fetch questions data with pagination
    const fetchQuestions = async (url) => {
        setLoading(true);
        let page = 1;
        if (url) {
            const temp = url.split('?page=');
            page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/questions?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });


            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setLessons(result.questions);
        } catch (error) {
            console.error('Error fetching data:', error);
            showToast(t("errorfetchingdata"), "error");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchQuestions('');
    }, []);

    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'question_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Question(English)')}
                    </div>
                ),
                colName: t('Question(English)'),
            },
            {
                accessorKey: 'question_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Question(Arabic)')}
                    </div>
                ),
                colName: t('Question(Arabic)'),
            },

            {
                accessorKey: 'id', // Correct accessor key
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue(); // Extract the value of the 'id' column
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>

                            {/* <AnswersModal question_id={id} /> */}

                            {/* EditQuestion component with required props */}
                            {/* <EditQuestion id={id} parentfetchQuestion={fetchQuestions} /> */}

                            {/* Delete button */}
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <div className="iconDel" onClick={(e) => {e.stopPropagation(); setSelectedQuestionId(id); setShowModal(true) }}>
                                    <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                                </div>
                            </div>
                        </div>
                    );
                },
                colName: t('Action'), // Correct property spelling
            },

        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    const handleLessonRowClick = (id) => {
        navigate(`/LessonQuestion?lesson_id=${lesson_id}&question=${id}`);
    };

    return (
        <div className="p-1">

            {/* <div className="pageTitle mb-4">All Questions</div> */}

            <div className="primaryBlueBox pad mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('SearchQuestion')}</label>
                        <div className="input-group">
                            <input  type="text"
                                className="form-control"
                                placeholder={t("Searchquestion")}
                                aria-label="Search question"
                                value={searchVal}
                                onChange={(e) => setSearchVal(e.target.value)}/>
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        {/* <AddQuestion parentfetchQuestion={fetchQuestions} /> */}
                        <NavLink className="btnWhiteGradient mt-3"to={`/Add-Question?lesson_id=${lesson_id}`}>
                                 <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddQuestion')}
                        </NavLink>
                    </div>
                </div>
            </div>

            {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}
            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>
                    </div>
                </div>
            ) : (

                  <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                                   {window.innerWidth < 768 ? (
                                       table.getRowModel().rows.map(row => (
                                           <div key={row.id} className="stacked-row"
                                               onClick={row.id.includes('id') === false ? () => handleLessonRowClick(row.original.id) : null}>
                                               {row.getVisibleCells().map(cell => (
                                                   <div key={cell.id} className="stacked-cell">
                                                       <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                                       {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                   </div>
                                               ))}
                                           </div>
                                       ))
                                   ) : (
                                       <table className="table table-bordered table-hover">
                                           <thead>
                                               {table.getHeaderGroups().map(headerGroup => (
                                                   <tr key={headerGroup.id}>
                                                       {headerGroup.headers.map(header => (
                                                           <th key={header.id}>
                                                               {flexRender(header.column.columnDef.header, header.getContext())}
                                                           </th>
                                                       ))}
                                                   </tr>
                                               ))}
                                           </thead>
                                           <tbody>
                                               {table.getRowModel().rows.map(row => (
                                                   <tr key={row.id}>
                                                       {row.getVisibleCells().map(cell => (
                                                           <td key={cell.id}
                                                               className='rowAnchor'
                                                               onClick={cell.id.includes('id') === false ? () => handleLessonRowClick(row.original.id) : null}>
                                                               {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                           </td>
                                                       ))}
                                                   </tr>
                                               ))}
                                           </tbody>
                                       </table>
                                   )}
                               </div>
            )}



            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchQuestions(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                <Modal.Title>{t("ConfirmDelete")}</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            {t("Areyousureyouwanttodeletethisquestion?")}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="danger" onClick={()=>handleDelete(selectedQuestionId)} disabled={loading}>
                                {loading ? t("Deleting...") : t("Delete")}
                            </Button>
                        </Modal.Footer>
                    </Modal>               
        </div>
    );
};

export default Questions;
