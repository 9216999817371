import { Modal, Button, Spinner } from "react-bootstrap";
import React, { useEffect, useState, } from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import './AddLearningManager.css';
import { showToast } from "../ShowToast/ToastNotification";
import { Tab, Tabs } from "react-bootstrap";

const AddLearningManager = ({ parentfetchLearningManager }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const [campaign_types, setCampaignTypes] = useState([]);
    const [formData, setFormData] = useState({
        type: "",
        title_ar: "",
        title_en: "",
        description_ar: "",
        description_en: "",
        start_date: "",
        start_time: "",
        end_date: "",
        video_seek: "",
        success_rate:"",
    });

    const [show, setShow] = useState(false);


    const fetchLearningManagerTypes = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/learning-campaigns/types", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
            setCampaignTypes(result.campaign_types || []);
        } catch (error) {
            console.error("Error fetching campaign types:", error);
            showToast(t("errorFetchingCampaignTypes"), "error");
        }
    };

    useEffect(() => {
        fetchLearningManagerTypes();
    }, []);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAddLearningManager = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start
    
        // Convert dates to JavaScript Date objects
        const startDate = new Date(formData.start_date);
        const endDate = new Date(formData.end_date);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        if (!formData.success_rate || formData.success_rate === 0) {
            showToast("Success Rate is required.", "error");
            setIsLoading(false);
            return;
        }
        // ✅ Validate start date
        if (startDate < today) {
            showToast(t("startDateValidation"), "error");
            return;
        }
    
        // ✅ Validate end date
        if (endDate <= startDate) {
            showToast(t("endDateValidation"), "error");
            return;
        }
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/learning-campaigns", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });
    
            if (response.ok) {
                showToast(t("learningManagerAdded"), "success");
                handleClose();
                navigate(-1);
            } else {
                const errorResult = await response.json();
                console.error("Error response:", errorResult);
                showToast(t("learningManagerAddFailed"), "error");
            }
        } catch (error) {
            console.error("Error adding Learning Manager:", error);
            showToast(t("unexpectedError"), "error");
        } finally {
            setIsLoading(false); // Loader stop
        }
    };
    


    return (
        <div className="addEmailContainer" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '26px 26px', margin: '20px', marginTop: '20px' }}>
            <div className="d-flex justify-content-between mb-3">
                <div className="pageTitle">{t('AddLearningCampaign')}</div>
                <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
            </div>

            <Tabs defaultActiveKey="AddLearningcampaign" className="mb-3">
                <Tab eventKey="AddLearningcampaign" title={t("AddLearningCampaign")}>
                    <form onSubmit={handleAddLearningManager} className="addEmailForm">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Type')}</label>
                                    <select
                                        name="type"
                                        className="form-select"
                                        onChange={handleChange}
                                        value={formData.type}
                                        required
                                    >
                                        <option value="">Type</option>
                                        {campaign_types.map((type, index) => (
                                            <option key={index} value={type}>{type}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Titlear')}</label>
                                    <input
                                        type="text"
                                        name="title_ar"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.title_ar}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Titleen')}</label>
                                    <input
                                        type="text"
                                        name="title_en"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.title_en}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Descriptionar')}</label>
                                    <input
                                        type="text"
                                        name="description_ar"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.description_ar}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Descriptionen')}</label>
                                    <input
                                        type="text"
                                        name="description_en"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.description_en}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('StartDate')}</label>
                                    <input
                                        type="date"
                                        name="start_date"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.start_date}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('StartTime')}</label>
                                    <input
                                        type="time"
                                        name="start_time"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.start_time}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('EndDate')}</label>
                                    <input
                                        type="date"
                                        name="end_date"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.end_date}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
    <div className="mb-3">
        <label className="form-label">{t('VideoSeek')}</label>
        <div className="dropdown">
            <select
                name="video_seek"
                className="form-select"  // Bootstrap class for better dropdown UI
                onChange={handleChange}
                value={formData.video_seek}
                required
            >
                <option value=""> Select Video Seek</option>
                <option value="backward">Backward</option>
                <option value="forward"> Forward</option>
                <option value="both"> Both</option>
            </select>
        </div>
    </div>
</div>
                   

                            <div className="col-md-6">
    <div className="mb-3">
        <label className="form-label">{t('Success Rate')}</label>
        {/* Range Slider */}
        <input
    type="range"
    className="form-range"
    name="success_rate"
    min="0"
    max="100"
    step="1"
    value={formData.success_rate || 0}
    required 
    onChange={(e) => setFormData(prev => ({ ...prev, success_rate: Number(e.target.value) }))}
/>

    </div>

    <div className="flex items-center gap-2">
        <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 h-1.5">
            <div 
                className="bg-blue-600 rounded-full h-1.5" 
                style={{ width: `${formData.success_rate || 0}%` }}
            />
        </div>
        {/* Success Rate Number */}
        <span className="text-sm font-medium text-gray-700">
            {formData.success_rate || 0}%
        </span>
    </div>
</div>






                        </div>

                        <div className="text-end">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner animation="border" size="sm" role="status" className="me-2" />
                                        {t('Adding...')}
                                    </>
                                ) : (
                                    t('AddLearningCampaign')
                                )}
                            </button>
                        </div>
                    </form>
                </Tab>
            </Tabs>
        </div>
    );
};

export default AddLearningManager;
