import { Modal, Button, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './AddUser.css';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { Tab, Tabs } from "react-bootstrap";

const AddUser = ({ onfetchUsers }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const [show, setShow] = useState(false);
    const [roles, setRoles] = useState([]);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        fetchRoles();
    }, []);

    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        username: "",
        password: "",
        role_id: "",
        status_id: "",
        source_id: "2"
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const fetchRoles = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/admin/main/user/roles', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                showToast(t("errorFetchingRoles"), "error");
                return;
              }
              
              const rolesData = await response.json();
              setRoles(rolesData);
              } catch (err) {
                showToast(t("somethingWentWrong"), "error");
              }
              
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start
    
        console.log(formData);
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/users", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
    
            const data = await response.json();
    
            if (response.ok) {
                setFormData({
                  firstname: "",
                  lastname: "",
                  email: "",
                  phoneNumber: "",
                  username: "",
                  password: "",
                  roleId: "",
                  statusId: "",
                  sourceId: "2",
                });
              
                navigate(-1);
                handleClose();
                showToast(t("userAddedSuccessfully"), "success");
              } else {
                if (data.errors?.email) {
                  showToast(data.errors.email[0], "error");
                  handleClose();
                } else if (data.message) {
                  showToast(t(data.message) || data.message, "error");
                }
              }
              } catch (error) {
                showToast(t("somethingWentWrong"), "error");
                handleClose();
              }
              
        finally {
            setIsLoading(false); // Loader stop
        }
    };
    

    return (
        <div className="addEmailContainer" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '26px 26px', margin: '20px' }}>

            <div className="d-flex justify-content-between mb-3">
                <div className="pageTitle">{t('AddUser')}</div>
                <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
            </div>

            <Tabs defaultActiveKey="AddUser" className="mb-3">
                <Tab eventKey="AddUser" title={t("AddUser")}>
                    <form onSubmit={handleAddUser} className="addEmailForm">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('FirstName')}</label>
                                    <input type="text" autoComplete="off" name="firstname" className="form-control" onChange={handleChange} value={formData.firstname} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('LastName')}</label>
                                    <input type="text" autoComplete="off" name="lastname" className="form-control" onChange={handleChange} value={formData.lastname} required />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Email')}</label>
                                    <input type="email" autoComplete="off" name="email" className="form-control" onChange={handleChange} value={formData.email} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('PhoneNumber')}</label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="phone_number"
                                        className="form-control"
                                        maxLength={15}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 15) {
                                                handleChange(e);
                                            }
                                        }}
                                        value={formData.phone_number}
                                        required
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Username')}</label>
                                    <input type="text" autoComplete="off" name="username" className="form-control" onChange={handleChange} value={formData.username} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Password')}</label>
                                    <input autoComplete="off" type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} required />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Role')}</label>
                                    <select className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                        <option value="">{t('SelectRole')}</option>
                                        {roles.map(role => (
                                            <option key={role.id} value={role.id}>{role.title}</option>
                                        ))}
                                        {/* <option value="1">{t('Supersoft')}</option>
                        <option value="2">{t('Professional Service')}</option>
                        <option value="3">{t('Admin')}</option> */}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Status')}</label>
                                    <select className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                        <option value=""></option>
                                        <option value="1">{t('Active')}</option>
                                        <option value="0">{t('Inactive')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="text-end">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner animation="border" size="sm" role="status" className="me-2" />
                                        {t('Adding...')}
                                    </>
                                ) : (
                                    t('AddUser')
                                )}
                            </button>
                        </div>
                    </form>
                </Tab>
            </Tabs>
        </div>
    );

}

export default AddUser;