import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Button ,Spinner } from 'react-bootstrap';
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import './AddQuestion.css';
import { showToast } from '../ShowToast/ToastNotification';
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Question = ({ parentfetchQuestion }) => {
  const navigate = useNavigate();
  const location = useLocation();
      const [isLoading, setIsLoading] = useState(false); // Loader state
  
  const searchParams = new URLSearchParams(location.search);
  const lesson_id = searchParams.get("lesson_id");
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    question_en: "",
    question_ar: "",
    lesson_id: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddQuestion = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Loader start

    console.log(formData);

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/lessons/${lesson_id}/questions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
        redirect: "follow",
      });

      if (response.ok) {
        showToast(t("questionAddedSuccessfully"), "success");
        handleClose();
        setFormData({
          questionEn: "",
          questionAr: "",
          lessonId: "",
        });
        navigate(-1);
      } else {
        showToast(t("failedToAddQuestion"), "error");
        handleClose();
      }
      } catch (error) {
        showToast(t("somethingWentWrong"), "error");
        handleClose();
      } finally {
        setIsLoading(false); // Loader stop
      }
      
  };


  return (

    <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", marginLeft: "19px", marginTop: "20px" }}>
        <div className="d-flex justify-content-end mb-3">
                       <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
                   </div>

      <Tabs defaultActiveKey="addEmail" className="mb-3">
        <Tab eventKey="addEmail" title={t("AddQuestion")}>

          <form onSubmit={handleAddQuestion}>
            <Modal.Body>
              {/* <div className="mb-3">
            <label className="form-label">Lesson ID</label>
            <input
              type="text"
              name="lesson_id"
              className="form-control"
              onChange={handleChange}
              value={formData.lesson_id}
            />
          </div> */}
              <div className="mb-3">
                <label className="form-label">{t('Question(English)')}</label>
                <input
                  type="text"
                  name="question_en"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.question_en}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t('Question(Arabic)')} </label>
                <input
                  type="text"
                  name="question_ar"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.question_ar}
                />
              </div>

            </Modal.Body>

            <Modal.Footer>
              {/* <Button variant="secondary" onClick={handleClose}>
              {t('Close')}
            </Button> */}
           <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                               {isLoading ? (
                                                   <>
                                                       <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                       {t('Adding...')}
                                                   </>
                                               ) : (
                                                   t('AddQuestion')
                                               )}
                                           </button>
            </Modal.Footer>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Question;
