import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const Licensekey = () => {
  const { t } = useTranslation();
  const [license, setLicense] = useState([]);
  const [loading, setLoading] = useState(true);

  // To store form data for each license
  const [formData, setFormData] = useState({});
  const handleCheckboxChange = (e, id) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: {
        ...prevData[id],
        [name]: checked ? 1 : 0, // Store 1 for checked, 0 for unchecked
      },
    }));
  };
  
  const handleInputChange = (e, id, field) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    
    setFormData(prevData => ({
      ...prevData,
      [id]: {
        ...prevData[id],
        [field]: value,
      },
    }));
  };

  const api_url = "https://api.ethaba.com/api"; // Updated API URL

  const fetchLicense = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        setLoading(false);
        return;
      }

      const response = await fetch(`${api_url}/customer/licenses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setLicense(result.data);

      // Initialize formData for each license
      const initialFormData = result.data.reduce((acc, item) => {
        acc[item.id] = {
          license_type: item.license_type || "",
          system_license_max_users: item.system_license_max_users || "",
          system_license_start_date: item.system_license_start_date || "",
          system_license_expiration_date: item.system_license_expiration_date || "",
          phishing_license_max_users: item.phishing_license_max_users || "",
          phishing_license_start_date: item.phishing_license_start_date || "",
          phishing_license_expiration_date: item.phishing_license_expiration_date || "",
          is_active: item.is_active || "",
          is_used: item.is_used || "",
        };
        return acc;
      }, {});
      
      setFormData(initialFormData); // Set initial form data for each license
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLicense();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (!license || license.length === 0) return <p>No license data found.</p>;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // Handle form submission here
  };

  return (
    <div>
  <h3>{t("LicenseDetails")}</h3>
  <form onSubmit={handleSubmit}>
    {license.map((item) => (
      <div key={item.id}>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">{t("LicenseType")}</label>
              <input
                type="text"
                name="license_type"
                className="form-control"
                onChange={(e) => handleInputChange (e, item.id, "license_type")}
                disabled
                value={formData[item.id]?.license_type || ""}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">{t("SystemLicenseMaxUsers")}</label>
              <input
                type="text"
                name="system_license_max_users"
                className="form-control"
                onChange={(e) => handleInputChange(e, item.id, "system_license_max_users")}
                disabled
                value={formData[item.id]?.system_license_max_users || ""}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">{t("SystemLicenseStartDate")}</label>
              <input
                type="text"
                name="system_license_start_date"
                className="form-control"
                onChange={(e) => handleInputChange(e, item.id, "system_license_start_date")}
                disabled
                value={formData[item.id]?.system_license_start_date || ""}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">{t("SystemLicenseExpirationDate")}</label>
              <input
                type="text"
                name="system_license_expiration_date"
                className="form-control"
                onChange={(e) => handleInputChange(e, item.id, "system_license_expiration_date")}
                disabled
                value={formData[item.id]?.system_license_expiration_date || ""}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">{t("PhishingLicenseMaxUsers")}</label>
              <input
                type="text"
                name="phishing_license_max_users"
                className="form-control"
                onChange={(e) => handleInputChange(e, item.id, "phishing_license_max_users")}
                disabled
                value={formData[item.id]?.phishing_license_max_users || ""}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">{t("PhishingLicenseStartDate")}</label>
              <input
                type="text"
                name="phishing_license_start_date"
                className="form-control"
                onChange={(e) => handleInputChange(e, item.id, "phishing_license_start_date")}
                disabled
                value={formData[item.id]?.phishing_license_start_date || ""}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">{t("PhishingLicenseExpirationDate")}</label>
              <input
                type="text"
                name="phishing_license_expiration_date"
                className="form-control"
                onChange={(e) => handleInputChange(e, item.id, "phishing_license_expiration_date")}
                disabled
                value={formData[item.id]?.phishing_license_expiration_date || ""}
              />
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                name="is_active"
                checked={formData[item.id]?.is_active === 1}
                disabled // Ensure correct check for 'is_active'
                onChange={(e) => handleCheckboxChange(e, item.id)}
                id="is_active"
              />
              <label className="form-check-label" htmlFor="is_active">
                {t("is_active")}
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                name="is_used"
                checked={formData[item.id]?.is_used === 1} 
                disabled// Ensure correct check for 'is_used'
                onChange={(e) => handleCheckboxChange(e, item.id)}
                id="is_used"
              />
              <label className="form-check-label" htmlFor="is_used">
                {t("is_used")}
              </label>
            </div>
          </div> */}
        </div>
      </div>
    ))}
    {/* <button type="submit">Submit</button> */}
  </form>
</div>

  

  );
};

export default Licensekey;
