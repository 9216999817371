import React, { useState } from 'react';
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Company from '../SystemSettings/Company';
import Customer from '../SystemSettings/Customer';
import CustomerName from '../SystemSettings/CustomerName';
import Licensekey from '../../Components/LicensekeyModel/Licensekey';
import Captcha from '../../Components/CaptchaModal/Captcha';
import FA from '../../Components/2FAModal/2FA';
const PhishingCompaignDetail = () => {
    const { id } = useParams(); // Ensure the route contains a parameter named 'id'

    const { t } = useTranslation();
    const [key, setKey] = useState("customer_name");
    // State variables



    return (
        <div className="p-4">

            <div className="pageTitle mb-4">{t('CompanySettings')}</div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >

                <Tab eventKey="customer_name" title={t('CustomersName')}>
                    <div>
                        <CustomerName />
                    </div>
                </Tab>
                <Tab eventKey="Licensekey" title={t("LicenseDetails")}>
                    <div>
                        <Licensekey />
                    </div>
                </Tab> 

                <Tab eventKey="customer" title={t('CustomersDetails')}>
                    <div>
                        <Customer />
                    </div>
                </Tab>
                    
                {/* <Tab eventKey="details" title={t("Settings")}>
                    <div>
                        <Company />
                    </div>
                </Tab>            */}
                <Tab eventKey="Captcha" title={t("Captcha")}>
                    <div>
                        <Captcha />
                    </div>
                </Tab> 
                <Tab eventKey="2FA" title={t("2FA")}>
                    <div>
                        <FA />
                    </div>
                </Tab> 
            </Tabs>

        </div>
    );
};

export default PhishingCompaignDetail;