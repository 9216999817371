import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { api_url, LOGO_SRC } from "../../constant/constant";

const Term = () => {
    const location = useLocation();
    const navigate = useNavigate(); // ✅ useNavigate added
    const terms = location.state?.terms;
    console.log("location", location.state);

    const { t } = useTranslation();
    const [loadingCircle, setCircleLoading] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(""); 
    const [successMessage, setSuccessMessage] = useState(""); 

    const token=localStorage.getItem("userToken");

    const handleLoginSubmit = async (e) => {
        const email=localStorage.getItem("userEmail");
        const password=localStorage.getItem("userPassword");
        try {
          // Proceed with login request
          const response = await fetch(api_url + "/login", {
            mode: "cors",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              login: email,
              password,
            }),
          });
          localStorage.setItem("userEmail", email);
          localStorage.setItem("userPassword", password);
          if (!response.ok) {
            const errorData = await response.json();
              if (errorData.terms) {
                localStorage.setItem("userToken", errorData.token);  
                navigate('/Terms', { state: { terms: errorData.terms } }); 
            }
            if (errorData.requires_gender_selection) {
              localStorage.user_id = errorData.user.user_id;
              window.location = "/Updategender";
              return;
            }
          }
    
          const data = await response.json();
          console.log("data response:",data);
          if (data.user?.force_reset_password === 1) {
            console.log(data.user);
            console.log("force password");
            localStorage.setItem("userToken", data.token);
            localStorage.setItem("userData", JSON.stringify(data.user));
            window.location = "/updatepassword";
            return false;
          }
          console.log("data roleId:",data.user.role_id);
          if (data.user.role_id === 5) {
            localStorage.setItem("userToken", data.token);
            localStorage.setItem("userData", JSON.stringify(data.user));
            if (data?.session_lifetime) {
              localStorage.session_lifetime=data.session_lifetime;
            }
            if (data.assessment_required == true) {
              localStorage.setItem("questions", JSON.stringify(data.questions));
              window.location = "/Assessment";
            }
            else {
              localStorage.setItem("userToken", data.token);
              localStorage.setItem("userData", JSON.stringify(data.user));
              if (data?.session_lifetime) {
                localStorage.session_lifetime=data.session_lifetime;
              }
              window.location = "/dashboardNormal";
              
            }
          } else {
            // Handle normal login
            localStorage.setItem("userToken", data.token);
            localStorage.setItem("userData", JSON.stringify(data.user));
            if (data?.session_lifetime) {
              localStorage.session_lifetime=data.session_lifetime;
            }
            window.location = "/dashboard";
            
          }
        } catch (err) {
          console.error(err.message);
        }
    };

    const handleAccept = async () => {
        if (!isAccepted) return; 

        setCircleLoading(true);
        setErrorMessage(""); // ✅ Clear previous errors

        try {
            const response = await fetch(`${api_url}/global/accept-terms`, { 
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                }
            });

            const result = await response.json();
            if (response.ok) {
                if(result.status == "success") {
                    setSuccessMessage(t("termsAcceptedSuccessfully"));
                    handleLoginSubmit();
                    // navigate("/login"); // ✅ Redirect to login page
                }
            } else {
                setErrorMessage(result.message || t("somethingWentWrong"));
            }
            } catch (error) {
                console.error("API Error:", error);
                setErrorMessage(t("somethingWentWrong"));
            } finally {
            setCircleLoading(false);
        }
    };

    return (
        <div className="container text-center my-5 d-flex align-items-center justify-content-center flex-column">
            <div className="loginLogo mt-5">
                <img src={LOGO_SRC} alt="Cyber Sense" />
            </div>
            <h2 className="m-5 txtLoginAccount">{t('Loginaccount')}</h2>
            <div className="card mt-5 pb-5 col-md-6" style={{borderRadius: "30px"}} >
                <div className="card-body">
                    {terms?.title ?(
                        <h2 className="m-5 txtLoginAccount">{terms?.title}</h2>
                    ):(
                        <h2 className="m-5 txtLoginAccount">{t('TermsandConditions')}</h2>
                    )}

                    {/* Render Terms Content with Border */}
                    <div
                        style={{
                            border: "2px solid #6897CB",
                            padding: "15px",
                            borderRadius: "10px",
                            maxHeight: "300px", 
                            overflowY: "auto", 
                        }}
                    >
                        {terms ? (
                            <div dangerouslySetInnerHTML={{ __html: terms.content || terms.content_en }} />
                        ) : (
                            <p>{t('Notermsavailable')}</p>
                        )}
                    </div>

                    {/* Loader */}
                    {loadingCircle && (
                        <div className="d-flex justify-content-center align-items-center mt-5">
                            <div
                                className="spinner-border loader-api"
                                role="status"
                                style={{
                                    borderTopColor: "#6897CB",
                                    borderBottomColor: "#6897CB",
                                    borderLeftColor: "#6897CB",
                                    marginTop: "50px",
                                }}
                            ></div>
                        </div>
                    )}

                    {/* Error Message */}
                    {errorMessage && (
                        <div className="alert alert-danger mt-3">{errorMessage}</div>
                    )}
                    {successMessage && (
                        <div className="alert alert-success mt-3">{successMessage}</div>
                    )}
                    {/* Accept Terms Checkbox */}
                    <div className="form-check mt-4" style={{display:"flex",justifyContent:"start",alignItems:"center",gap:"10px"}}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="acceptTerms"
                            checked={isAccepted}
                            onChange={() => setIsAccepted(!isAccepted)}
                            style={{height:"18px",width:"18px",border:"1px solid gray",marginTop:"2px"}}
                        />
                        <label className="form-check-label" htmlFor="acceptTerms">
                            {t('IaccepttheTermsandConditions')}.
                        </label>
                    </div>

                    {/* Accept Button */}
                    <button
                        className="btn btn-primary mt-3"
                        disabled={!isAccepted || loadingCircle}
                        onClick={handleAccept}
                        style={{width:"200px"}}
                    >
                        {loadingCircle ? t("Processing...") : t('Accept')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Term;
