import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { api_url } from "../../constant/constant";
import './LcLesson.css';
import { useNavigate } from 'react-router-dom';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import AddLcLesson from "../../Components/AddLcLessonModal/AddLcLesson";
import { showToast } from "../ShowToast/ToastNotification";
import { NavLink } from 'react-router-dom';

const LcLesson = ({ LearningManager_id }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [lesson_ids, setLessonids] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    // State variables
    const [data, setLcUser] = useState([]);
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(false);

    // Handle navigation to Question page with lesson id
    const handleAnswersClick = (id) => {
        navigate(`/question/${id}`); // Pass lesson id as a route parameter
    };
    const [selectedIds, setSelectedIds] = useState([]);

    const handleCheckboxChange = (e, id) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            // Add the id to selectedIds if it's checked
            setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
        } else {
            // Remove the id from selectedIds if it's unchecked
            setSelectedIds((prevSelectedIds) => prevSelectedIds.filter((selectedId) => selectedId !== id));
        }
    };


   
    const handleRemove = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");

            const response = await fetch(`${api_url}/learning-campaigns-lessons/${LearningManager_id}/detach-lessons`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    lesson_ids: selectedIds, // Send the selected lesson ids in the request body
                }),
            });

            if (response.ok) {
                showToast(t("lessonDeleted"), "success");
                fetchLcLesson("");
            } else {
                console.error("Delete Lesson Failed:", response.statusText);
                showToast(t("lessonDeleteFailed"), "error");
            }
        } catch (error) {
            console.error("Error deleting lesson:", error);
            showToast(t("somethingWentWrong"), "error");
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (searchValue !== '') {
            const filteredData = data.filter((item) =>
                item.title_en.toLowerCase().includes(searchValue.toLowerCase())
                || item.code.toLowerCase().includes(searchValue.toLowerCase())
            );
            setLcUser(filteredData);
        }
        else {
            fetchLcLesson('');
        }
    }, [searchValue]);
    // Fetch questions data with pagination
    const fetchLcLesson = async (url) => {
        setLoading(true);
        let page = 1;
        if (url) {
            const temp = url.split('?page=');
            page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/learning-campaigns-lessons/${LearningManager_id}/lessons`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setLcUser(result.data.data);  // Assuming 'data' contains the user list
        } catch (error) {
            console.error("Error fetching data:", error);
            showToast(t("errorFetchingData"), "error");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'title_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Titleen')}
                    </div>
                ),
                colName: t('Titleen'),
            },
            {
                accessorKey: 'code',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Code')}
                    </div>
                ),
                colName: t('Code'),
            },



            {
                accessorKey: 'id', // Correct accessor key
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue(); // Extract the value of the 'id' column
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>

                            {/* Checkbox component */}
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e, id)}
                            // You can add a checked prop here if needed, e.g., checked={isChecked}
                            />
                        </div>
                    );
                },
                colName: t('Action'), // Correct property spelling
            }


        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (


            <div className="p-4">
                {/* <div className="pageTitle mb-4">All Answers</div> */}

                <div className="primaryBlueBox mb-4" id="padd">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="form-label">{t('SearchLesson')}</label>
                            <div className="input-group">
                                <input  type="text"
                                className="form-control"
                                placeholder={t("Searchbytitle")}
                                aria-label="Search by name"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)} />
                                <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                    <i className="bi bi-search"></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-1">
                            <div className="vrLine"></div>
                        </div>
                        <div className="col-md-7">
                            {/* <AddLcLesson LcLesson_id={LearningManager_id} parentfetchLcLesson={fetchLcLesson} /> */}
                            <NavLink className="btnWhiteGradient mt-3" to={`/Add-Lc-Lesson?LearningManager_id=${LearningManager_id}`}>
                                                          <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddLcLesson')}
                                                </NavLink>
                        </div>
                    </div>
                </div>

            
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center  mt-5">
                        <div className="spinner-border loader-api" role="status" style={{
                            borderTopColor: "#6897CB",
                            borderBottomColor: "#6897CB",
                            borderLeftColor: "#6897CB",
                            marginTop: "50px"
                        }}>
                        </div>
                    </div>
                ) : (

                    <div className="bg-light px-3 py-3 userTableWrapper resTable">

                        {window.innerWidth < 768 ? (
                            // Render stacked view for small screens
                            table.getRowModel().rows.map(row => (
                                <div key={row.id} className="stacked-row">
                                    {row.getVisibleCells().map(cell => (
                                        <div key={cell.id} className="stacked-cell">
                                            {cell.column.columnDef.colName != "ID" ? (
                                                <strong>{cell.column.columnDef.colName}: </strong>
                                            ) : ('')}

                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </div>
                                    ))}
                                </div>
                            ))
                        ) : (
                            <div className="1">
                                <table className="table table-bordered table-hover">
                                    <thead className="thead-dark">
                                        {table.getHeaderGroups().map(headerGroup => (
                                            <tr key={headerGroup.id}>
                                                {headerGroup.headers.map(header => (
                                                    <th key={header.id}>
                                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>

                                    <tbody>
                                        {table.getRowModel().rows.map(row => (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map(cell => (
                                                    <td key={cell.id}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="button-container">
                                    <button onClick={handleRemove} class="btn btn-danger" disabled={selectedIds.length === 0}>
                                        {t('RemoveSelected')}
                                    </button>

                                </div>

                            </div>
                        )}


                    </div>
                )}


                <div className="navigationWrapper">
                    <ul>
                        {links.map((link, index) => (

                            <li key={index}
                                className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                                onClick={link.url !== null ? () => fetchLcLesson(link.url) : undefined}
                            >
                                <div dangerouslySetInnerHTML={{ __html: link.label }} />
                            </li>
                        ))}
                    </ul>
                </div>

            </div>

    );
};

export default LcLesson;
