import React,{ useEffect, useState } from 'react';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import './Head.css';
import { Link } from "react-router-dom";

const Head = () => {

    const { t } = useTranslation();

    const [firstName, setFirstName] = useState('true');

    useEffect(() => {
        var jsonData = localStorage.getItem("userData");
        var userData = JSON.parse(jsonData);
        setFirstName(userData?.user?.firstname);
    }, []);

    const handleLogout = () => {

        localStorage.removeItem("userToken");
        localStorage.removeItem("userData");
        localStorage.clear();
        window.location = "/login";
    }

    return (
        <div className="p-4 siteHeadWrapper">

            <div className="rightHeadWrapper">

            </div>

            <div className="leftHeadWrapper">
                <div>
                    <LanguageSwitcher />
                </div>
                
                <div className="helpWrapper">
                    <i className="bi bi-question-circle"></i> {t('Help')}
                </div>

                <div className="profileWrapper d-flex align-items-center">
                    <span className="profileLogo">
                        <img src="/images/profile.png" width="32" alt="{firstName}" />
                    </span>

                    <span className="nav-item dropdown">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#profile"
                            data-bs-toggle="dropdown" 
                            id="submenu"
                            role="button"
                            aria-expanded="false"
                        >
                            {firstName}
                        </a>
                        <ul
                            className="dropdown-menu"
                            aria-labelledby="submenu"
                            id="dropdown"
                            style={{ marginRight: "-20px" }}
                        >
                           <li>
    <Link className="dropdown-item" to="/profile">
        {t("Profile")}
    </Link>
</li>
                            <li>
                                {/* <a className="dropdown-item" href="#settings">
                                    {t("Settings")}
                                </a> */}
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={handleLogout}>
                                    {t("Logout")}
                                </button>
                            </li>
                        </ul>
                    </span>

                </div>
            </div>

        </div>
    );
}

export default Head;