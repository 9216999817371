import { Modal, Button } from "react-bootstrap";
import React,{ useState } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { showToast } from "../ShowToast/ToastNotification";
import { Tab, Tabs } from "react-bootstrap";

const AddLicenseManager = ({ parentFetchLicenses }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [licenseKey, setLicenseKey] = useState("");
    const [validationKey, setValidationKey] = useState("");
        const navigate = useNavigate();  

    const handleClose = () => {
        setValidationKey("");
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const handleLicenseChange = (e) => {
        setLicenseKey(e.target.value);
    };

    const handleValidateLicense = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/validate-license`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ license_key: licenseKey }),
                credentials: "include",
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log(data);
    
                showToast(t("licenseSuccess"), "success");
                setLicenseKey("");
                navigate(-1);
            } else {
                const errorData = await response.json();
                showToast(t("licenseError", { message: errorData.message }), "error");
                handleClose();
            }
        } catch (error) {
            showToast(t("licenseUnknownError"), "error");
            handleClose();
        }
    };
    

    return (
        <div className="addEmailContainer" style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'26px 26px',margin:'20px',marginTop:'20px'}}>
       <div className="d-flex justify-content-end mb-3">
                           <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
                       </div>

{/* <h3 className="pageTitle">{t('Add Lesson')}</h3> */}

<Tabs defaultActiveKey="Add Lesson" className="mb-3">
<Tab eventKey="Add Lesson" title={t("AddValidateLicense")}>
<form onSubmit={handleValidateLicense}>

                    <Modal.Body className="addUserWrapper">
                        <div className="mb-3">
                            <label className="form-label">{t("EnteryourLicenseKey")}</label>
                            <textarea
                                name="license_key"
                                className="form-control"
                                rows="3"
                                value={licenseKey}
                                onChange={handleLicenseChange}
                                required
                            />
                        </div>


                        {validationKey && <div className="alert alert-info">Validation Key: {validationKey}</div>} {/* Show validation key */}
                    </Modal.Body>

                    <Modal.Footer>
                        {/* <Button variant="secondary" onClick={handleClose}>
                            {t("Close")}
                        </Button> */}
                        <Button variant="primary" type="submit">
                            {t("ValidateLicense")}
                        </Button>
                    </Modal.Footer>
                </form>
</Tab>
</Tabs>
</div>
    );
};

export default AddLicenseManager;
