import React, { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { showToast } from "../../Components/ShowToast/ToastNotification";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TermsAndConditions.css"

const TermsAndConditions = () => {
    const { t } = useTranslation();
    const [contentEn, setContentEn] = useState("");
    const [contentAr, setContentAr] = useState("");
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [id, setId] = useState(null);

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            [{ font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["blockquote", "code-block"],
            ["link", "image", "video"],
            ["clean"],
        ],
    };

    // Fetch Terms and Conditions
    const fetchTermsAndConditions = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/terms`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                showToast(t("failedToFetchData"), "error");
                return;
            }
            
            const data = await response.json();
            if (data.status === "success" && data.data.length > 0) {
                const termsData = data.data[0];
            
                if (!termsData.id) {
                    showToast(t("invalidIdReceived"), "error");
                    return;
                }
            
                setId(termsData.id);
                setTitle(termsData.title);
                setContentEn(termsData.content_en || "");
                setContentAr(termsData.content_ar || "");
            } else {
                showToast(t("noTermsFound"), "warning");
            }
            } catch (err) {
                showToast(t("errorFetchingData"), "error");
            } finally {
            setLoading(false);
        }
    };

    // Update Terms and Conditions
    const updateTermsAndConditions = async () => {
        if (!id) {
            showToast(t("invalidIdCannotUpdateTerms"), "error");
            return;
        }
        
        try {
            const token = localStorage.getItem("userToken");
            const requestBody = {
                id,
                title,
                content_en: contentEn,
                content_ar: contentAr,
            };
        
            const response = await fetch(`${api_url}/terms/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });
        
            const data = await response.json();
        
            if (data.status === "success") {
                showToast(t("termsUpdatedSuccessfully"), "success");
            } else {
                showToast(t("failedToUpdateTerms"), "error");
            }
        } catch (err) {
            showToast(t("errorUpdatingTerms"), "error");
        }        
    };

    useEffect(() => {
        fetchTermsAndConditions();
    }, []);

    return (
        <div className="py-4 d-flex flex-column">
            {loading ? (
                <div className="d-flex justify-content-center align-items-center flex-grow-1">
                    <div className="spinner-border" role="status" style={{ borderTopColor: "#6897CB" }}></div>
                </div>
            ) : (
                <div className="container">
                    <div className="row pb-2">
                        {/* English Editor */}
                        <div className="col-12">
                            <label className="form-label"><strong>{t("EnglishContent")}</strong></label>
                            <ReactQuill
                                value={contentEn}
                                onChange={setContentEn}
                                modules={modules}
                                className="w-100"
                            />
                        </div>

                        {/* Arabic Editor */}
                        <div className="col-12 mt-3">
                            <label className="form-label"><strong>{t("ArabicContent")}</strong></label>
                            <ReactQuill
                                value={contentAr}
                                onChange={setContentAr}
                                modules={modules}
                                className="w-100"
                            />
                        </div>
                    </div>

                    {/* Update Button */}
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <button className="btn btn-primary">
                                {t("Update")}
                            </button>
                        </div>
                    </div>
                </div>

            )}
        </div>



    );
};

export default TermsAndConditions;
