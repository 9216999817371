import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useTranslation } from 'react-i18next';

const Errorlogs = () => {
    const [data, setData] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [searchValue, setSearchVal] = useState('');
    const fetchLogs = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            if (!token) {
                console.error('No token found');
                setLoading(false);
                return;
            }

            const response = await fetch(`${api_url}/ldap/error-logs`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            if (data.success) {
                setData(data.data);
            } else {
                console.error('Failed to fetch logs:', data.message);
            }
        } catch (error) {
            console.error('Error fetching logs:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (searchValue !== '') {
            const filteredData = data.filter(log =>
                log.level.toLowerCase().includes(searchValue.toLowerCase()) ||
                log.message.toLowerCase().includes(searchValue.toLowerCase()) ||
                log.context.toLowerCase().includes(searchValue.toLowerCase())
            );
            setData(filteredData);
        }
        else {
            fetchLogs();
        }
    }, [searchValue]);
    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'level',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                       <i class="bi bi-bar-chart-fill"></i>{t('Level')}
                    </div>
                ),
                colName: t('Level'),
            },
            {
                accessorKey: 'message',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-envelope"></i>{t('Message')}
                    </div>
                ),
                colName: t('Message'),
            },
            {
                accessorKey: 'context',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-card-text"></i>{t('Context')}
                    </div>
                ),
                colName: t('Context'),
            },
            {
                accessorKey: 'created_at',
                header: () =>  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <img src="/images/fi_clock.png" alt="Created At" />{t('CreatedAt')}
            </div>
            },
            {
                accessorKey: 'id',
                header: () => <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
            </div>,
                cell: ({ getValue }) => {
                    const id = getValue();
                    return (
                        <div>
                            {/* Add delete functionality or actions here */}
                        </div>
                    );
                },
            },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">
            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('Error-logs')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("SearchErrorlogs")}
                                value={searchValue}
                                onChange={(e) => setSearchVal(e.target.value)}
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}

            <div className="bg-light px-3 py-3 userTableWrapper resTable">
                {loading ? (
                    <div>{t('Loading...')}</div>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <thead className="thead-dark">
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (
                        <li key={index} className={`${link.active ? "active" : ""} ${link.url === null ? "disabled" : ""}`}>
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Errorlogs;
