import React, { useState, useEffect } from "react";
import { showToast } from "../../../Components/ShowToast/ToastNotification";
import { api_url } from "../../../constant/constant";
import { useTranslation } from "react-i18next";
const UserPoliciesDetails = () => {
    const { t } = useTranslation();

    const [PolicyId, setPolicyId] = useState("");
    const [isLoading, setIsLoading] = useState(true); 
    const [PolicyTitle, setPolicyTitle] = useState("");
    const [Policytype, setPolicytype] = useState("");
    const [Policycontent, setPolicycontent] = useState("");
    const [showPolicy, setShowPolicy] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    // Fetch system logs
   
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${api_url}/user/dashboard`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        console.log("API Response:", result);

        if (result.status === "success") {
          if (Array.isArray(result.userPolicies) && result.userPolicies.length > 0) {
            const policy = result.userPolicies[0];
            setPolicyTitle(policy.name || "");
            setPolicyId(policy.policy_id || "");
            setPolicytype(policy.policy_type || "");
            setPolicycontent(policy.content || "");
            setShowPolicy(true);
          } 
        }
      } catch (err) {
        console.error('Fetch error:', err);
        showToast(t("somethingwentwrongpleaserefresh"), "error");
    } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleSavePolicy = async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${api_url}/user/mark-policy-as-read/${PolicyId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      const result = await response.json();
      console.log("Policy Save Response:", result);
  
      if (result.status === "success") {
        showToast(t("policyhasbeenmarkedasread"), "success");
        setShowPolicy(false);
    } else {
        showToast(t("failedtosavepolicy"), "error");
    }
    } catch (err) {
        console.error('Save Policy error:', err);
        showToast(t("somethingwentwrongwhilesavingthepolicy"), "error");
    }    
  };
    return (
        <div className="dashboard-container p-4">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">{t('Loading')}...</span>
            </div>
          </div>
        ) : (
          <>
            {/* Policy Section */}
            <div>
              {showPolicy && (
                <div>
                  <div className="policy-section col-md-10" style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'15px 25px'}}>
                    {Policytype === "HTML" ? (
                      <div dangerouslySetInnerHTML={{ __html: Policycontent }}></div>
                    ) : (
                      <p>{Policycontent}</p>
                    )}
                  </div>
  
                  <div className="mt-3" style={{ marginLeft: '0%' }}>
                  <div className="form-check">
                    <input 
                      type="checkbox" 
                      className="form-check-input" 
                      id="policyCheck" 
                      checked={isChecked} 
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="policyCheck">
                      {t('IAgreetothePolicyCampaign')}
                    </label>
                  </div>
  
                  <button 
                    className="btn btn-primary mt-2" 
                    onClick={handleSavePolicy} 
                    disabled={!isChecked} 
                  >
                    {t('AcceptPolicyCampaign')}
                  </button>
                </div>
  
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
};

export default UserPoliciesDetails;
