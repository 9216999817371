import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { Tabs, Tab } from "react-bootstrap";
import { api_url } from '../../constant/constant';
import { useParams, useNavigate } from 'react-router-dom';  // useNavigate ko import kiya hai
import EditQuestion from '../../Components/EditQuestionModal/EditQuestion';
import AnswersModal from '../../Components/AnswersModal/AnswersModal';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { Button } from "react-bootstrap";

// import './LessonDetail.css';

const LessonDetail = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    console.log("id",id);
    const [key, setKey] = useState("details");
    const navigate = useNavigate();  

    // State variables
    const [data, setVideos] = useState([]);
    const [links, setLinks] = useState([]);


    return (
        <div className="p-4">

            {/* Back Button & Lesson Details Title */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('QuestionDetails')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t("Back")}</Button>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="details" title={t("EditQuestion")}>
                    <div>
                        <EditQuestion id={id}  />
                    </div>
                </Tab>
                <Tab eventKey="AnswersModal" title={t("AnswersModal")}>
                    <div>
                        <AnswersModal question_id={id}  />
                    </div>
                </Tab>
            </Tabs>

        </div>
    );
};

export default LessonDetail;
