import React, { useState } from 'react';
import { Tabs, Tab, Button } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditLessonQuiz from './LessonQuiz';
import Videos from '../Videos/Videos';
import Questions from '../Questions/Questions';
import EditLesson from '../../Components/EditLesson/EditLesson';
import LessonQuiz from '../../Components/LessonQuizModal/LessonQuiz';

const LessonQuizDetail = () => {
    const { id: lesson_id } = useParams();  
    const { t } = useTranslation(); 
    const [key, setKey] = useState("details");
    const navigate = useNavigate();

    return (
        <div className="p-4">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('QuizDetails')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
            {/* <Tab eventKey="lessondetails" title="Lesson Details">
                    <div>
                        <EditLesson id={lesson_id} />
                    </div>
                </Tab> */}

                {/* <Tab eventKey="videos" title="Videos">
                    <div>
                        <Videos id={lesson_id}/>
                    </div>
                </Tab> */}

                {/* <Tab eventKey="questions" title="Questions">
                    <div>
                        <Questions id={lesson_id}/>
                    </div>
                </Tab> */}
                {/* <Tab eventKey="LessonQuiz" title="Lesson Quiz">
                    <div>
                        <LessonQuiz id={lesson_id}/>
                    </div>
                </Tab> */}

                <Tab eventKey="details" title={t("EditLessonQuiz")}>
                    <EditLessonQuiz lesson_id={lesson_id}/>
                </Tab>
               
            </Tabs>
        </div>
    );
};

export default LessonQuizDetail;



