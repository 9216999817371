import React,{ useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './QuizNormal.css';
import { api_url } from "../../constant/constant";
import { showToast } from "../../Components/ShowToast/ToastNotification";
import { t } from "i18next";


const QuizNormal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const lesson = location.state?.lesson;

  console.log("Lesson:", lesson);

  const [quizData, setQuizData] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  // Wait for lesson data before setting quizData
  useEffect(() => {
    if (lesson?.quiz) {
      const filteredQuiz = lesson.quiz.filter(q => !q.answered);
      setQuizData(filteredQuiz);
      setAnswers(Array(filteredQuiz.length).fill(null));
      if (filteredQuiz.length === 0) {
        setQuizCompleted(true);
      }
    }
  }, [lesson]);

  const handleAnswerSelect = (index) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = index;
    setAnswers(newAnswers);
  };
  const handleNext = async () => {
    if (answers[currentQuestion] === null) {
      setError(t("pleaseselectanoptionbeforeproceeding"));
      showToast(t("pleaseselectanoptionbeforeproceeding"), "error");
      return;
  }  

    const selectedOption = quizData[currentQuestion]?.options[answers[currentQuestion]];
    const questionId = quizData[currentQuestion].question_id;
    const answerId = selectedOption.option_id;

    setLoading(true);

    try {
      const res = await submitAnswer(questionId, answerId);
      console.log("response is:", res)
      console.log("Answer submitted:", { questionId, answerId });
      if (res === true) {
        if (currentQuestion < quizData.length - 1) {
          setCurrentQuestion((prev) => prev + 1);
        } else {
          // navigate("/quiz-completed"); // Redirect after the last question
          console.log("quiz completed");
          setQuizCompleted(true);
        }
      }
    } catch (error) {
      console.error("Error submitting answer:", error);
      setError(t("failedtosubmitanswer"));
      showToast(t("failedtosubmitanswer"), "error");
  }  

    setLoading(false);
  };
  const submitAnswer = async (questionId, answerId) => {
    console.log("questionId:", questionId);
    console.log("answerId:", answerId);
    try {
      // Send the selected gender to the backend API
      const response = await fetch(api_url + `/user/submit-quiz-answer/${questionId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`, // Assuming the token is stored in localStorage
        },
        body: JSON.stringify({ option_id: answerId })
      });

      if (!response.ok) {
        setLoading(false);
        return false;
      }

      const data = await response.json();
      console.log(data);
      if (data.status === "success") {
        setTotalScore(prevScore => prevScore + data.score); 
        return true;
      }
    } catch (err) {
      setError(t("erroroccurredwhilesubmittinganswer"));
      showToast(t("erroroccurredwhilesubmittinganswer"), "error");
      return false;
  }  
  };
  if (quizCompleted) {
    const maxScore = quizData.length * 100;
    const percentage = ((totalScore / maxScore) * 100).toFixed(2);
    return (
      <div className="quiz-container">
        <h2>{t("ThanksforSubmittingQuiz")}</h2>
        <p>{t("YourScore:")} {totalScore} / {maxScore} ({percentage}%)</p>
        <div className="finish-btn">
          <button onClick={() => navigate("/DashboardNormal")}>{t("Finish")}</button>
        </div>
      </div>
    );
  }
  if (!quizData) {
    return <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">{t("Loading...")}</span>
      </div>
    </div>
  }
  return (
    <div className="quiz-container">
      <h2>{quizData[currentQuestion]?.question}</h2>
      <div className="options">
        {quizData[currentQuestion]?.options.map((option, index) => (
          <label key={option.option_id} className="option">
            <input
              type="radio"
              name={`question-${currentQuestion}`}
              checked={answers[currentQuestion] === index}
              value={option.option_id}
              onChange={() => handleAnswerSelect(index)}
              disabled={loading}
            />
            {option.answer}
          </label>
        ))}
      </div>
      {error && <div className="error-message">{error}</div>}
      {loading && <div className="loading-text">{t("Submittinganswer...")}</div>}
      <div className="quiz-buttons">
        <button onClick={handleNext} disabled={loading}>
          {currentQuestion === quizData.length - 1 ? "Finish" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default QuizNormal;
