import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { Tabs, Tab, Button } from "react-bootstrap";
import { api_url } from '../../constant/constant';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditPhishingCompaign from '../../Components/EditPhishingCompaignModal/EditPhishingCompaign';
import PhishingCompaignuser from '../../Components/PhishingCompaignUserModal/PhishingCompaignUser';
import PhishingCompaignSetting from'../../Components/PhishingCompaignSettingModal/PhishingCompaignSetting';
const PhishingCompaignDetail = () => {
    const { id } = useParams(); // Ensure the route contains a parameter named 'id'
    console.log("phishingID:",id);
    const { t } = useTranslation();
    const [key, setKey] = useState("details");

    const navigate = useNavigate();

    return (
        <div className="p-4">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('PhishingCampaignDetail')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>


        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="details" title={t('PhishingCampaignDetail')}>
                <div>
                <EditPhishingCompaign id={id}  />
                            </div>
            </Tab>
            <Tab eventKey="Phishing Compaign user" title={t('PhishingCampaignUser')}>
                <div>
                <PhishingCompaignuser  id={id}/>
                            </div>
            </Tab>
            <Tab eventKey="Phishing Compaign Setting" title={t('PhishingCampaignSetting')}>
                <div>
                <PhishingCompaignSetting  id={id}/>
                            </div>
            </Tab>
            
        </Tabs>

    </div>
    );
};

export default PhishingCompaignDetail;