import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { showToast } from "../ShowToast/ToastNotification"; // Custom toast function
import { api_url } from "../../constant/constant"; // API base URL
import { t } from "i18next";

function EmailTemplate({ LearningManager_id }) {
    const [campaignData, setCampaignData] = useState(null);
    const [emailServers, setEmailServers] = useState([]);
    const [servers, setServers] = useState([]);
    const [arabicTemplates, setArabicTemplates] = useState([]);
    const [englishTemplates, setEnglishTemplates] = useState([]);
    const [selectedEnglishTemplate, setSelectedEnglishTemplate] = useState("");
    const [selectedArabicTemplate, setSelectedArabicTemplate] = useState("");
    const [selectedServer, setSelectedServer] = useState("");
    const [fromEmail, setFromEmail] = useState("");
    const [fromName, setFromName] = useState("");
    const [emailSubject, setEmailSubject] = useState("");
    const [isAutoSend, setIsAutoSend] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCampaignData();
        fetchEmailTemplates();
        fetchEmailServers();
    }, []);

    // 📌 Fetch Campaign Data
    const fetchCampaignData = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/learning-campaigns-settings/${LearningManager_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok && response.status !== 404) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result?.campaign) setCampaignData(result.campaign);
            if (result?.email_servers) setEmailServers(result.email_servers);

            if (result.email_servers?.length > 0) {
                const server = result.email_servers[0];
                setSelectedEnglishTemplate(server.email_template_en?.id || "");
                setSelectedArabicTemplate(server.email_template_ar?.id || "");
                setSelectedServer(server.server_id || "");
                setFromEmail("example@example.com");
                setFromName("Campaign Name");
                setEmailSubject(server.mail_subject || "");
                setIsAutoSend(server.is_auto_send === 1);
            }
        } catch (error) {
            console.error("Failed to fetch campaign data:", error);
            showToast(t("errorFetchingCampaignData"), "error");
        }        
    };

    // 📌 Fetch Email Templates
    const fetchEmailTemplates = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/learning-campaigns-settings-templates`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            // Assuming the API response structure is correct
            setArabicTemplates(result.arabic_templates || []);
            setEnglishTemplates(result.english_templates || []);
        } catch (error) {
            console.error("Failed to fetch email templates:", error);
            showToast(t("errorFetchingEmailTemplates"), "error");
        }        
    };


    // 📌 Fetch Email Servers
    const fetchEmailServers = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-servers`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const result = await response.json();
            setServers(result.data || []);
        } catch (error) {
            console.error("Failed to fetch email servers:", error);
            showToast(t("errorFetchingEmailServers"), "error");
            setServers([]);
        }        
    };

    // 📌 Save Email Settings
    const handleSaveEmail = async (e) => {
        e.preventDefault();

        if (!selectedEnglishTemplate || !selectedArabicTemplate || !selectedServer || !fromEmail || !fromName || !emailSubject) {
            showToast("Please fill in all fields.", "error");
            return;
        }

        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/learning-campaigns-settings`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    campaign_id: LearningManager_id,
                    email_template_en_id: selectedEnglishTemplate,
                    email_template_ar_id: selectedArabicTemplate,
                    server_id: selectedServer,
                    from_email_address: fromEmail,
                    from_name: fromName,
                    email_subject: emailSubject,
                    is_auto_send: isAutoSend ? 1 : 0,
                }),
            });

            const data = await response.json();
            if (response.ok && data?.status === "success") {
                showToast(t("emailSettingsSaved"), "success");
            }else {
                showToast(t("errorSavingEmailSettings"), "error");
            }            
        } catch (err) {
            console.error("Error saving email settings:", err);
            showToast(t("errSavingEmailSettings"), "error");
        }finally {
            setLoading(false);
        }
    };

    return (
        <div className="container-fluid" style={{border:'1px solid #EAEAEA', borderRadius:'10px', padding:'15px'}}>
    <form onSubmit={handleSaveEmail}>
        {/* Email Templates */}
        <div className="row g-3 mt-3">
            <div className="col-12 col-md-6">
                <label htmlFor="englishTemplate" className="form-label">{t('EnglishTemplate')}</label>
                <select className="form-select" id="englishTemplate" value={selectedEnglishTemplate} onChange={(e) => setSelectedEnglishTemplate(e.target.value)} required>
                    <option value="">{t('SelectEnglishTemplate')}</option>
                    {englishTemplates.map((template) => (
                        <option key={template.id} value={template.id}>{template.title}</option>
                    ))}
                </select>
            </div>
            
            <div className="col-12 col-md-6">
                <label htmlFor="arabicTemplate" className="form-label">{t('ArabicTemplate')}</label>
                <select className="form-select" id="arabicTemplate" value={selectedArabicTemplate} onChange={(e) => setSelectedArabicTemplate(e.target.value)} required>
                    <option value="">{t('SelectArabicTemplate')}</option>
                    {arabicTemplates.map((template) => (
                        <option key={template.id} value={template.id}>{template.title}</option>
                    ))}
                </select>
            </div>
        </div>

        {/* Email Server */}
        <div className="row g-3 mt-3">
            <div className="col-12 col-md-6">
                <label htmlFor="email_servers" className="form-label">{t('EmailServer')}</label>
                <select className="form-select" id="email_servers" value={selectedServer} onChange={(e) => setSelectedServer(e.target.value)} required>
                    <option value="">{t('SelectEmailServer')}</option>
                    {servers.length > 0 ? (
                        servers.map((server) => (
                            <option key={server.id} value={server.id}>{server.name}</option>
                        ))
                    ) : (
                        <option disabled>{t('Noserversfound')}</option>
                    )}
                </select>
            </div>
            <div className="col-12 col-md-4 d-flex align-items-center" style={{marginTop:'40px'}}>
                <div className="d-flex align-items-center">
                <input type="checkbox" className="form-check-input me-2" checked={isAutoSend} onChange={() => setIsAutoSend(!isAutoSend)} />
                <label className="form-label mt-2">{t('AutoSendEmail')}</label>
                </div>
            </div>
        </div>

        {/* Email Address & Name */}
        <div className="row g-3 mt-3">
            <div className="col-12 col-md-6">
                <label className="form-label">{t('FromEmailAddress')}</label>
                <input type="email" className="form-control" value={fromEmail} onChange={(e) => setFromEmail(e.target.value)} required />
            </div>
            <div className="col-12 col-md-6">
                <label className="form-label">{t('FromName')}</label>
                <input type="text" className="form-control" value={fromName} onChange={(e) => setFromName(e.target.value)} required />
            </div>
        </div>

        {/* Email Subject */}
        <div className="row g-3 mt-3">
            <div className="col-12">
                <label className="form-label">{t('EmailSubject')}</label>
                <input type="text" className="form-control" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} required />
            </div>
        </div>

        {/* Save Button */}
        <div className="d-flex justify-content-end mt-3">
            <Button variant="primary" type="submit" disabled={loading}>
                {loading ? t("Saving...") : t("Save")}
            </Button>
        </div>
    </form>
</div>

    );
}

export default EmailTemplate;
