import { Modal, Button } from "react-bootstrap";
import React,{ useState } from "react";
import { NavLink } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './BulkUserImport.css';
import { showToast } from "../ShowToast/ToastNotification";
import { useTranslation } from "react-i18next";

const BulkUserImport = ({ onfetchUsers }) => {
    const [show, setShow] = useState(false);
    const [file, setFile] = useState(null);
    const { t } = useTranslation();
    

    const handleClose = () => {

        setFile(null);
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === "text/csv") {
            setFile(selectedFile);
            console.log("selectedFile:", selectedFile);
        } else {
            showToast(t("pleaseSelectValidCSV"), "error");
            handleClose();
          }          
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!file) {
            showToast(t("pleaseSelectCSVToUpload"), "error");
            handleClose();
            return;
          }
          

        try {
            const token = localStorage.getItem("userToken");
            const formData = new FormData();
            formData.append("csv_file", file, file.name);

            const response = await fetch(api_url + "/users/upload-csv", {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
                body: formData,
                redirect: "follow",
            });

            if (response.ok) {
                const result = await response.json();
                showToast(t("csvUploadedSuccessfully"), "success");
                handleClose();
                setFile(null);
                onfetchUsers('');
                setTimeout(() => {
                  handleClose();
                }, 2000);
              } else {
                showToast(t("failedToUploadCSV"), "error");
                handleClose();
              }
              } catch (error) {
                showToast(t("errorOccurredRefresh"), "error");
                handleClose();
              }              
    };

    return (
        <div>
            {/* <label style={{ marginTop: '10px' }}>Users Import</label> */}
            <br />
            <NavLink className="btn btn-success btnWhiteGradient2 mt-1" onClick={handleShow}>
                <i className="bi bi-file-earmark-excel-fill me-2"></i>{t('ImportUsers')}</NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('ImportUsers')}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="addUserWrapper">
                    <div className="mb-3">
                        <label className="form-label">{t('SelectCSVFile')}</label>
                        <input
                            type="file"
                            className="form-control"
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleUpload}>
                    {t('UploadCSV')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default BulkUserImport;
