import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { NavLink } from 'react-router-dom';
import "./LessonQuiz.css"

// import Editlessonquiz from'../../Components/EditLessonQuizModal/Editlessonquiz';
const LessonQuiz = ({ id }) => {
    const lesson_id = id;
    const { t } = useTranslation();
    const navigate = useNavigate();

    // State variables
    const [data, setLessons] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);

    // Handle navigation to Question page with lesson id
    const handleQuestionClick = (id) => {
        navigate(`/question/${id}`);
    };

    // Handle delete user action
    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/questions/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                showToast(t("questiondeletedsuccessfully"), "success");
                setShowModal(false);
            } else {
                showToast(t("failedtodeletequestion"), "error");
            }
            } catch (error) {
                setShowModal(false);
                showToast(t("somethingwentwrongpleaserefresh"), "error");
                setLoading(false);
            }finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (searchVal !== '') {
            const filteredData = data.filter((item) =>
                item.question_en.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.question_ar.toLowerCase().includes(searchVal.toLowerCase())
            );
            setLessons(filteredData);
        } else {
            fetchQuestions('');
        }
    }, [searchVal]);

    // Fetch questions data with pagination
// Fetch questions data with pagination
const fetchQuestions = async (url) => {
    setLoading(true);
    let page = 1;
    if (url) {
        const temp = url.split('?page=');
        page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
    }

    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/lessons/${lesson_id}/quiz`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        if(result?.quizzes){
            const rs=  result?.quizzes; 

            let questionData=[];
            rs.forEach(data => {
                questionData.push(data.question)
            });
            console.log("Fetched data:", result); // Log the response to check if data is received
            setLessons(questionData);
        }
        
        // Extract the 'quizzes' array and update state
    } catch (error) {
        showToast(t("errorfetchingdata"), "error");
        setLoading(false);
    }finally {
        setLoading(false);
    }
};

// Corrected useEffect hook to trigger fetch on component mount



   

    // Define table columns
    const columns = React.useMemo(
        () => [
            // {
            //     accessorKey: 'lesson_id', // Displaying lesson_id
            //     header: () => (
            //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            //             <i className="bi bi-book"></i>{t('Lesson ID')}
            //         </div>
            //     ),
            //     colName: 'lesson_id',
            // },
            // {
            //     accessorKey: 'question_id', // Displaying question_id
            //     header: () => (
            //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            //             <i className="bi bi-question-circle"></i>{t('Question ID')}
            //         </div>
            //     ),
            //     colName: 'question_id',
            // },
            {
                accessorKey: 'question_en', // Displaying question in English
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Question(English)')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const question = getValue(); // Get the value of the question_en column
                    return <div>{question}</div>; // Render the question
                },
                colName: t('Question(English)'),
            },
            {
                accessorKey: 'question_ar', // Displaying question in Arabic
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Question(Arabic)')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const question = getValue(); // Get the value of the question_ar column
                    return <div>{question}</div>; // Render the question
                },
                colName: t('Question(Arabic)'),
            },
            // {
            //     accessorKey: 'id',
            //     header: () => (
            //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            //             <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
            //         </div>
            //     ),
            //     cell: ({ getValue }) => {
            //         const id = getValue();
            //         return (
            //             <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            //                 <Editlessonquiz id={id} parentfetchQuestion={fetchQuestions} />
            //                 <div className="iconDel" onClick={() => { setSelectedQuestionId(id); setShowModal(true) }}>
            //                     <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
            //                 </div>
            //             </div>
            //         );
            //     },
            //     colName: 'Action',
            // },
        ],
        []
    );
    
    

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    const handleLessonRowClick = () => {
        navigate(`/LessonQuizDetail/${lesson_id}`);
    };
    
    return (
        <div className="p-1">
            <div className="primaryBlueBox pad mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('SearchLessonQuiz')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('SearchLessonQuiz')}
                                aria-label="Search by name"
                                value={searchVal}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        {/* <AddLessonQuiz id={id} parentfetchQuestion={fetchQuestions} /> */}
                        <NavLink className="btnWhiteGradient mt-3" to={`/Add-Lesson-Quiz?id=${id}`}>
                                                      <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AttachLessonQuiz')}
                                            </NavLink>
                    </div>
                </div>
            </div>

            {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}

            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}></div>
                </div> 
            ) : (
                <div className="bg-light px-3 py-3 userTableWrapper resTable">
                    {window.innerWidth < 768 ? (
                        // Render stacked view for small screens
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row">
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        {cell.column.columnDef.colName !== "ID" ? (
                                            <strong>{cell.column.columnDef.colName}: </strong>
                                        ) : ('')}
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                           <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                                            {window.innerWidth < 768 ? (
                                                table.getRowModel().rows.map(row => (
                                                    <div key={row.id} className="stacked-row"
                                                        onClick={row.id.includes('id') === false ? () => handleLessonRowClick() : null}>
                                                        {row.getVisibleCells().map(cell => (
                                                            <div key={cell.id} className="stacked-cell">
                                                                <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))
                                            ) : (
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        {table.getHeaderGroups().map(headerGroup => (
                                                            <tr key={headerGroup.id}>
                                                                {headerGroup.headers.map(header => (
                                                                    <th key={header.id}>
                                                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </thead>
                                                    <tbody>
                                                        {table.getRowModel().rows.map(row => (
                                                            <tr key={row.id}>
                                                                {row.getVisibleCells().map(cell => (
                                                                    <td key={cell.id}
                                                                        className='rowAnchor'
                                                                        onClick={cell.id.includes('id') === false ? () => handleLessonRowClick(row.original.id) : null}>
                                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                    )}
                </div>
            )}

            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (
                        <li key={index}>
                            <button
                                onClick={() => fetchQuestions(link.url)}
                                className={`btn ${link.active ? 'active' : ''}`}
                            >
                                {link.label}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Modal for Deleting Question */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Are you sure you want to delete this question?')}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        {t('Close')}
                    </Button>
                    <Button variant="danger" onClick={() => handleDelete(selectedQuestionId)}>
                        {t('Delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default LessonQuiz;
