import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Button ,Spinner } from 'react-bootstrap';
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from "react-bootstrap";
import './AddLDAPServer.css';
import { showToast } from '../ShowToast/ToastNotification';

const AddLDAPServer = ({ parentfetchLDAPServer }) => {
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const { t } = useTranslation();
    const [Security_types, setSecurityTypes] = useState([]);
    const navigate = useNavigate();

    const fetchSecurityTypes = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/ldapsecuritytypes', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
            console.log(result); // Verify the API response
            setSecurityTypes(result.data || result); // Adjust based on API structure
        } catch (error) {
            console.error('Error fetching data:', error);
            showToast(t("errorFetchingData"), "error");
        }
    };
    

    useEffect(() => {
        fetchSecurityTypes();
    }, []);
    const [Server_types1, setSecurityTypes1] = useState([]);

    const fetchSecurityTypes1 = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/ldapservertypes', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result); // Verify the API response
            setSecurityTypes1(result.data || result); // Adjust based on API structure
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchSecurityTypes1();
    }, []);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        title: "",
        host: "",
        port: "",
        server_type: "",
        security_type: "",
        bind_dn: "",
        password: "",
        base: "",
        filter: "",
        map_user_name: "",
        map_first_name: "",
        map_last_name: "",
        map_email: "",
        map_department: "",
        map_phone_number: "",
        map_group: "",
        enable_group_sync: false,
        map_group_filter: "",

    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,

        });
    };

    const handleAddLDAPServer = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start
    
        console.log(formData);
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/ldap_servers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow",
            });
    
            if (response.ok) {
                showToast(t("ldapServerAddedSuccessfully"), "success");
                handleClose();
                setFormData({
                    title: "",
                    host: "",
                    port: "",
                    server_type: "",
                    security_type: "",
                    bind_dn: "",
                    password: "",
                    base: "",
                    filter: "",
                    map_user_name: "",
                    map_first_name: "",
                    map_last_name: "",
                    map_email: "",
                    map_department: "",
                    map_phone_number: "",
                    map_group: "",
                    enable_group_sync: "",
                    map_group_filter: "",
                });
                navigate(-1);
            } else {
                showToast(t("failedToAddLDAPServer"), "error");
                handleClose();
            }
        } catch (error) {
            showToast(t("failedToAddLDAPServer"), "error");
            handleClose();
        } finally {
            setIsLoading(false); // Loader stop
        }
    };
    


    return (

        <div className="addEmailContainer" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '26px 26px', margin: '20px', marginTop: '20px' }}>
            <div className="d-flex justify-content-between mb-3">
                <div className="pageTitle">{t('AddLDAPServer')}</div>
                <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
            </div>

            <Tabs defaultActiveKey="addEmail" className="mb-3">
                <Tab eventKey="addEmail" title={t("AddLDAPServer")}>
                    <form onSubmit={handleAddLDAPServer} className="addEmailForm">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('Title')}</label>
                                    <input type="text" name="title" className="form-control" onChange={handleChange} value={formData.title} required />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('Host')}</label>
                                    <input type="text" name="host" className="form-control" onChange={handleChange} value={formData.host} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('Port')}</label>
                                    <input type="number" name="port" className="form-control" onChange={handleChange} value={formData.port} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('ServerType')}</label>
                                    <select name="server_type" className="form-select" onChange={handleChange} value={formData.server_type} required>
                                        <option></option>
                                        {Server_types1 && Server_types1.map((Server, index) => (
                                            <option value={Server}>{Server}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('SecurityType')}</label>
                                    <select name="security_type" className="form-select" onChange={handleChange} required value={formData.security_type}>
                                        <option></option>
                                        {Security_types && Security_types.map((Security, index) => (
                                            <option value={Security}>{Security}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('BindDn')}</label>
                                    <input type="text" name="bind_dn" className="form-control" onChange={handleChange} value={formData.bind_dn} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('Password')}</label>
                                    <input type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('Base')}</label>
                                    <input type="text" name="base" className="form-control" onChange={handleChange} value={formData.base} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('Filter')}</label>
                                    <input type="text" name="filter" className="form-control" onChange={handleChange} value={formData.filter} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('MapUserName')}</label>
                                    <input type="text" name="map_user_name" className="form-control" onChange={handleChange} value={formData.map_user_name} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('MapFirstName')}</label>
                                    <input type="text" name="map_first_name" className="form-control" onChange={handleChange} value={formData.map_first_name} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('MapLastName')}</label>
                                    <input type="text" name="map_last_name" className="form-control" onChange={handleChange} value={formData.map_last_name} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('MapEmail')}</label>
                                    <input type="email" name="map_email" className="form-control" onChange={handleChange} value={formData.map_email} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('MapDepartment')}</label>
                                    <input type="text" name="map_department" className="form-control" onChange={handleChange} value={formData.map_department} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('MapPhoneNumber')}</label>
                                    <input type="tel" name="map_phone_number" className="form-control" onChange={handleChange} value={formData.map_phone_number} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('MapGroup')}</label>
                                    <input type="text" name="map_group" className="form-control" onChange={handleChange} value={formData.map_group} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">{t('MapGroupFilter')}</label>
                                    <input type="text" name="map_group_filter" className="form-control" onChange={handleChange} value={formData.map_group_filter} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3 d-flex align-items-center" style={{  padding: '0 5px', marginTop: '20px', fontSize: 'large', gap: '10px' }}>
                                    <input
                                        type="checkbox"
                                        name="enable_group_sync"
                                        className="form-check-input custom-checkbox"
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                enable_group_sync: e.target.checked ? true : false,
                                            })
                                        }
                                        checked={formData.enable_group_sync === true}
                                    />
                                    <label className="form-label ms-2 mt-2 custom-label">{t('EnableGroupSync')}</label>
                                </div>
                            </div>

                        </div>
                        <div className="text-end">
                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                    {isLoading ? (
                                        <>
                                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                                            {t('Adding...')}
                                        </>
                                    ) : (
                                        t('AddLDAPServer')
                                    )}
                                </button>
                        </div>
                    </form>
                </Tab>
            </Tabs>
        </div>
    );
};

export default AddLDAPServer;
