import { Button ,Spinner} from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { useNavigate } from 'react-router-dom';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EditCertificateManager.css";

const EditCertificateManager = ({ id }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false); // Loader state
    const [backgroundImage, setBackgroundImage] = useState("");  

    const [formData, setFormData] = useState({
        title: "",
        html_content: "",
        language: "en",
    });

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            [{ font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["blockquote", "code-block"],
            ["link", "image", "video"],
            ["clean"],
        ],
    };

    useEffect(() => {
        fetchCertificateManager();
    }, [id]);

    const fetchCertificateManager = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/certificate-templates/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const result = await response.json();
                
                const parser = new DOMParser();
                const doc = parser.parseFromString(result.data.html_content, "text/html");
                const div = doc.querySelector("div");
                const bgImage = div?.style?.backgroundImage || "";
                
                if (bgImage.includes("url")) {
                    const imageUrl = bgImage.match(/url\(["']?(.*?)["']?\)/)?.[1] || "";
                    console.log("Extracted Background Image:", imageUrl);
                    setBackgroundImage(imageUrl);
                }
                
                setFormData({
                    title: result.data.title,
                    html_content: result.data.html_content,
                    language: result.data.language || "en",
                });
            } else {
                showToast(t("failedToFetchCertificateData"), "error");
            }
        } catch (error) {
            console.error("Error fetching certificate data:", error);
            showToast(t("errorFetchingCertificateData"), "error");
        }
    };

    useEffect(() => {
        if (backgroundImage) {
            const editor = document.querySelector("#editCertificate .ql-editor");
            if (editor) {
                editor.style.backgroundImage = `url(${backgroundImage})`;
            }
        }
    }, [backgroundImage]);
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleEditorChange = (content) => {
        setFormData(prev => ({ ...prev, html_content: content }));
    };

    const handleEditCertificateManager = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        if (!formData.html_content?.trim()) {
            showToast("HTML content is required!", "error");
            return;
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/certificate-templates/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                showToast(t("certificateUpdatedSuccessfully"), "success");
                navigate(-1);
              } else {
                showToast(t("failedToUpdateCertificate"), "error");
              }
              } catch (error) {
                showToast(t("somethingWentWrongRefresh"), "error");
              } finally {
                setIsLoading(false); // Loader stop
              }
              
    };

    return (
        <div className="addCertificateContainer" style={{ marginTop: "20px" }}>
            <form onSubmit={handleEditCertificateManager}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("Title")}</label>
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.title}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t("Language")}</label>
                            <select
                                name="language"
                                className="form-select"
                                onChange={handleChange}
                                value={formData.language}
                                required
                            >
                                <option value="en">{t("English")}</option>
                                <option value="ar">{t("Arabic")}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="mb-3 position-relative">
                        <label className="form-label">{t("HTMLContent")}</label>
                        <ReactQuill
                            value={formData.html_content}
                            onChange={handleEditorChange}
                            modules={modules}
                            className="ReactQuill"
                            id="editCertificate"
                        />
                        <div className="mt-2">
                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                    {isLoading ? (
                                        <>
                                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                                            {t('Update...')}
                                        </>
                                    ) : (
                                        t('UpdateCertificateManager')
                                    )}
                                </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditCertificateManager;
