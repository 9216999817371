import React, { useEffect, useState } from 'react';
import { api_url } from '../../constant/constant';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

const PlayVideo = ({ id, row }) => {
    const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const lesson_id = searchParams.get("lesson_id");
    const video_id = searchParams.get("video_id");

    useEffect(() => {
        const fetchVideos = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('userToken');
                const response = await fetch(`${api_url}/lessons/${lesson_id}/videos/${video_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Fetched Videos:", result.data);
                
                if (result.data) {
                    setSelectedVideoUrl(result.data.url); 
                } else {
                    showToast(t("novideosfound"), "error");
                }
                
                } catch (error) {
                    console.error('Error fetching data:', error);
                    showToast(t("errorfetchingdata"), "error");
                }finally {
                setLoading(false);
            }
        };

        fetchVideos();
    }, [lesson_id]);

    return (
        <>
        {loading && <p>{t('Loading video...')}</p>}
    
        {selectedVideoUrl && (
            <video 
                controls 
                width="100%" 
                style={{ border: "1px solid #eaeaea", marginTop:'20px', padding:'15px' }} 
            >
                <source src={selectedVideoUrl} type="video/mp4" />
                {t('Your browser does not support the video tag.')}
            </video>
        )}
    </>
    
    );
};

export default PlayVideo;
