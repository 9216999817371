import React,{ useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { api_url } from "../../constant/constant";

const EmailLogs = ({ LearningManager_id }) => {
  const [emailLogs, setEmailLogs] = useState([]);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  // Fetch email logs
  const fetchEmaillog = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(
        `${api_url}/learning-campaigns-emails/${LearningManager_id}/email-logs`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      // Assuming the API response structure is correct
      setEmailLogs(result.data.data || []); // set email logs data here
    } catch (error) {
      console.error("Error fetching email logs:", error);
      setError(t("errorFetchingEmailLogs")); 
  }  
  };

  // Fetch the email logs when component mounts
  useEffect(() => {
    fetchEmaillog();
  }, [LearningManager_id]);

  return (
    <div className="container mt-4">
      <h2>{t("EmailLogs")}</h2>
      {error && <p className="text-danger">{error}</p>}

      <table className="table table-striped">
        <thead>
          <tr>
            <th>{t("Username")}</th>
            <th>{t("Email")}</th>
            <th>{t("Status")}</th>
            {/* <th>{t("SentAt")}</th> */}
          </tr>
        </thead>
        <tbody>
          {emailLogs.length > 0 ? (
            emailLogs.map((log, index) => (
              <tr key={log.id}>
                <td>{log.username}</td>
                <td>{log.email}</td>
                <td>{log.status}</td>
                {/* <td>{log.sent_at}</td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                {t('NoEmailLogsFound')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EmailLogs;
