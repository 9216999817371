import React, { useState, useEffect } from 'react';
import { api_url } from '../../constant/constant';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { Spinner, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const MyCertificate = () => {
  const [certificates, setCertificates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  

  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${api_url}/user/certificates`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();

        if (result.status === "success") {
          setCertificates(result.certificates || []);
          setMessage(result.message || "");
        } else {
          setMessage(t("failedtofetchcertificates"));
      } 
      } catch (err) {
          console.error('Fetch error:', err);
          showToast(t("somethingwentwrong"), "error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCertificates();
  }, []);

  return (
    <div className="my-certificate-container p-4">
      <h3 className="text-bluelightsenselarge">{t('MyCertificate')}</h3>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('Loading')}...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {certificates.length > 0 ? (
            <div className="certificates-list">
              {certificates.map((certificate, index) => (
                <Card key={index} className="mb-3 shadow-sm">
                  <Card.Body>
                    <Card.Title>{certificate.name}</Card.Title>
                    <Card.Text>
                      <strong>{t('Course')}:</strong> {certificate.course}
                      <br />
                      <strong>{t('Date')}:</strong> {certificate.date}
                      <br />
                      <strong>{t('Status')}:</strong> {certificate.status}
                    </Card.Text>
                    <Button variant="primary" onClick={() => console.log("View Certificate", certificate)}>
                     {t('ViewCertificate')}
                    </Button>
                  </Card.Body>
                </Card>
              ))}
            </div>
          ) : (
            <p className='m-3'>
              {message}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default MyCertificate;