import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Tab, Tabs, Spinner } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { showToast } from "../ShowToast/ToastNotification";
import ReactQuill from "react-quill";
import "./AddPolice.css";

const AddPolice = ({ }) => {
  const { lesson_id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const [body, setBody] = useState("");
  const [bodyAr, setBodyAr] = useState("");
  const [police_types, setPoliceTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [showHtml, setShowHtml] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["blockquote", "code-block"],
      ["link", "image", "video"],
      ["clean"],
    ],
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };

  const [formData, setFormData] = useState({
    name_en: "",
    name_ar: "",
    content_en: "",
    content_ar: "",
    policy_type: "",
    version: "",
    pdf_file: "",
  });

  // Fetch policy types from API
  useEffect(() => {
    const fetchPoliceTypes = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/policy-types`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setPoliceTypes(result.data || result);
      } catch (error) {
        showToast(t("errorFetchingData"), "error");
      }
    };

    fetchPoliceTypes();
  }, []);

  // Sync body and bodyAr to formData
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      content_en: body,
      content_ar: bodyAr,
    }));
  }, [body, bodyAr]);

  // Handle Input Change
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  // Handle Policy Type Change
  const handlePolicyTypeChange = (e) => {
    const value = e.target.value;
    setSelectedType(value);
    setFormData((prevData) => ({
      ...prevData,
      policy_type: value,
    }));
  };


  // Handle Add Policy
  const handleAddPolice = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Loader start

    try {
      const token = localStorage.getItem("userToken");
      const formDataToSend = new FormData();

      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const response = await fetch(`${api_url}/policies`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSend,
      });

      if (response.ok) {
        showToast(t("policyAddedSuccessfully"), "success");
        setFormData({
          nameEn: "",
          nameAr: "",
          contentEn: "",
          contentAr: "",
          policyType: "",
          version: "",
          pdfFile: "",
        });
        navigate(-1);
      } else {
        showToast(t("failedToAddPolicy"), "error");
      }
      
      } catch (error) {
        showToast(t("somethingWentWrong"), "error");
      } finally {
        setIsLoading(false); // Loader stop
      }
      
  };

  return (
    <div
      className="addEmailContainer"
      style={{
        border: "1px solid #EAEAEA",
        borderRadius: "10px",
        padding: "26px 26px",
        margin: "20px",
      }}
    >
      <div className="d-flex justify-content-between mb-3">
        <div className="pageTitle">{t('AddPolicy')}</div>
        <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
      </div>


      <Tabs defaultActiveKey="Add Policy" className="mb-3">
        <Tab eventKey="Add Policy" title={t("AddPolicy")}>
          <form onSubmit={handleAddPolice} className="AddPolicy">
            <div className="mb-3">
              <label className="form-label">{t("Name(English)")}</label>
              <input
                type="text"
                name="name_en"
                className="form-control"
                onChange={handleChange}
                value={formData.name_en}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">{t("Name(Arabic)")}</label>
              <input
                type="text"
                name="name_ar"
                className="form-control"
                onChange={handleChange}
                value={formData.name_ar}
                required
              />
            </div>

            {/* Policy Type Dropdown */}
            <div className="mb-3">
              <label className="form-label">{t("Policytype")}</label>
              <select
                name="policy_type"
                className="form-select"
                onChange={handlePolicyTypeChange}
                required
              >
                <option value="">{t("SelectPolicyType")}</option>
                <option value="PDF">PDF</option>
                <option value="HTML">HTML</option>
              </select>

            </div>

            {/* Show Only if "pdf" is Selected */}
            {selectedType === "PDF" && (
              <div className="mb-3">
                <label className="form-label">{t("PDFfile")}</label>
                <input
                  type="file"
                  name="pdf_file"
                  className="form-control"
                  accept=".PDF"
                  onChange={handleChange}
                  required
                />
              </div>
            )}

            {/* Show Only if "html" is Selected */}
            {selectedType === "HTML" && (
              <>
                <div className="d-flex justify-content-between">
                  <label>{t("Content(English)")}</label>
                  <Button
                    type="button"
                    onClick={() => setShowHtml(!showHtml)}
                    className="btn btn-secondary mb-2 "
                  >
                    {showHtml ? t("SwitchtoEditor") : t("ViewHTMLSource")}
                  </Button>
                </div>
                <div className="my-3 mb-5" style={{ position: "relative" }}>
                  {showHtml ? (
                    <textarea
                      value={formData.content_en}
                      onChange={(e) =>
                        setFormData({ ...formData, content_en: e.target.value })
                      }
                      style={{
                        width: "100%",
                        height: "300px",
                        fontFamily: "monospace",
                      }}
                    />
                  ) : (
                    <ReactQuill
                      value={body}
                      onChange={setBody}
                      modules={modules}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "color",
                        "background",
                        "link",
                        "image",
                        "video",
                        "script",
                        "list",
                        "bullet",
                        "indent",
                        "align",
                        "blockquote",
                        "code-block",
                      ]}
                      style={{ height: "300px", width: "100%" }}
                    />
                  )}
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className="d-flex justify-content-between">
                  <label>{t("Content(Arabic)")}</label>
                  <Button
                    type="button"
                    onClick={() => setShowHtml(!showHtml)}
                    className="btn btn-secondary mb-2 "
                  >
                    {showHtml ? t("SwitchtoEditor") : t("ViewHTMLSource")}
                  </Button>
                </div>
                <div className="my-3 mb-5" style={{ position: "relative" }}>
                  {showHtml ? (
                    <textarea
                      value={formData.content_ar}
                      onChange={(e) =>
                        setFormData({ ...formData, content_ar: e.target.value })
                      }
                      style={{
                        width: "100%",
                        height: "300px",
                        fontFamily: "monospace",
                      }}
                    />
                  ) : (
                    <ReactQuill
                      value={bodyAr}
                      onChange={setBodyAr}
                      modules={modules}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "color",
                        "background",
                        "link",
                        "image",
                        "video",
                        "script",
                        "list",
                        "bullet",
                        "indent",
                        "align",
                        "blockquote",
                        "code-block",
                      ]}
                      style={{ height: "300px", width: "100%" }}
                    />
                  )}
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </>
            )}

            <div className="mb-3">
              <label className="form-label">{t("Version")}</label>
              <input
                type="number"
                name="version"
                className="form-control"
                onChange={handleChange}
                value={formData.version}
                required
              />
            </div>

            <div className="text-end">
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" role="status" className="me-2" />
                    {t('Adding...')}
                  </>
                ) : (
                  t('AddPolicy')
                )}
              </button>
            </div>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};


export default AddPolice;
