import './DashboardNormal.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api_url } from '../../constant/constant';
import { Button } from 'react-bootstrap';
import { showToast } from '../../Components/ShowToast/ToastNotification';

const DashboardNormal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const [CampaignTitle, setCampaignTitle] = useState("");
  const [CampaignId, setCampaignId] = useState("");
  const [PolicyId, setPolicyId] = useState("");
  const [Videoseek, setVideoseek] = useState(null);
  const [CampaignLesson, setCampaignlesson] = useState([]);
  const [PolicyData, setPolicyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [progress, setProgress] = useState(0);
  const [canStartExam, setCanStartExam] = useState(false);
  const [PolicyTitle, setPolicyTitle] = useState("");
  const [Policytype, setPolicytype] = useState("");
  const [Policycontent, setPolicycontent] = useState("");
  const [Policyversion, setPolicyversion] = useState("");
  const [showPolicy, setShowPolicy] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${api_url}/user/dashboard`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        console.log("API Response:", result);

        if (result.status === "success") {
          if (Array.isArray(result.userPolicies) && result.userPolicies.length > 0) {
            const policy = result.userPolicies[0];
            setPolicyData(policy);
            setPolicyTitle(policy.name || "");
            setPolicyId(policy.policy_id || "");
            setPolicytype(policy.policy_type || "");
            setPolicycontent(policy.content || "");
            setShowPolicy(true);
          } else {
            setShowPolicy(false);
          }

          if (Array.isArray(result.campaigns) && result.campaigns.length > 0) {
            const campaign = result.campaigns[0];
            setCampaignTitle(campaign.campaign_name || "");
            setCampaignId(campaign.campaign_id || "");
            setCampaignlesson(campaign.videos_with_quizzes || []);
            setVideoseek(campaign.video_seek || null);
            setCanStartExam(campaign.can_start_exam !== null && campaign.can_start_exam !== undefined ? campaign.can_start_exam : false);
            setProgress(campaign.progress || 0);  
          }
        }
      } catch (err) {
        console.error('Fetch error:', err);
        showToast(t("somethingwentwrongpleaserefresh"), "error");
    } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleLessonClick = (lesson) => {
    navigate('/LessonVideos', { state: { lesson: lesson, Videoseek: Videoseek } });
  };
  const handlepolicyClick = () => {
    navigate('/UserPolicies', { state: { PolicyData: PolicyData} });
  };

  const gotoExam = () => {
    navigate('/CampaignExam', { state: { campaign_id: CampaignId } });
  };

  return (
    <div className="dashboard-container p-4">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">{t('Loading')}...</span>
          </div>
        </div>
      ) : (
        <>
          <h1 className="textHeadDashboard mb-4">{CampaignTitle}</h1>
          
          {/* Policy Section */}
          <div>
          {showPolicy && (
              <div>
                 <h3 className="text-bluelightsenselarge">{t('AssignedPolicy')}</h3>
                 <p>{t('acceptpolicytext')}</p>
                  <div className="col-md-7 lesson-card" onClick={() => handlepolicyClick()}>
                    <h5 style={{padding:5}}>{PolicyTitle}</h5>
                    <i className="bi bi-box-arrow-right me-2 text-bluelightsense"></i>
                  </div>
              </div>
            )}
            {/* Lessons Section */}
            <div className="row mt-4">
              <div className="col-md-7">
                <h3 className="text-bluelightsenselarge">{t('LearningCampaigntxt')}</h3>
                <p>{t('Lessonrequiredinthiscampaign')}</p>
                <div className="lessons-container">
                  {Array.isArray(CampaignLesson) && CampaignLesson.length > 0 ? (
                    CampaignLesson.map((lesson, index) => (
                      <div key={index} className="lesson-card" onClick={() => handleLessonClick(lesson)}>
                        <div>
                          <i className="bi bi-play-btn me-2 text-bluelightsense"></i>
                          <h4>{lesson.lesson_title}</h4>
                        </div>
                        {lesson.watched ? <p>{t('watched')}</p> : <p>{t('Unwatched')}</p>}
                      </div>
                    ))
                  ) : (
                    <p>{t('Nolessonsavailable')}</p>
                  )}
                </div>
              </div>

              {/* Exam Section */}
              {canStartExam && (
                <div className="col-md-5 d-flex justify-content-center align-items-center">
                  <div className="exam-card p-3 border rounded shadow-sm h-100" style={{width:"100%"}}>
                    <h5 className="mb-0"><i className="bi bi-clipboard-check fs-4 me-2"></i>{t('Exam')}</h5>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                      <Button style={{width:200,marginTop:50}} onClick={() => gotoExam()} className="btn btn-outline-primary btn-sm">{t('Start')}</Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardNormal;
