import React, { useState } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { showToast } from "../ShowToast/ToastNotification";
import "./Userupdatepassword.css"

function UpdatePassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [toastData, setToastData] = useState(null);

    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Check if passwords match
        if (password !== confirmPassword) {
            showToast(t("passwordsdonotmatch"), "error");
            return;
        }        

        setLoading(true);

        // Get user data from localStorage
        const userData = JSON.parse(localStorage.getItem("userData"));
        const token = localStorage.getItem("userToken");

        if (!userData) {
            showToast(t("usernotauthenticated"), "error");
            setLoading(false);
            return;
        }        

        const userId = userData.id; // Fetch user_id from userData

        try {
            const response = await fetch(`${api_url}/change-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    user_id: userId,
                    password: password,
                    password_confirmation: confirmPassword,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                showToast(errorData.message || t("failedupdatepassword"), "error");
                throw new Error(errorData.message);
            }
            
            const data = await response.json();
            
            if (data.status === "success") {
                showToast(data.message || t("passwordupdatedsuccess"), "success");
            
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/login";
                }, 2000);
            } else {
                showToast(t("failedupdatepasswordretry"), "error");
            }
            } catch (err) {
                console.error(err.message);
                showToast(t("somethingwentwrong"), "error");
            } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container" style={{  margin: "0 auto", marginTop: "5%" }}>

            <form onSubmit={handleSubmit}>
                <div className="mb-3" style={{width:"30%"}}>
                    <label htmlFor="password" className="form-label pass">
                        {t("NewPassword")}
                    </label>
                    <input
                        type="password"
                        className="form-control pass"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3" style={{width:"30%"}}>
                    <label htmlFor="confirmPassword" className="form-label pass">
                        {t("ConfirmPassword")}
                    </label>
                    <input
                        type="password"
                        className="form-control pass"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <Button variant="primary" type="submit">
                 {t('UpdatePassword')}
            </Button>
            </form>
        </div>
    );
}

export default UpdatePassword;
