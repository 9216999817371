import { Button ,Spinner} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { showToast } from "../../Components/ShowToast/ToastNotification";
import { useParams} from "react-router-dom";


const EditLessonQuiz = () => {
    const { t } = useTranslation();
    const { id: lesson_id } = useParams();
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const [availableQuestions, setAvailableQuestions] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [formData, setFormData] = useState({ lessonid: [] });
    const [loading, setLoading] = useState(false);

    // Fetch available questions and selected questions
    const fetchLessonQuizModal = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/quiz/available-questions`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const result = await response.json();
            if (response.ok) {
                setAvailableQuestions(result.availableQuestions || []);
                fetchQuestions();
            }
        } catch (error) {
            showToast(t("errorfetchingavailablequestions", { message: error.message }), "error");
        } finally {
            setLoading(false);
        }
    };

    // Fetch the selected quiz questions for the lesson
    const fetchQuestions = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/quiz`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const result = await response.json();
            const questionData = result?.quizzes?.map((quiz) => quiz.question) || [];
            setSelectedQuestions(questionData);

            // Update formData with selected question IDs
            const selectedQuestionIds = questionData.map(q => q.id);
            setFormData({ lessonid: selectedQuestionIds });
        } catch (error) {
            showToast(t("errorfetchingselectedquestions", { message: error.message }), "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (lesson_id) {
            fetchLessonQuizModal();
        }
    }, [lesson_id]);

    // Handle change of selected options in the dropdown
    const handleChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions).map(option => parseInt(option.value));
        setFormData({ question_ids: selectedOptions });
    };

    // Update lesson quiz
    const handleUpdateLessonquiz = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/quiz`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                showToast(t("lessonupdatedsuccessfully"), "success");
                fetchQuestions();
            } else {
                showToast(t("failedtoupdatelesson"), "error");
            }
            } catch (error) {
                showToast(t("somethingwentwrong"), "error");
            } finally {
            setIsLoading(false); // Loader stop
        }
    };

    // Filter out selected questions from available ones to display the remaining available questions
    const remainingAvailableQuestions = availableQuestions.filter(
        (question) => !selectedQuestions.some((selected) => selected.id === question.id)
    );

    // Combine selected questions (to show first) and remaining available questions
    const allQuestions = [...selectedQuestions, ...remainingAvailableQuestions];

    return (
        <div>       
        <div className="py-4" style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "0px 15px" }}>
            <div className="mb-3">
                <label className="form-label">{t("Available&SelectedQuestions")}</label>
                <select
                    className="form-control"
                    multiple
                    style={{ width: "100%", height: "250px" }}
                    onChange={handleChange}
                    value={formData.lessonid} // Bind selected options to formData.lessonid
                >
                    {allQuestions.length > 0 ? (
                        allQuestions.map((question) => (
                            <option key={question.id} value={question.id}>
                                {question.question_ar} - {question.question_en}
                            </option>
                        ))
                    ) : (
                        <option value="" disabled>{t("NoQuizzesAvailable")}</option>
                    )}
                </select>
            </div>
    
           <button type="submit" className="btn btn-primary" disabled={isLoading} onClick={handleUpdateLessonquiz}>
                                              {isLoading ? (
                                                  <>
                                                      <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                      {t('Update...')}
                                                  </>
                                              ) : (
                                                  t('UpdateLessonQuiz')
                                              )}
                                          </button>
        </div>
    </div>
    
    );
};

export default EditLessonQuiz;
