import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { api_url } from "../../constant/constant";
import { useNavigate } from 'react-router-dom';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import AddPhishingCompaign from "../AddPhishingCompaignUserModal/AddPhishingCompaignUser";
import { showToast } from "../ShowToast/ToastNotification";
import { NavLink } from 'react-router-dom';

const PhishingCompaignuser = ({ id }) => {
    console.log("id", id);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const LM_Id = id;
    // console.log("LearningManager_id:",LM_Id);
    // State variables
    const [data, setLcUser] = useState([]);
    const [links, setLinks] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);


    const handleCheckboxChange = (e, id) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            // Add the id to selectedIds if it's checked
            setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
        } else {
            // Remove the id from selectedIds if it's unchecked
            setSelectedIds((prevSelectedIds) => prevSelectedIds.filter((selectedId) => selectedId !== id));
        }
    };
    // Handle navigation to Question page with lesson id
    const handleAnswersClick = (id) => {
        navigate(`/question/${id}`); // Pass lesson id as a route parameter
    };


    const handleRemove = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");

            const response = await fetch(`${api_url}/phishing-campaigns/${LM_Id}/detach-users`, {
                method: 'POST', // Use DELETE method
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    user_ids: selectedIds, // Pass the selected lesson ids in the request body
                }),
            });

            if (response.ok) { 
                showToast(t("userdetachedsuccessfully"), "success");
                fetchLcUserModal('');
            } else {
                showToast(t("failedtodetachuser"), "error");
            }
            } catch (error) {
                showToast(t("somethingwentwrong"), "error");
                setLoading(false);
            }finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (searchValue !== '') {
            const searchText = searchValue?.toLowerCase() || "";
            const filteredData = data?.filter((item) =>
                item?.email?.toLowerCase().includes(searchText) ||
                item?.username?.toLowerCase().includes(searchText) ||
                item?.firstname?.toLowerCase().includes(searchText) ||
                item?.lastname?.toLowerCase().includes(searchText) ||
                item?.phone_number?.toLowerCase().includes(searchText)
            );
            setLcUser(filteredData);
        } else {
            fetchLcUserModal('');
        }
    }, [searchValue]);


    // Fetch questions data with pagination
    const fetchLcUserModal = async (campaignId, url) => {
        setLoading(true); // Start loading
        let page = 1;

        // Parse page number from the provided URL, defaulting to 1
        if (url) {
            const temp = url.split("?page=");
            page = temp[1] || 1; // Extract page number, or default to 1
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/phishing-campaigns/${id}/users`, { // Use dynamic campaignId
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log("API Response:", result);

            // Assuming response structure matches the provided sample
            setLcUser(result.assigned_users || []);  // 'data.data' contains the user list
            // setLinks(result.data.links || []); // 'data.links' contains pagination links
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); // Stop loading in all cases
        }
    };


    // Define table columns
    const columns = React.useMemo(
        () => [
            // {
            //     accessorKey: 'title_id',
            //     header: () => (
            //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            //             <i className="bi bi-clipboard-data"></i> lesson_id (id)
            //         </div>
            //     ),
            // },
            {
                accessorKey: 'email',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Email')}
                    </div>
                ),
                colName: 'email',
            },
            {
                accessorKey: 'username',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('UserName')}
                    </div>
                ),
                colName: 'username',
            },
            {
                accessorKey: 'firstname',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('FirstName')}
                    </div>
                ),
                colName: 'firstname',
            },
            {
                accessorKey: 'lastname',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('LastName')}
                    </div>
                ),
                colName: 'lastname',
            },
            {
                accessorKey: 'phone_number',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('PhoneNumber')}
                    </div>
                ),
                colName: 'phone_number',
            },
            {
                accessorKey: 'id', // Correct accessor key
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue(); // Extract the value of the 'id' column
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>

                            {/* Checkbox component */}
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e, id)}
                            // You can add a checked prop here if needed, e.g., checked={isChecked}
                            />
                        </div>
                    );
                },
                colName: 'Action', // Correct property spelling
            }

        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">
            {/* <div className="pageTitle mb-4">All Answers</div> */}

            <div className="primaryBlueBox pad mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('SearchPhishingUsers')}</label>
                        <div className="input-group">
                            <input type="text"
                                className="form-control"
                                placeholder={t("Searchbyname")}
                                aria-label="Search by name"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)} />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        {/* <AddPhishingCompaign LcUserModal_id={LM_Id} parentfetchLcUserModal={fetchLcUserModal} /> */}
                        <NavLink className="btnWhiteGradient mt-3" to={`/AddPhishing-Compaign?LcUserModal_id=${LM_Id}`}>
                            <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddPhishingCampaignUser')}
                        </NavLink>

                    </div>
                </div>
            </div>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center  mt-5">
                    <div className="spinner-border loader-api" role="status" style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>
                    </div>
                </div>
            ) : (

                <div className="bg-light px-3 py-3 userTableWrapper resTable">
                    {window.innerWidth < 768 ? (
                        <>
                            {table.getRowModel().rows.map(row => (
                                <div key={row.id} className="stacked-row">
                                    {row.getVisibleCells().map(cell => (
                                        <div key={cell.id} className="stacked-cell">
                                            {cell.column.columnDef.colName !== "ID" ? (
                                                <strong>{cell.column.columnDef.colName}: </strong>
                                            ) : null}
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </div>
                                    ))}
                                </div>
                            ))}

                            <div className="d-flex justify-content-end mt-3">
                                <button onClick={handleRemove} className="btn btn-danger" disabled={selectedIds.length === 0}>
                                    {t('RemoveSelected')}
                                </button>
                            </div>
                        </>
                    ) : (
                        <div>
                            <table className="table table-bordered table-hover">
                                <thead className="thead-dark">
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <th key={header.id}>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="button-container text-center mt-3">
                                <button onClick={handleRemove} className="btn btn-danger" disabled={selectedIds.length === 0}>
                                    {t('RemoveSelected')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>

            )}



            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchLcUserModal(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>


        </div>
    );
};

export default PhishingCompaignuser;
