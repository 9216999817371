import { Button ,Spinner} from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";

const EditEmail = ({ id }) => {
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const { t } = useTranslation();
    const [userId] = useState(id);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };


    const [formData, setFormData] = useState({
        name: "",
        username: "",
        password: "",
        port: "",
        encryption: "",
        host: "",

    });

    const fetchEmailData = async () => {

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/email-servers/" + userId, {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                showToast(t("emailUpdateSuccess"), "success");
                return;
              }
              
              const data = await response.json();
              
              setFormData({
                ...formData,
                ["name"]: data.name,
                ["username"]: data.username,
                ["password"]: data.password,
                ["port"]: data.port,
                ["host"]: data.host,
                ["encryption"]: data.encryption,
              });
              
              } catch (err) {
                showToast(t("errorFetchingEmail"), "error");
              }
              
    }

    useEffect(() => {
        fetchEmailData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEditEmail = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/email-servers/" + userId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) {
                showToast(t("emailServerUpdatedSuccess"), "success");
                handleClose();
              } else {
                showToast(t("failedToUpdateEmailServer"), "error");
                handleClose();
              }
              } catch (error) {
                showToast(t("somethingWentWrongRefresh"), "error");
                handleClose();
              }finally {
            setIsLoading(false); // Loader stop
        }
    }

    return (
        <div className="py-4"  style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'0px 15px'}}>


            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Name')}</label>
                        <input type="text" name="name" className="form-control" onChange={handleChange} value={formData.name} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Username')}</label>
                        <input type="email" name="username" className="form-control" onChange={handleChange} value={formData.username} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Password')}</label>
                        <input type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Host')}</label>
                        <input type="host" name="host" className="form-control" onChange={handleChange} value={formData.host} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Port')}</label>
                        <input type="text" name="port" className="form-control" onChange={handleChange} value={formData.port} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Encryption')}</label>
                        <input type="encryption" name="encryption" className="form-control" onChange={handleChange} value={formData.encryption} required />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="mb-3">
                    </div>
                </div>
            </div>
            <button 
                type="submit" 
                className="btn btn-primary" 
                disabled={isLoading} 
                onClick={handleEditEmail}  // <-- Add this line
            >
                {isLoading ? (
                    <>
                        <Spinner animation="border" size="sm" role="status" className="me-2" />
                        {t('Update...')}
                    </>
                ) : (
                    t('UpdateEmailServer')
                )}
            </button>

        </div>
    );

}

export default EditEmail;