import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { api_url } from "../../../constant/constant";
import { showToast } from "../../../Components/ShowToast/ToastNotification";
import { useTranslation } from "react-i18next";

const CampaignExam = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const compaign_id = location.state?.campaign_id;
    // console.log("location.state:",location.state.campaign_id);
    const { t } = useTranslation();

    const [quizData, setQuizData] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [examAnswers, setExamAnswers] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [submitted, setSubmitted] = useState(false); // New state for submission

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('userToken');
                const response = await fetch(`${api_url}/user/get-exam-details/${compaign_id}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const result = await response.json();
                if (result.status === "success") {
                    setQuizData(result.exam?.questions);
                }
            } catch (error) {
                setError(t("failedtoloadcampaigndata"));
                showToast(t("failedtoloadcampaigndata"), "error");
            }            
        };

        fetchData();
    }, [compaign_id]);

    const handleAnswerSelect = (index) => {
        const newAnswers = [...answers];
        newAnswers[currentQuestion] = index;
        setAnswers(newAnswers);
    };

    const handleNext = async () => {
        if (answers[currentQuestion] === undefined) {
            setError(t("pleaseselectanoptionbeforeproceeding"));
            showToast(t("pleaseselectanoptionbeforeproceeding"), "error");
            return;
        }        

        const selectedOption = quizData[currentQuestion]?.options[answers[currentQuestion]];
        const questionId = quizData[currentQuestion].question_id;
        const answerId = selectedOption.option_id;

        setExamAnswers(prev => [...prev, { question_id: questionId, answer_id: answerId }]);

        if (currentQuestion < quizData.length - 1) {
            setCurrentQuestion(prev => prev + 1);
        } else {
            await handleSubmit(); // Submit answers
            setQuizCompleted(true);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError("");

        try {
            const response = await fetch(`${api_url}/user/submit-exam-answers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
                },
                body: JSON.stringify({ answers: examAnswers }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                showToast(t("failedtosubmitanswers") || errorData.message, "error");
                return;
            }
            
            const data = await response.json();
            if (data.status === "success") {
                showToast(t("quizsubmittedsuccessfully"), "success");
                setSubmitted(true); // Set submitted state to true
            }
            } catch (err) {
                setError(t("anerroroccurredwhilesubmittingyouranswers"));
                showToast(t("anerroroccurredwhilesubmittingyouranswers"), "error");
            } finally {
            setLoading(false);
        }
    };

    if (!quizData) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">{t('Loading')}...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="quiz-container">
            {!quizCompleted ? (
                <>
                    <h2>{quizData[currentQuestion]?.question}</h2>
                    <div className="options">
                        {quizData[currentQuestion]?.options.map((option, index) => (
                            <label key={option.option_id} className="option">
                                <input
                                    type="radio"
                                    name={`question-${currentQuestion}`}
                                    checked={answers[currentQuestion] === index}
                                    onChange={() => handleAnswerSelect(index)}
                                    disabled={loading}
                                />
                                {option.answer}
                            </label>
                        ))}
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    {loading && <div className="loading-text">{t('Submittinganswer')}...</div>}
                    <div className="quiz-buttons">
                        <button onClick={handleNext} disabled={loading}>
                            {currentQuestion === quizData.length - 1 ? t("Finish") : t("Next")}
                        </button>
                    </div>
                </>
            ) : (
                <div className="quiz-container">
                    <h4>{t('Thanksforsubmittinganswers')}!</h4>
                    <button onClick={() => navigate("/DashboardNormal")} disabled={loading}>
                    {loading ? t("Loading...") : t("Next")}
                    </button>
                </div>
            )}
        </div>
    );
};

export default CampaignExam;
