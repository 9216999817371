import { Button,Spinner } from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditLesson.css';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";

const EditLesson = ({ id, parentfetchLessons }) => {
    const { t } = useTranslation();
    const [lessonId] = useState(id);
    const [lesson_types, setLessonTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Loader state

    

    const fetchLessonTypes = async () => {

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/lesson-types', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setLessonTypes(result);

        }catch (error) {
            showToast(t("errorFetchingData"), "error");
          }          
    };

    const fetchLesson = async () => {

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/lessons/" + lessonId, {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                showToast(t("errorFetchingLessonDetails"), "error");
            }
            
            const data = await response.json();
            
            setFormData({
                ...formData,
                ["title_ar"]: data.title_ar,
                ["title_en"]: data.title_en,
                ["description_ar"]: data.description_ar,
                ["description_en"]: data.description_en,
                ["code"]: data.code,
                ["lesson_type_id"]: data.lesson_type_id
            });
            
            } catch (err) {
                showToast(t("somethingWentWrong"), "error");
            }            
    }

    useEffect(() => {
        fetchLessonTypes();
        fetchLesson();
    }, []);

    const [formData, setFormData] = useState({
        title_ar: "",
        title_en: "",
        description_ar: "",
        description_en: "",
        code: "",
        lesson_type_id: ""
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleUpdateLesson = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/lessons/" + lessonId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) {
                showToast(t("lessonUpdatedSuccessfully"), "success");
            } else {
                showToast(t("failedToUpdateLesson"), "error");
            }
            } catch (error) {
                showToast(t("somethingWentWrong"), "error");
            } finally {
                setIsLoading(false); // Loader stop
            }            
    }

    return (
        <div className="py-4"  style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'0px 15px'}}>

            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Title(English)')}</label>
                        <input type="text" name="title_en" className="form-control" onChange={handleChange} value={formData.title_en} required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Title(Arabic)')}</label>
                        <input type="text" name="title_ar" className="form-control" onChange={handleChange} value={formData.title_ar} required/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Description(English)')}</label>
                        <textarea 
                            name="description_en" 
                            className="form-control" 
                            onChange={handleChange} 
                            value={formData.description_en} 
                            required
                        />

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Description(Arabic)')}</label>
                        <textarea 
                            name="description_ar" 
                            className="form-control" 
                            onChange={handleChange} 
                            value={formData.description_ar} 
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Code')}</label>
                        <input type="text" name="code" className="form-control" onChange={handleChange} value={formData.code} required/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('LessonType')}</label>
                        <select name="lesson_type_id" className="form-select" onChange={handleChange} disabled value={formData.lesson_type_id}>
                            <option></option>
                            {lesson_types && lesson_types.map((lesson, index) => (
                                <option value={lesson.id}>{lesson.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

              <button type="submit" className="btn btn-primary" disabled={isLoading}  onClick={handleUpdateLesson} >
                                                {isLoading ? (
                                                    <>
                                                        <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                        {t('Update...')}
                                                    </>
                                                ) : (
                                                    t('UpdateLesson')
                                                )}
                                            </button>
        </div>
    );

}

export default EditLesson;