import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { Tabs, Tab } from "react-bootstrap";
import { api_url } from '../../constant/constant';
import { useParams, useNavigate } from 'react-router-dom';  // useNavigate ko import kiya hai
import EditVideo from '../../Components/EditVideoModal/EditVideo';
import Videos from '../Videos/Videos';
import EditLesson from '../../Components/EditLesson/EditLesson';
import Questions from '../Questions/Questions';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import LessonQuiz from'../../Components/LessonQuizModal/LessonQuiz';
import { Button } from "react-bootstrap";

import './LessonDetail.css';

const LessonDetail = () => {

    const { t } = useTranslation();
    const { lesson_id } = useParams();
    const [key, setKey] = useState("details");
    const navigate = useNavigate();  

    // State variables
    const [data, setVideos] = useState([]);
    const [links, setLinks] = useState([]);

    // Handle delete user action
    // const handleDelete = async (id) => {
    //     try {
    //         const token = localStorage.getItem("userToken");
    //         const response = await fetch(`${api_url}/lessons/${lesson_id}/questions/${id}`, {
    //             method: "DELETE",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${token}`,
    //             },
    //         });

    //         if (response.ok) {
    //             showToast("Video deleted successfully!" , "success");
    //         } else {
    //             showToast("Failed to delete Video. Please try again." , "error");
    //         }
    //     } catch (error) {
    //         showToast("Something went wrong. Please refresh." , "error");

    //     }
    // };

    // Fetch videos data with pagination
    // const fetchVideos = async (url) => {
    //     let page = 1;
    //     if (url) {
    //         const temp = url.split('?page=');
    //         page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
    //     }

    //     try {
    //         const token = localStorage.getItem("userToken");
    //         const response = await fetch(`${api_url}/lessons/${lesson_id}/videos?page=${page}`, {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${token}`,
    //             },
    //         });


    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }

    //         const result = await response.json();
    //         setVideos(result);
    //         // setLinks(result.links);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         showToast("Error fetching data:" , "error");
    //     }
    // };


    // useEffect(() => {
    //     fetchVideos('');
    // }, []);

    // const getLanguageName = (code) => {
    //     switch (code) {
    //         case 'en':
    //             return 'English';
    //         case 'ar':
    //             return 'Arabic';
    //         default:
    //             return 'Unknown';
    //     }
    // }

    // Define table columns
    // const columns = React.useMemo(
    //     () => [
    //         {
    //             accessorKey: 'url',
    //             header: () => (
    //                 <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //                     <i className="bi bi-clipboard-data"></i>{t('URL')}
    //                 </div>
    //             ),
    //             colName: 'Url',
    //         },

    //         {
    //             accessorKey: 'size',
    //             header: () => (
    //                 <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //                     <i className="bi bi-clipboard-data"></i>{t('Size')}
    //                 </div>
    //             ),
    //             colName: 'Size',
    //         },

    //         {
    //             accessorKey: 'length',
    //             header: () => (
    //                 <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //                     <i className="bi bi-clipboard-data"></i>{t('Length')}
    //                 </div>
    //             ),
    //             colName: 'Length',
    //         },

    //         {
    //             accessorKey: 'language',
    //             header: () => (
    //                 <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //                     <i className="bi bi-clipboard-data"></i>{t('Language')}
    //                 </div>
    //             ),
    //             cell: ({ getValue }) => {
    //                 return (
    //                     getLanguageName(getValue())
    //                 );
    //             },
    //             colName: 'Language',
    //         },

    //         {
    //             accessorKey: 'id', // Correct accessor key
    //             header: () => (
    //                 <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //                     <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
    //                 </div>
    //             ),
    //             cell: ({ getValue }) => {
    //                 const id = getValue(); // Extract the value of the 'id' column
    //                 return (
    //                     <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>

    //                         <div className="iconPlay">
    //                             <i class="bi bi-play-btn"></i> {t('Play')}
    //                         </div>

    //                         <EditVideo lesson_id={lesson_id} id={id} parentfetchVideo={fetchVideos} />

    //                         <div className="iconDel" onClick={() => handleDelete(id)}>
    //                             <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
    //                         </div>
    //                     </div>
    //                 );
    //             },
    //             colName: 'ID', // Correct property spelling
    //         },

    //     ],
    //     []
    // );

    // // Create a table instance
    // const table = useReactTable({
    //     data,
    //     columns,
    //     getCoreRowModel: getCoreRowModel(),
    // });

    return (
        <div className="p-4">

            {/* Back Button & Lesson Details Title */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('LessonDetails')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="details" title={t('LessonDetails')}>
                    <div>
                        <EditLesson id={lesson_id} />
                    </div>
                </Tab>

                <Tab eventKey="videos" title={t('Videos')}>
                    <div>
                        <Videos id={lesson_id}/>
                    </div>
                </Tab>

                <Tab eventKey="questions" title={t('Questions')}>
                    <div>
                        <Questions id={lesson_id}/>
                    </div>
                </Tab>
                <Tab eventKey="LessonQuiz" title={t('LessonQuiz')} >
                    <div>
                        <LessonQuiz id={lesson_id}/>
                    </div>
                </Tab>
            </Tabs>

        </div>
    );
};

export default LessonDetail;
