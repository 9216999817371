import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { api_url } from "../../constant/constant";

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();

  const getInitialLanguage = () => {
    const storedLang = localStorage.getItem("lng") || "en"; // Default 'en'
    return {
      lang: storedLang,
      flag: storedLang === "ar" ? "/images/icons8-saudi-arabia.svg" : "/images/icons8-us-flag.svg",
    };
  };

  const [selectedLanguage, setSelectedLanguage] = useState(getInitialLanguage());

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.lang);
    document.documentElement.dir = selectedLanguage.lang === "ar" ? "rtl" : "ltr";
    document.getElementsByTagName("body")[0].setAttribute("dir", selectedLanguage.lang === "ar" ? "rtl" : "ltr");
  }, [selectedLanguage.lang, i18n]);

  const handleLanguageChange = (lang, flag) => {
    changeUserLanguage(lang);
    setSelectedLanguage({ lang, flag });
    localStorage.setItem("lng", lang);
  };

  const changeUserLanguage = async (lng) => {
    const language_code = lng === "en" ? 1 : 2;
    try {
      const token = localStorage.getItem("userToken");
      await fetch(api_url + "/admin/main/users/language", {
        mode: "cors",
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ language_id: parseInt(language_code) }),
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="dropdown nav-item me-2">
      <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <img src={selectedLanguage.flag} width="24" alt="Language" className="me-2" />
      </a>
      <ul className="dropdown-menu" aria-labelledby="submenu">
        <li>
          <a href="#" className="dropdown-item" onClick={() => handleLanguageChange("en", "/images/icons8-us-flag.svg")}>
            <img src="/images/icons8-us-flag.svg" width="24" alt="English" className="me-2" />
            {t("English")}
          </a>
        </li>
        <li>
          <a href="#" className="dropdown-item" onClick={() => handleLanguageChange("ar", "/images/icons8-saudi-arabia.svg")}>
            <img src="/images/icons8-saudi-arabia.svg" width="24" alt="العربية" className="me-2" />
            العربية
          </a>
        </li>
      </ul>
    </div>
  );
};

export default LanguageSwitcher;
