export const api_url = "https://api.ethaba.com/api";
const URLPATh = window.location.origin; // Get current origin (localhost, dev, etc.)
let LOGO_SRC = "/images/logo.png"; // Default logo
console.log("URL path:",URLPATh);
let isSTC = false; 
if (URLPATh.includes("ui-dev.ethaba.com") || URLPATh.includes("localhost")) {
    LOGO_SRC = "/images/logo.png";
} else if (URLPATh.includes("demo.cybersense.online") || URLPATh.includes("localhost")) {
    document.documentElement.classList.add("alternate");
    LOGO_SRC = "/images/stc.png";
}

export { LOGO_SRC };