import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { api_url } from '../../constant/constant';
import "./PreviewEmailTemplate.css";
import {  Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const PreviewEmailTemplate = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [formData, setFormData] = useState({ name: "", body: "" });
    const navigate = useNavigate();

    const fetchEmailTemplate = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-templates/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            });
            if (response.ok) {
                const { data } = await response.json();
                setFormData({ title: data.title, body: data.body });
            } else {
                throw new Error("Failed to fetch data");
            }
        }
        catch (error) {
            alert(`Error: ${error.message}`);
        }
    };

    useEffect(() => {
        if (id) fetchEmailTemplate();
    }, [id]);

    return (
        <div className="container my-3">
             <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                            <div className="pageTitle">{t('PhishingCampaignDetail')}</div>
                            <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
                        </div>
            <h2 className="pageTitle">{t("PreviewEmailTemplate")}</h2>

            <div className="mt-4">
                <strong>{t('Title')}:</strong>
                <div className="border p-2 rounded bg-light">{formData.title}</div>
            </div>

            <div className="mt-4">
                <strong>{t('EmailBody')}:</strong>
                <div className="emailBody border p-3 rounded bg-white"
                    dangerouslySetInnerHTML={{ __html: formData.body }}>
                </div>
            </div>
        </div>
    );
}

export default PreviewEmailTemplate;
