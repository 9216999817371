import { Modal, Button ,Spinner} from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditQuestion.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { useLocation } from "react-router-dom";

const EditQuestion = ({id, parentfetchQuestion}) => {
    const [isLoading, setIsLoading] = useState(false); // Loader state

    // console.log('lesson id is ' + id);
     const location = useLocation();
        const searchParams = new URLSearchParams(location.search);
        const lesson_id = searchParams.get("lesson_id");
    // const { lesson_id } = useParams(); 
    const question_id = searchParams.get("question");

    // const [questions_id] = useState(id);
    const [show, setShow] = useState(false); 
    const { t } = useTranslation();
    useEffect(()=>{
        fetchQuestion();

    }, [
        lesson_id
    ])

    const fetchQuestion= async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
			const response = await fetch(`${api_url}/lessons/${lesson_id}/questions/${question_id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
	  
              if (!response.ok) {
                showToast(t("errorFetchingQuestionDetails"), "error");
                handleClose();
            }            
	  
			const data = await response.json();
			
            setFormData({
                ...formData,
                ["question_en"]: data.question.question_en,
                ["question_ar"]: data.question.question_ar,
                ["lesson_id"]: data.question.lesson_id
            });

		} 
		catch (err) {
            showToast(t("somethingWentWrong"), "error");
            handleClose();
        }        
    }

    
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () =>{
        setShow(true);
    } 

    const [formData, setFormData] = useState({
        question_ar: "",
        question_en: "",
        lesson_id: ""
      });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleUpdateQuestion = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/questions/${question_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
            

            if (response.ok) {
                showToast(t("questionUpdatedSuccess"), "success");
                handleClose();
                parentfetchQuestion("");
            } else {
                showToast(t("questionUpdateFailed"), "error");
                handleClose();
            }
        } catch (error) {
            showToast(t("somethingWentWrong"), "error");
            handleClose();
        }finally {
            setIsLoading(false); // Loader stop
        }
    }

    return (
        <div className="py-4"  style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'0px 15px'}}>

<div className="row">
            <div className="col-md-6">
                <div className="mb-3">
                    <label className="form-label">{t('Question(English)')}</label>
                    <input type="text" name="question_en" className="form-control" onChange={handleChange} value={formData.question_en} />
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-3">
                    <label className="form-label">{t('Question(Arabic)')}</label>
                    <input type="text" name="question_ar" className="form-control" onChange={handleChange} value={formData.question_ar} />
                </div>
            </div>
        </div>

        <button type="submit" className="btn btn-primary" disabled={isLoading} onClick={handleUpdateQuestion}>
                                    {isLoading ? (
                                        <>
                                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                                            {t('Update...')}
                                        </>
                                    ) : (
                                        t('UpdateQuestion')
                                    )}
                                </button>
        </div>
      );

}

export default EditQuestion;