import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { api_url } from '../../constant/constant';
import { useNavigate } from 'react-router-dom';
const Callback = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        localStorage.setItem("userToken", token);
        handleSubmit(token);
        console.log("Extracted Token:", token);
    }, [location]);
    const handleSubmit = async (token) => {
        try {
          const response = await fetch(api_url + "/user/microsoft/check-cases", {
            mode: "cors",
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            console.log("errorData",errorData);
            if (errorData?.terms) {
              localStorage.setItem("userToken",errorData?.token);
              navigate('/Terms', { state: { terms: errorData.terms } }); 
              return;
            }
            if (errorData.requires_gender_selection) {
              // Redirect to gender selection page or prompt
              localStorage.user_id = errorData?.user.user_id;
              window.location = "/Updategender";
              return;
            }
          }
    
          const data = await response.json();
          console.log("data response:",data.user);
          if (data?.status == "success") {
            localStorage.setItem("userData", JSON.stringify({role_id:5})); 
            window.location = "/DashboardNormal";
          }

        } catch (err) {
          console.error(err.message);
        }
      };
    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
    );
};

export default Callback;
