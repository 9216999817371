import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Button } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditCertificateManager from '../../Components/EditCertificateManager/EditCertificateManager';
const CertificateManagerDetails = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [key, setKey] = useState("details");
    const navigate = useNavigate();

    return (
        <div className="p-4">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '16px' }}>
                <div className="pageTitle">{t('CertificateManagementDetail')}</div>
                <Button variant="primary" onClick={() => navigate(-1)}>{t('Back')}</Button>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="details" title={t('EditCertificate')}>
                    <div><EditCertificateManager id={id} /></div>
                </Tab>


            </Tabs>

        </div>
    );
};

export default CertificateManagerDetails;