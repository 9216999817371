import { Modal, Button,Spinner } from "react-bootstrap";
import React,{ useState } from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './Addemail.css';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";

import { Tab, Tabs } from "react-bootstrap";

const AddEmail = ({ onfetchEmail }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [encryptionError, setEncryptionError] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Loader state


    const [formData, setFormData] = useState({
        name: "",
        username: "",
        password: "",
        host: "",
        port: "",
        encryption: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        
        // Encryption validation: Only allow specific values (e.g., 'SSL', 'TLS', 'None')
        const validEncryptions = ["SSL", "TLS", "None"];
        if (name === "encryption") {
            if (!validEncryptions.includes(value.trim().toUpperCase())) {
                setEncryptionError("Please enter correct encryption (SSL, TLS, or None).");
            } else {
                setEncryptionError(""); // Clear error if valid
            }
        }
    };

    const handleAddEmail = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start
    
        // Prevent submission if encryption is invalid
        if (encryptionError) {
            showToast(t("InvalidEncryptionMethod"), "error");
            return;
        }
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/email-servers", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
    
            if (response.ok) {
                setFormData({
                    name: "",
                    username: "",
                    password: "",
                    host: "",
                    port: "",
                    encryption: "",
                });
                showToast(t("EmailServerAddedSuccessfully"), "success");
                navigate(-1);
            } else {
                showToast(t("FailedToAddEmailServer"), "error");
            }
        } catch (error) {
            showToast(t("SomethingWentWrong"), "error");
        } finally {
            setIsLoading(false); // Loader stop
        }
    };

    return (
        <div className="p-4">
            <div className="addEmailContainer" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '26px 26px', marginTop: '20px' }}>
                <div className="d-flex justify-content-end mb-3">
                    <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
                </div>

                <Tabs defaultActiveKey="Add Lesson" className="mb-3">
                    <Tab eventKey="Add Lesson" title={t("AddEmailServer")}>
                        <form onSubmit={handleAddEmail}>
                            <Modal.Body className="addUserWrapper">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">{t('Name')}</label>
                                            <input type="text" name="name" className="form-control" onChange={handleChange} value={formData.name} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">{t('Username')}</label>
                                            <input type="email" name="username" className="form-control" onChange={handleChange} value={formData.username} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">{t('Password')}</label>
                                            <input type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">{t('Host')}</label>
                                            <input type="text" name="host" className="form-control" onChange={handleChange} value={formData.host} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">{t('Port')}</label>
                                            <input type="number" name="port" className="form-control" onChange={handleChange} value={formData.port} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">{t('Encryption')}</label>
                                            <input
                                                type="text"
                                                name="encryption"
                                                className={`form-control ${encryptionError ? 'is-invalid' : ''}`}
                                                onChange={handleChange}
                                                value={formData.encryption}
                                                required
                                            />
                                            {encryptionError && <div className="invalid-feedback">{encryptionError}</div>}
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                    {isLoading ? (
                                        <>
                                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                                            {t('Adding...')}
                                        </>
                                    ) : (
                                        t('AddEmailServer')
                                    )}
                                </button>
                            </Modal.Footer>
                        </form>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default AddEmail;
