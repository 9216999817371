import React,{ useState } from "react";
import { api_url } from "../../constant/constant";
import "./AddDepartment.css";
import { useTranslation } from "react-i18next";
import { showToast } from "../ShowToast/ToastNotification";
import { Tab, Tabs } from "react-bootstrap";
import {  Button ,Spinner} from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const AddDepartment = ({ onfetchDepartment }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const [formData, setFormData] = useState({
        name: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddDepartment = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/admin/main/departments", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow",
            });
    
            if (response.ok) {
                showToast(t("DepartmentAddedSuccessfully"), "success");
                setFormData({ name: "" });
                navigate(-1);
            } else {
                showToast(t("FailedToAddDepartment"), "error");
            }
        } catch (error) {
            showToast(t("SomethingWentWrong"), "error");
        } finally {
            setIsLoading(false); // Loader stop
        }
    };

    return (
        <div style={{ padding: "26px 26px", margin: '20px', marginTop: "20px" }}>
             <div className="d-flex justify-content-between mb-3">
                <div className="pageTitle">{t('AddDepartmentDetail')}</div>
                <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
            </div>

            <Tabs defaultActiveKey="addEmail" className="mb-3">
                <Tab eventKey="addEmail" title={t("AddDepartment")}>
                    <form onSubmit={handleAddDepartment} style={{border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px"}}>
                        
                        <div className="mb-3">
                            <label className="form-label" style={{ paddingLeft: "20px" }}>
                                {t("DepartmentName")}
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.name}
                                required
                            />
                        </div>

                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                    {isLoading ? (
                                        <>
                                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                                            {t('Adding...')}
                                        </>
                                    ) : (
                                        t('AddDepartment')
                                    )}
                                </button>
                    </form>
                </Tab>
            </Tabs>
        </div>

    );
};

export default AddDepartment;
