import { Modal, Button, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './AddSurvey.css';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { Tab, Tabs } from "react-bootstrap";
const AddSurvey = ({ parentfetchSurveys }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        title_en: "",
        title_ar: "",
        description_en: "",
        description_ar: "",
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddSurvey = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/surveys", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) {
                showToast(t("surveyAddedSuccessfully"), "success");
                handleClose();
                setFormData({
                  titleEn: "",
                  titleAr: "",
                  descriptionEn: "",
                  descriptionAr: "",
                });
                navigate(-1);
              } else {
                showToast(t("failedToAddSurvey"), "error");
                handleClose();
              }
              } catch (error) {
                showToast(t("somethingWentWrong"), "error");
                handleClose();
              } finally {
                setIsLoading(false); // Loader stop
              }
              
    }

    return (
        <div className="addEmailContainer" style={{ border: '1px solid #EAEAEA', borderRadius: '10px', padding: '26px 26px', margin: "20px", marginTop: '20px' }}>
            <div className="d-flex justify-content-between mb-3">
                <div className="pageTitle">{t('AddSurvey')}</div>
                <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
            </div>

            <Tabs defaultActiveKey="Add Survey" className="mb-3">
                <Tab eventKey="Add Survey" title={t("AddSurvey")}>
                    <form onSubmit={handleAddSurvey} className="Add Survey">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Title(English)')}</label>
                                    <input type="text" name="title_en" className="form-control" onChange={handleChange} value={formData.title_en} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Title(Arabic)')}</label>
                                    <input type="text" name="title_ar" className="form-control" onChange={handleChange} value={formData.title_ar} required />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Description(English)')}</label>
                                    <textarea type="text" name="description_en" className="form-control" onChange={handleChange} required>{formData.description_en}</textarea>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{t('Description(Arabic)')}</label>
                                    <textarea type="text" name="description_ar" className="form-control" onChange={handleChange} required>{formData.description_ar}</textarea>
                                </div>
                            </div>
                        </div>

                        <div className="text-end">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner animation="border" size="sm" role="status" className="me-2" />
                                        {t('Adding...')}
                                    </>
                                ) : (
                                    t('AddSurvey')
                                )}
                            </button>
                        </div>
                    </form>
                </Tab>
            </Tabs>
        </div>
    );

}

export default AddSurvey;