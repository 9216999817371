import React, { useState } from 'react';
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SystemLogsDetails from './SystemLogsDetail';

const SystemLogs = () => {
    const { id } = useParams(); // Ensure the route contains a parameter named 'id'

    const { t } = useTranslation();
    const [key, setKey] = useState("systemlogs");
    // State variables



    return (
        <div className="p-4">

            <div className="pageTitle mb-4">{t('systemlogs')}</div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >

                <Tab eventKey="systemlogs" title={t('systemlogs')}>
                    <div>
                        <SystemLogsDetails />
                    </div>
                </Tab>

            </Tabs>

        </div>
    );
};

export default SystemLogs;