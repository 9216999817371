import { Modal, Button ,Spinner} from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditUser.css';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";

const EditUser = ({ id, onfetchUsers }) => {
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const { t } = useTranslation();
    const [roles, setRoles] = useState([]); // State for roles  
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setFormData({
            ...formData,
            ["firstname"]: '',
            ["lastname"]: '',
            ["email"]: '',
            ["phone_number"]: '',
            ["username"]: '',
            ["role_id"]: '',
            // ["password"]: '',
            ["status_id"]: '',
        });
    };

    // const handleShow = () => {
    //     fetchUserData();
    //     fetchRoles();
    //     setShow(true);
    // }

    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        username: "",
        role_id: "",
        // password: "",
        status_id: "",
        source_id: "2"
    });
    const fetchUserData = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/users/" + id, {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });
    
            if (!response.ok) {
                showToast(t("errorFetchingUserDetails"), "error");
                return; // Stop execution if API fails
            }            
    
            const result = await response.json();
    
            // Ensure API response has 'data' key
            if (result.status !== "success" || !result.data) {
                console.error("Invalid API response:", result); // Log for debugging
                showToast(t("invalidApiResponse"), "error");
                return;
            }            
    
            const userData = result.data; // Extract user details from response
    
            setFormData({
                ...formData,
                firstname: userData.firstname,
                lastname: userData.lastname,
                email: userData.email,
                phone_number: userData.phone_number,
                username: userData.username,
                role_id: userData.role_id,
                // password: userData.password || "", // Avoid undefined
                status_id: userData.status_id,
            });
    
        } catch (err) {
            console.error("Fetch error:", err);
            showToast(t("somethingWentWrong"), "error");
        }
    };
    
    useEffect(() => {
        fetchRoles();
        fetchUserData();
    }, []);

    const fetchRoles = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/admin/main/user/roles', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                showToast(t("errorFetchingRoles"), "error");
                return;
            }            

            const rolesData = await response.json();
            setRoles(rolesData); // Assuming API returns an array of roles
        } catch (err) {
            console.error("Error:", err); // Log error for debugging
            showToast(t("somethingWentWrong"), "error");
        }        
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,

            [name]: value,
        });
    };

    const handleEditUser = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        const updateFormData = { ...formData };
        if (!formData.password || formData.password.trim() === "") {
            delete updateFormData.password;
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/users/" + id, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(updateFormData),
                redirect: "follow"
            });

            if (response.ok) {
                showToast(t("userUpdatedSuccess"), "success");
                fetchUserData(); 
            } else {
                showToast(t("userUpdateFailed"), "error");
            }
        } catch (error) {
            console.error("Error updating user:", error);
            showToast(t("somethingWentWrong"), "error");
        }finally {
            setIsLoading(false); // Loader stop
        }
    }

    return (
        <div className="py-4" style={{border:'1px solid #EAEAEA',borderRadius:'10px',padding:'0px 15px'}}>

            <form onSubmit={handleEditUser}>

                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('FirstName')}</label>
                            <input type="text" name="firstname" className="form-control" onChange={handleChange} value={formData.firstname} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('LastName')}</label>
                            <input type="text" name="lastname" className="form-control" onChange={handleChange} value={formData.lastname} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Email')}</label>
                            <input type="email" name="email" className="form-control" onChange={handleChange} value={formData.email} />
                        </div>
                    </div>
                    <div className="col-md-6">
                    <div className="mb-3">
                    <label className="form-label">{t('PhoneNumber')}</label>
                    <input 
                        type="text" 
                        name="phone_number" 
                        className="form-control" 
                        maxLength={15} 
                        onChange={handleChange} 
                        value={formData.phone_number} 
                    />
                </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Username')}</label>
                            <input type="text" name="username" className="form-control" onChange={handleChange} value={formData.username} />
                        </div>
                    </div>
                    {/* <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Password')}</label>
                            <input type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} required />
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Role')}</label>
                            <select value={formData.role_id} className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                <option value="">{t('SelectRole')}</option>
                                {roles.map(role => (
                                    <option key={role.id} value={role.id}>{role.title}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Status')}</label>
                            <select value={formData.status_id} className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                <option value=""></option>
                                <option value="1">{t('Active')}</option>
                                <option value="2">{t('Inactive')}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                   {isLoading ? (
                                                       <>
                                                           <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                           {t('Update...')}
                                                       </>
                                                   ) : (
                                                       t('UpdateUser')
                                                   )}
                                               </button>
            </form>
        </div>
    );

}

export default EditUser;