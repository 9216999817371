import './lessonVideo.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import { useTranslation } from 'react-i18next';
import React, { useState, useRef , useEffect} from 'react';
import { useLocation , useNavigate} from 'react-router-dom';
import { api_url } from '../../constant/constant';
const LessonVideos = () => {
    const location = useLocation();
    const videoRef = useRef(null);
    const [lastTime, setLastTime] = useState(0);
    const [videoEnded, setVideoEnded] = useState(false);

    const navigate = useNavigate();
    const lesson = location.state?.lesson;
    const video_seek = location.state?.Videoseek;
    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;
    
        // Prevent seeking backward
        const handleTimeUpdate = () => {
            if (video_seek === 'forward' && video.currentTime < lastTime) {
                video.currentTime = lastTime; // Prevent backward seeking
            } 
            else if (video_seek === 'backward' && video.currentTime > lastTime + 1) {
                video.currentTime = lastTime; // Prevent forward seeking
            } 
            setLastTime(video.currentTime);
        };
        video.addEventListener('timeupdate', handleTimeUpdate);
        return () => video.removeEventListener('timeupdate', handleTimeUpdate);
      }, [lastTime]);
    if (!lesson) {
        return <p>No lesson data available</p>;
    }
    const handleVideoEnd = (videoId) => {
        setVideoEnded(true);
        handleSubmit(videoId)
    };
    const handleSubmit = async (videoId) => {
        try {
          const response = await fetch(api_url + `/user/mark-video-as-watched/${videoId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('userToken')}`, // Assuming the token is stored in localStorage
            },
          });
          
          if (!response.ok) {
            const errorData = await response.json();
            console.log("errorData:",errorData);
          }

          const data = await response.json();
          if(data.status=="success"){
            console.log('video marked as watch');
          }
        } catch (err) {
         console.log("err is:",err);
        } 
      };
  return (
    <div className="lesson-video-container p-4">
    {!videoEnded ? (
    <div className='col-sm-12 col-lg-8'>
        <video
            controls={true} // Disable default controls
            autoPlay={false}
            width="100%"
            onEnded={() => handleVideoEnd(lesson?.video_id)} 
            ref={videoRef}
        >
        <source src={lesson.url} type="video/mp4" />
        our browser does not support the video tag.
        </video>
    </div>
    ):(
        <div className="completion-screen col-sm-12 col-lg-8">
          <i className="bi bi-check-circle" style={{ fontSize: '50px', color: '#007bff' }}></i>
          <h3>You have successfully watched this video</h3>
          <h4>{lesson.lesson_title}</h4>
          <div className="buttons">
            <button onClick={() => navigate('/DashboardNormal')}>Home</button>
            <button onClick={() => navigate('/QuizNormal',{ state: { lesson:lesson} })}>Start Quiz</button>
            <button onClick={() => setVideoEnded(false)}>Watch Again</button>
          </div>
        </div>
    )}
    <div className='lessonTitle col-sm-12 col-lg-8'>
        <h2>{lesson.lesson_title}</h2>
    </div>
    </div>
  )
}
export default LessonVideos;