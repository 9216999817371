import React, { useEffect, useState, useRef } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
// import AddVideo from '../../Components/AddVideoModal/AddVideo';
// import EditVideo from '../../Components/EditVideoModal/EditVideo';
import { useTranslation } from 'react-i18next';
import './Videos.css';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';


const
    Videos = ({ id }) => {
        const lesson_id = id;
        const { t } = useTranslation();
        const navigate = useNavigate();

        // State variables
        const [data, setVideos] = useState([]);
        const [links, setLinks] = useState([]);
        const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);
        const [loading, setLoading] = useState(false);
        const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
        const [searchVal, setSearchVal] = useState('');
        const [showModal, setShowModal] = useState(false);
        const [selectedVideoId, setSelectedVideoId] = useState(null);

        // State to track whether the video is playing
        const [isVideoPlaying, setIsVideoPlaying] = useState(false);
        const videoRef = useRef(null);

        const togglePlayPause = () => {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsVideoPlaying(true);
            } else {
                videoRef.current.pause();
                setIsVideoPlaying(false);
            }
        };

        // Handle delete user action
        const handleDelete = async (id) => {
            setLoading(true);
            try {
                const token = localStorage.getItem('userToken');
                const response = await fetch(`${api_url}/lessons/${lesson_id}/videos/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    showToast(t("videodeleted"), "success");
                    fetchVideos('');
                    setShowModal(false);
                } else {
                    showToast(t("failedtodeletevideo"), "error");
                    setShowModal(false);
                }
                } catch (error) {
                    setShowModal(false);
                    showToast(t("somethingwentwrong"), "error");
                    setLoading(false);
                } finally {
                setLoading(false);
            }
        };
        useEffect(() => {
            if (searchVal !== '') {
                const filteredData = data.filter((item) =>
                    item.url.toLowerCase().includes(searchVal.toLowerCase()) ||
                    item.size.toLowerCase().includes(searchVal.toLowerCase()) ||
                    item.length.toLowerCase().includes(searchVal.toLowerCase()) ||
                    item.language.toLowerCase().includes(searchVal.toLowerCase())
                );
                setVideos(filteredData);
            } else {
                fetchVideos('');
            }
        }, [searchVal]);
        // Fetch videos data with pagination
        const fetchVideos = async (url) => {
            setLoading(true);
            let page = 1;
            if (url) {
                const temp = url.split('?page=');
                page = temp[1] || 1;
            }

            try {
                const token = localStorage.getItem('userToken');
                const response = await fetch(`${api_url}/lessons/${lesson_id}/videos?page=${page}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
        
                const result = await response.json();
        
                if (response.status === 404) {
                    // showToast(result.message || "Resource not found", "error");
                    setVideos([]); 
                    return;
                }
        
                if (!response.ok) {
                    throw new Error(result.message || `HTTP error! status: ${response.status}`);
                }
        
                setVideos(Array.isArray(result.data) ? result.data : []);
        
            } catch (error) {
                console.error('Error fetching data:', error);
                showToast(t("errorfetchingdata"), "error");
                setVideos([]);
            } finally {
                setLoading(false);
            }
        };


        useEffect(() => {
            fetchVideos('');
        }, []);

        const getLanguageName = (code) => {
            switch (code) {
                case 'en':
                    return 'English';
                case 'ar':
                    return 'Arabic';
                default:
                    return 'Unknown';
            }
        };

        const handlePlayVideo = (url) => {
            setSelectedVideoUrl(url);
            setVideoPopupOpen(true);
        };
        const closeVideoPopup = () => {
            setVideoPopupOpen(false);
            setSelectedVideoUrl('');
        };

        // Define table columns
        const columns = React.useMemo(
            () => [
                {
                    accessorKey: 'url',
                    header: () => (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100px' }}>
                            <i className="bi bi-clipboard-data"></i>
                            {t('URL')}
                        </div>
                    ),
                    colName: t('URL'),
                },
                {
                    accessorKey: 'size',
                    header: () => (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <i className="bi bi-clipboard-data"></i>
                            {t('Size')}
                        </div>
                    ),
                    colName: t('Size'),
                },
                {
                    accessorKey: 'length',
                    header: () => (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <i className="bi bi-clipboard-data"></i>
                            {t('Length')}
                        </div>
                    ),
                    colName: t('Length'),
                },
                {
                    accessorKey: 'language',
                    header: () => (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <i className="bi bi-clipboard-data"></i>{t('Language')}
                        </div>
                    ),
                    cell: ({ getValue }) => {
                        return (
                            getLanguageName(getValue())
                        );
                    },
                    colName: t('Language'),
                },

                {
                    accessorKey: 'id',
                    header: () => (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <img src="/images/fi_alert-octagon.png" alt="Source" />
                            {t('Action')}
                        </div>
                    ),
                    cell: ({ getValue, row }) => {
                        const id = getValue();
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                {/* <div className="iconPlay" onClick={(e) => { e.stopPropagation(); handlePlayVideo(row.original.url) }}>
                                <i className="bi bi-play-btn me-1"></i>{t('Play')}
                            </div> */}
                                {/* <EditVideo lesson_id={lesson_id} id={id} parentfetchVideo={fetchVideos} /> */}

                                {/* Delete button */}
                                <div className="iconDel" onClick={(e) => { e.stopPropagation(); setSelectedVideoId(id); setShowModal(true); }}>
                                    <img src="/images/fi_trash-2.png" alt="Delete" />
                                    {t('Delete')}
                                </div>
                            </div>
                        );
                    },
                    colName: t('Action'),
                },

            ],
            []
        );

        const table = useReactTable({
            data,
            columns,
            getCoreRowModel: getCoreRowModel(),
        });
        const handleLessonRowClick = (id) => {
            navigate(`/LessonVideo/LessonVideo?video_id=${id}&lesson_id=${lesson_id}`);
        };
        return (
            <div className="p-1">
                <div className="primaryBlueBox pad mb-4">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="form-label">{t('SearchVideo')}</label>
                            <div className="input-group">
                                <input type="text"
                                    className="form-control"
                                    placeholder={t("Searchvideos")}
                                    aria-label="Search videos"
                                    value={searchVal}
                                    onChange={(e) => setSearchVal(e.target.value)} />
                                <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                    <i className="bi bi-search"></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-1">
                            <div className="vrLine"></div>
                        </div>
                        <div className="col-md-7">
                            {/* <AddVideo lesson_id={lesson_id} parentfetchVideo={fetchVideos} /> */}
                            <NavLink className="btnWhiteGradient mt-3" to={`/Add-Video?lesson_id=${lesson_id}`}>
                                <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddVideo')}
                            </NavLink>
                        </div>
                    </div>
                </div>

                {loading ? (<div className="d-flex justify-content-center align-items-center  mt-5">
                    <div className="spinner-border loader-api" role="status" style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>
                    </div>
                </div>) : (

                    <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                        {window.innerWidth < 768 ? (
                            table.getRowModel().rows.map(row => (
                                <div key={row.id} className="stacked-row"
                                    onClick={row.id.includes('id') === false ? () => handleLessonRowClick(row.original.id) : null}>
                                    {row.getVisibleCells().map(cell => (
                                        <div key={cell.id} className="stacked-cell">
                                            <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                            <div className='urlw'>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                        ) : (
                            <table className="table table-bordered table-hover">
                                <thead>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <th key={header.id}>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id}
                                                    className='rowAnchor'
                                                    onClick={cell.id.includes('id') === false ? () => handleLessonRowClick(row.original.id) : null}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                )}



                <div className="navigationWrapper">
                    <ul>
                        {links.map((link, index) => (

                            <li key={index}
                                className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                                onClick={link.url !== null ? () => fetchVideos(link.url) : undefined}
                            >
                                <div dangerouslySetInnerHTML={{ __html: link.label }} />
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Video Modal */}
                {isVideoPopupOpen && (
                    <div className="video-popup" onClick={closeVideoPopup}>
                        <div className="video-container" onClick={(e) => e.stopPropagation()}>
                            {/* Close Button */}
                            <button className="close-btn" onClick={closeVideoPopup}>
                                &times;
                            </button>
                            <video
                                controls={false} // Disable default controls
                                autoPlay
                                ref={(video) => {
                                    if (video) {
                                        video.addEventListener('seeked', (e) => e.preventDefault()); // Prevent seeking
                                        video.addEventListener('seeking', (e) => e.preventDefault()); // Prevent seeking
                                    }
                                }}
                            >
                                <source src={selectedVideoUrl} type="video/mp4" />
                                {t('Your browser does not support the video tag.')}
                            </video>
                            <div className="video-custom-controls">
                                {/* Custom Play/Pause Button */}
                                {/* <button
                    onClick={(e) => {
                        const video = e.target.closest('video');
                        if (video.paused) {
                            video.play();
                        } else {
                            video.pause();
                        }
                    }}
                >
                    {isVideoPlaying ? 'Pause' : 'Play'}
                </button> */}
                            </div>
                        </div>
                    </div>
                )}



                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("ConfirmDelete")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {t("Areyousureyouwanttodeletethisvideo?")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            {t("Cancel")}
                        </Button>
                        <Button variant="danger" onClick={() => handleDelete(selectedVideoId)} disabled={loading}>
                            {loading ? t("Deleting...") : t("Delete")}
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div >

        );
    };

export default Videos;
