import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button,Spinner } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { Tab, Tabs } from "react-bootstrap";

import { useLocation } from "react-router-dom";
const AddPoliceUser = ({ }) => {
  // const {question_id} = useParams(); // Get questions_id from the URL
  const { t } = useTranslation();
      const navigate = useNavigate();  
      const [isLoading, setIsLoading] = useState(false); // Loader state
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id"); 
  const [data, setUsers] = useState([]);
  const [links, setLinks] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
  });


  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    const { options } = event.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    setFormData({
      ...formData,
      users: selectedValues, // Update with selected values
    });
  };
  const fetchLcUserModal = async (url) => {
    console.log(url);
    let page = 1;
    if (url) {
      const temp = url.split('?page=');
      page = temp[1] || 1; // Fallback to 1 if page isn't found
    }

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/learning-campaigns-users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setUsers(result.data.data);// Links for pagination
    } catch (error) {
      showToast(t("errorFetchingData"), "error");
    }
  };

  useEffect(() => {
    fetchLcUserModal('');
  }, []);

  const handleAddLcUserModal = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Loader start


    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/policies/${id}/attach-users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        showToast(t("selectedUsersAddedSuccessfully"), "success");
        handleClose();
        setFormData({
          users: "",
        });
        navigate(-1);
      } else {
        const result = await response.json();
        console.log("response", result.message);
        showToast(t(result.message) || result.message, "error"); // Handle dynamic API errors
        handleClose();
      }
      } catch (error) {
        showToast(t("somethingWentWrong"), "error");
        handleClose();
      }
      finally {
      setIsLoading(false); // Loader stop
  }
  };

  return (
    <div style={{ border: "1px solid #EAEAEA", borderRadius: "10px", padding: "26px 26px", margin: "20px", marginTop: "20px" }}>
        <div className="d-flex justify-content-end mb-3">
                       <Button variant="primary" onClick={() => navigate(-1)} >{t('Back')}</Button>
                   </div>

        <Tabs defaultActiveKey="Add Video" className="mb-3">
        <Tab eventKey="Add Video" title={t("AddUserPolicy")}>
        <form onSubmit={handleAddLcUserModal}>

        <div className="mb-3">
              <label className="form-label">{t('SelectUser')}</label>
              <select
                name="user_ids"
                className="form-control"
                onChange={handleChange}
                value={formData.users}
                multiple 
              >
                <option value="" disabled>
                  {t('SelectUsers')} {/* Adjust placeholder text */}
                </option>
                {data.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.username} ({user.email}) {/* Adjust based on actual field */}
                  </option>
                ))}
              </select>

            </div>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                    {t('Adding...')}
                                                </>
                                            ) : (
                                                t('AddUser')
                                            )}
                                        </button>
        </form>
        </Tab>
        </Tabs>
        </div>
  );
};

export default AddPoliceUser;
