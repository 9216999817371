import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { api_url } from "../../constant/constant";
import './QuestionOptions.css';
import { useNavigate } from 'react-router-dom';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import AddOption from '../../Components/AddQuestionOptionModal/AddOption';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";

import { useParams } from 'react-router-dom';

const QuestionOptions = ({  survey_id }) => {
       const {id} = useParams(); // Get questions_id from the URL

    const navigate = useNavigate();
    const { t } = useTranslation();

    // State variables
    const [data, setOptions] = useState([]);
    const [links, setLinks] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);

    // Handle navigation to Question page with lesson id
    const handleAnswersClick = (id) => {
        navigate(`/question/${id}`); // Pass lesson id as a route parameter
    };

   

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/questions/${survey_id}/options/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                showToast(t("answersdeletedsuccessfully"), "success");
                fetchOptions('');
            } else {
                showToast(t("failedtodeleteanswers"), "error");
            }
            } catch (error) {
                showToast(t("somethingwentwrong"), "error");
                setLoading(false);
            } finally {
            setLoading(false);
        }

    }


    useEffect(() => {
        if (searchValue !== '') {
            const filteredData = data.filter((item) =>
                item.option_text_en.toLowerCase().includes(searchValue.toLowerCase())
                || item.option_text_ar.toLowerCase().includes(searchValue.toLowerCase())
            );
            setOptions(filteredData);
        } else {
            fetchOptions('');
        }
    }, [searchValue]);


    // Fetch questions data with pagination
    const fetchOptions = async (url) => {
        setLoading(true);
        let page = 1;
        if (url) {
            const temp = url.split('?page=');
            page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
        }
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/questions/${id}/options`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
            setOptions(result); // Data update
        } catch (error) {
            console.error('Error fetching data:', error);
            showToast(t("errorfetchingdata"), "error"); // ✅ Sirf error hone per toast show hoga
        } finally {
            setLoading(false); // ✅ Loading state false hoga
        }
    };
    


    useEffect(() => {
        fetchOptions('');
    }, [survey_id]);




    // Define table columns
    const columns = React.useMemo(
        () => [

            {
                accessorKey: 'option_text_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Name(English)')}

                    </div>
                ),
                colName: t('Name(English)'),
            },
            {
                accessorKey: 'option_text_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Name(Arabic)')}
                    </div>
                ),
                colName: t('Name(Arabic)'),
            },


        ],
        []
        // [fetchOptions, handleDelete, survey_id]
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });



    return (
        <div>

        

         


                    <div className="p-1">

                        {/* <div className="pageTitle mb-4">All Answers</div> */}

                        <div className="primaryBlueBox p-5 mb-4">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="form-label">{t('SearchOptions')}</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder={t("Searchbyname")} aria-label="Search"
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                        <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                            <i className="bi bi-search"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <div className="vrLine"></div>
                                </div>
                                <div className="col-md-7">
                                    <AddOption survey_id={survey_id} parentfetchOptions={fetchOptions} />
                                </div>
                            </div>
                        </div>

                      
                        {loading ? (
                            <div className='d-flex justify-content-center align-items-center my-5'>
                                <div className='spinner-border' role='status' style={{
                                    borderTopColor: "#6897CB",
                                    borderBottomColor: "#6897CB",
                                    borderLeftColor: "#6897CB",
                                    marginTop: "50px"
                                }}></div>
                            </div>
                        ) : (

                            <div className="bg-light px-3 py-3 userTableWrapper resTable">

                                {window.innerWidth < 768 ? (
                                    // Render stacked view for small screens
                                    table.getRowModel().rows.map(row => (
                                        <div key={row.id} className="stacked-row">
                                            {row.getVisibleCells().map(cell => (
                                                <div key={cell.id} className="stacked-cell">
                                                    {cell.column.columnDef.colName != "ID" ? (
                                                        <strong>{cell.column.columnDef.colName}: </strong>
                                                    ) : ('')}

                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                ) : (
                                    <div className="1">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-dark">
                                                {table.getHeaderGroups().map(headerGroup => (
                                                    <tr key={headerGroup.id}>
                                                        {headerGroup.headers.map(header => (
                                                            <th key={header.id}>
                                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>

                                            <tbody>
                                                {table.getRowModel().rows.map(row => (
                                                    <tr key={row.id}>
                                                        {row.getVisibleCells().map(cell => (
                                                            <td key={cell.id}>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                            </div>
                        )}



                        <div className="navigationWrapper">
                            <ul>
                                {links.map((link, index) => (

                                    <li key={index}
                                        className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                                        onClick={link.url !== null ? () => fetchOptions(link.url) : undefined}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: link.label }} />
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </div>

            
        </div>
    );

}

export default QuestionOptions;