import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Button, Toast, ToastHeader } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";


const AddAnswers = ({ question_id, parentfetchAnswers }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    answer_en: "",
    answer_ar: "",
    is_correct: 0,
  });


  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    });
  };

  const handleAddAnswers = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/questions/${question_id}/answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        showToast(t("AnswerAddedSuccessfully"), "success");
        handleClose();
        setFormData({
          answer_ar: "",
          answer_en: "",
          is_correct: 0,
        });
        parentfetchAnswers("");
      } else {
        showToast(t("FailedToAddAnswer"), "error");
        handleClose();
      }
    } catch (error) {
      showToast(t("SomethingWentWrong"), "error");
      handleClose();
    } finally {
      setLoading(false);
    }
};

  return (
    <div>
      <br />
      <NavLink className="btnWhiteGradient" onClick={handleShow}>
        <i className="bi bi-chat-square-text me-2 h6"></i>{t('AddAnswer')}
      </NavLink>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('AddAnswer')}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleAddAnswers}>

          {loading ? (
            <Modal.Body className="d-flex justify-content-center align-items-center" style={{ minHeight: "200px" }}>
              <div
                className="spinner-border text-primary"
                role="status"
                style={{
                  width: "3rem",
                  height: "3rem",
                  borderWidth: "0.3rem",
                }}
              ></div>
            </Modal.Body>
          ) : (
            <Modal.Body>
              <div className="mb-3">
                <label className="form-label">{t('Answer(English)')}</label>
                <input
                  type="text"
                  name="answer_en"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.answer_en}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t('Answer(Arabic)')}</label>
                <input
                  type="text"
                  name="answer_ar"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.answer_ar}
                />
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="is_correct"
                  id="flexCheckIndeterminate"
                  onChange={handleChange}
                  checked={formData.is_correct === 1}
                />
                <label className="form-check-label" htmlFor="flexCheckIndeterminate">
                  {t('Correct')}
                </label>
              </div>
            </Modal.Body>
          )}

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t('Close')}
            </Button>
            <Button variant="primary" type="submit">
              {t('AddAnswer')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default AddAnswers;
