import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Button ,Spinner} from "react-bootstrap";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";
import { useParams } from 'react-router-dom';

const AddOption = ({ survey_id, parentfetchOptions }) => {
    console.log("Question ID from URL:", survey_id);
       const {id} = useParams(); // Get questions_id from the URL

    const [isLoading, setIsLoading] = useState(false); // Loader state

    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        option_text_en: "",
        option_text_ar: "",
        is_correct: 0, // Checkbox default value
    });
    const [options, setOptions] = useState([]);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
        });
    };


    const handleGetOptions = async () => {
        try {
            console.log("Fetching options for question ID:", survey_id); // Debug log
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/questions/${survey_id}/options`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setOptions(data);
              } else {
                showToast(t("failedToFetchOptions"), "error");
                setOptions([]);
              }
              } catch (error) {
                showToast(t("somethingWentWrong"), "error");
                setOptions([]);
              }
              
    };

    useEffect(() => {
        if (survey_id) {
            handleGetOptions();
        } else {
            // showToast("Question ID is missing!" , "error");
        }
    }, [survey_id]);

    const handleAddOptions = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Loader start

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + `/questions/${id}/options`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) {
                showToast(t("optionAddedSuccessfully"), "success");
                handleClose();
                setFormData({
                  optionTextEn: "",
                  optionTextAr: "",
                });
                parentfetchOptions('');
              } else {
                showToast(t("failedToAddOption"), "error");
                handleClose();
              }
              } catch (error) {
                showToast(t("somethingWentWrong"), "error");
                handleClose();
              } finally {
                setIsLoading(false); // Loader stop
              }
              
    }

    return (
        <div>
            {/* <label>{t('Add New Option')} </label> */}
            <br />
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <i class="bi bi-list me-2 mt-3 h6"></i>{t('AddOption')}
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('AddOption')}</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleAddOptions}>

                    <Modal.Body>
                        <div className="mb-3">
                            <label className="form-label">{t('Name(English)')}</label>
                            <input
                                type="text"
                                name="option_text_en"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.option_text_en}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t('Name(Arabic)')} </label>
                            <input
                                type="text"
                                name="option_text_ar"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.option_text_ar}
                                required
                            />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t('Close')}
                        </Button>
                          <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                            {isLoading ? (
                                                                <>
                                                                    <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                                    {t('Adding...')}
                                                                </>
                                                            ) : (
                                                                t('AddOption')
                                                            )}
                                                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
};

export default AddOption;
