import { useLocation, NavLink, json } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './LeftNav.css';
import { LOGO_SRC } from '../../constant/constant';
function LeftNav() {
    const location = useLocation();
    const { t } = useTranslation();
    const isActive = (path) => location.pathname === path;
    var userData = localStorage.getItem('userData');
    if (userData) {
        userData = JSON.parse(userData);
    }
    return (
        <div
            className="col-md-3 pt-4 pb-4 siteLeft"
            style={{ maxWidth: window.innerWidth < 768 ? '100%' : '310px' }}
        >

            <div className="logo ps-3">
                <img src={LOGO_SRC} alt="Cyber Sense" />
            </div>

            <div className="sidebarNavWrapper">
                <Container>
                    <Row className='pt-5'>
                        <Col xs={6}>
                            <div className="pageName ps-3">{t('Dashboard')}</div>
                        </Col>
                        <Col xs={6} className='text-end d-lg-none'>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarNav"
                                aria-controls="navbarNav"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                style={{ background: "transparent", border: "none" }}
                            >
                                <i className="bi bi-list"></i>
                            </button>

                        </Col>
                    </Row>
                </Container>

                <nav className="navbar navbar-expand-lg p-3">
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="list-unstyled">
                            <li >
                                {userData?.role_id !== 5 && (
                                    <li className="first-item py-2">
                                        <NavLink
                                            to="/dashboard"
                                            className={`d-block py-2 px-2 ${isActive("/dashboard") ? "active" : ""}`}
                                        >
                                            <i className="bi bi-pie-chart me-2 text-bluelightsense"></i> {t('Analytics')}
                                        </NavLink>
                                    </li>
                                )}
                                {userData?.role_id == 5 && (
                                    <li className="first-item py-2">
                                        <NavLink
                                            to="/DashboardNormal"
                                            className={`d-block py-2 px-2 ${isActive("/DashboardNormal") ? "active" : ""}`}
                                        >
                                            <i className="bi bi-house me-2 text-bluelightsense"></i> {t('Home')}
                                        </NavLink>
                                    </li>
                                )}
                            </li>
                            {/* Conditionally render the section based on role_id */}
                            {userData?.role_id !== 5 && (

                                <li className="first-item py-2">
                                    <a
                                        className="d-flex align-items-center py-2 px-2"
                                        data-bs-toggle="collapse"
                                        href="#dashboardcon"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="dashboardcon"
                                    >
                                        <i className="bi bi-grid me-2 text-bluelightsense"></i> {t('DashboardConfiguration')} <i className="ms-auto bi bi-chevron-down"></i>
                                    </a>
                                    <div className="collapse" id="dashboardcon">
                                        <ul className="list-unstyled ps-4">
                                            <li>
                                                <a
                                                    className="d-flex align-items-center py-2 px-2"
                                                    data-bs-toggle="collapse"
                                                    href="#systemsettings"
                                                    role="button"
                                                    aria-expanded="false"
                                                    aria-controls="systemsettings">
                                                    {t('SystemSettings')} <i className="ms-auto bi bi-chevron-down"></i>
                                                </a>
                                                <div className="collapse" id="systemsettings">
                                                    <ul className="list-unstyled ps-4">
                                                        <li>
                                                            <NavLink
                                                                to="/companydetail"
                                                                className={`d-block py-2 px-2 ${isActive("/companydetail") ? "active" : ""}`}>
                                                                {t('Company')}
                                                            </NavLink>
                                                        </li>

                                                        <li>
                                                            <NavLink
                                                                to="/TermDetails"
                                                                className={`d-block py-2 px-2 ${isActive("/TermDetails") ? "active" : ""}`}>
                                                                {t('TermsAndConditions')}
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>



                                            {/* <li>
                                                <a href="#sub1-2" className="d-block py-1 px-2">
                                                    {t('PhishScout')}
                                                </a>
                                            </li> */}
                                            {/* <li>
                                                <a href="#sub1-2" className="d-block py-1 px-2">
                                                    {t('Survey Manager')}
                                                </a>
                                            </li> */}
                                            <li>
                                                <NavLink
                                                    to="/emailserver"
                                                    className={`d-block py-2 px-2 ${isActive("/emailserver") ? "active" : ""}`}
                                                >
                                                    {t('EmailServerManager')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/emailtemplates"
                                                    className={`d-block py-2 px-2 ${isActive("/emailtemplates") ? "active" : ""}`}
                                                >
                                                    {t('EmailTemplates')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/licensemanager"
                                                    className={`d-block py-2 px-2 ${isActive("/licensemanager") ? "active" : ""}`}
                                                >
                                                    {t('LicenseManager')}
                                                </NavLink>

                                            </li>
                                            {/* <li>
                                        <NavLink
                                            to="/ldapserver"
                                            className={`d-block py-2 px-2 ${isActive("/ldapserver") ? "active" : ""}`}
                                        >
                                            {t('LDAP Server')}
                                            
                                        </NavLink>
                                        
                                    </li> */}


                                        </ul>
                                    </div>
                                </li>
                            )}


                            {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    <a
                                        className="d-flex align-items-center py-2 px-2"
                                        data-bs-toggle="collapse"
                                        href="#usermanagement"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="usermanagement"
                                    >
                                        <i className="bi bi-person me-2 text-bluelightsense"></i>   {t('UserManagement')} <i className="ms-auto bi bi-chevron-down"></i>
                                    </a>
                                    <div className="collapse" id="usermanagement">
                                        <ul className="list-unstyled ps-4">
                                            <li>
                                                <NavLink
                                                    to="/users"
                                                    className={`d-block py-2 px-2 ${isActive("/users") ? "active" : ""}`}
                                                >
                                                    {t('AllUsers')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/departments"
                                                    className={`d-block py-2 px-2 ${isActive("/departments") ? "active" : ""}`}
                                                >
                                                    {t('DepartmentsManager')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/LockedAccounts"
                                                    className={`d-block py-2 px-2 ${isActive("/LockedAccounts") ? "active" : ""}`}
                                                >
                                                    {t('LockedAccounts')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <a
                                                    className="d-flex align-items-center py-2 px-2"
                                                    data-bs-toggle="collapse"
                                                    href="#ldap"
                                                    role="button"
                                                    aria-expanded="false"
                                                    aria-controls="ldap"
                                                >
                                                    {t('LDAP')} <i className="ms-auto bi bi-chevron-down"></i>
                                                </a>
                                                <div className="collapse" id="ldap">
                                                    <ul className="list-unstyled ps-4">
                                                        <li>
                                                            <NavLink
                                                                to="/ldapserver"
                                                                className={`d-block py-2 px-2 ${isActive("/ldapserver") ? "active" : ""}`}
                                                            >
                                                                {t('LDAPServer')}

                                                            </NavLink>

                                                        </li>
                                                        {/* <li>
                                                        <NavLink
                                                            to="/infolog"
                                                            className={`d-block py-2 px-2 ${isActive("/infolog") ? "active" : ""}`}
                                                        >
                                                            {t('Info-logs')}
                                                        </NavLink>
                                                    </li> */}
                                                        {/* <li>
                                                        <NavLink
                                                            to="/errorlog"
                                                            className={`d-block py-2 px-2 ${isActive("/errorlog") ? "active" : ""}`}
                                                        >
                                                            {t('Error-logs')}
                                                        </NavLink>
                                                    </li> */}
                                                    </ul>
                                                </div>
                                            </li>


                                        </ul>
                                    </div>
                                </li>
                            )}


                            {/* Conditionally render the "My Certificate" section based on role_id */}
                            {userData?.role_id === 5 && (
                                <li className="first-item py-2">
                                    <a href="#home" className="d-block py-2 px-2">
                                        <i className="bi bi-file-earmark-text me-2 text-bluelightsense"></i> {t('MyTraining')}
                                    </a>
                                </li>
                            )}




                            {/* Conditionally render the "My Certificate" section based on role_id */}
                            {userData?.role_id === 5 && (
                                <li className="first-item py-2">
                                    <NavLink
                                        to="/MyCertificate"
                                        className={`d-block py-2 px-2 ${isActive("/MyCertificate") ? "active" : ""}`}
                                    >
                                        <i className="bi bi-file-earmark-text me-2 text-bluelightsense"></i> {t('MyCertificate')}
                                    </NavLink>
                                </li>
                            )}


                            {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    <a
                                        className="d-flex align-items-center py-2 px-2"
                                        data-bs-toggle="collapse"
                                        href="#awareness"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="awareness"
                                    >
                                        <i className="bi bi-file-earmark-text me-2 text-bluelightsense"></i> {t('AwarenessManagement')} <i className="ms-auto bi bi-chevron-down"></i>

                                    </a>
                                    <div className="collapse" id="awareness">
                                        <ul className="list-unstyled ps-4">

                                            <li>

                                                <li className="py-2">
                                                    <a
                                                        className="d-flex align-items-center py-2 px-2"
                                                        data-bs-toggle="collapse"
                                                        href="#training"
                                                        role="button"
                                                        aria-expanded="false"
                                                        aria-controls="training"
                                                    >
                                                        {t('TrainingManagement')} <i className="ms-auto bi bi-chevron-down"></i>
                                                    </a>
                                                    {/* Collapse content */}
                                                    <div className="collapse" id="training">
                                                        <ul className="list-unstyled ps-4">
                                                            <li>
                                                                <NavLink
                                                                    to="/lessons"
                                                                    className={`d-block py-2 px-2 ${isActive("/lessons") ? "active" : ""}`}
                                                                >
                                                                    {t('Lessons')}
                                                                </NavLink>
                                                            </li>

                                                            <li>
                                                                <NavLink
                                                                    to="/surveys"
                                                                    className={`d-block py-2 px-2 ${isActive("/surveys") ? "active" : ""}`}
                                                                >
                                                                    {t('Surveys')}
                                                                </NavLink>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </li>

                                            <li className="py-2">
                                                <NavLink
                                                    to="/learningcompaignmanager"
                                                    className={`d-block py-2 px-2 ${isActive("/learningcompaignmanager") ? "active" : ""}`}
                                                >
                                                    {t('LearningCampaignManagement')}
                                                </NavLink>
                                            </li>

                                            <li className="py-2">
                                                <NavLink
                                                    to="/CertificateManagement"
                                                    className={`d-block py-2 px-2 ${isActive("/CertificateManagement") ? "active" : ""}`}
                                                >
                                                    {t('CertificateManagement')}
                                                </NavLink>
                                            </li>


                                        </ul>
                                    </div>
                                </li>
                            )}

                            {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    <NavLink
                                        to="/policies"
                                        className={`d-flex align-items-center py-2 px-2 ${isActive("/policies") ? "active" : ""}`}
                                    >
                                        <i class="bi bi-file-earmark-ruled me-2 text-bluelightsense"></i>  {t('ComplianceManager')}
                                    </NavLink>
                                </li>
                            )}

                            {/* Conditionally render the "Phishing Management" section based on role_id */}
                            {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    <NavLink
                                        to="/PhishingCompaign"
                                        className={`d-block py-2 px-2 ${isActive("/PhishingCompaign") ? "active" : ""}`}
                                    >
                                        <i className="bi bi-file-earmark-ppt me-2 text-bluelightsense"></i> {t('PhishingManagement')}
                                    </NavLink>
                                </li>
                            )}
                             {/* Conditionally render the "Phishing Management" section based on role_id */}
                             {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    <NavLink
                                        to="/Systemlogs"
                                        className={`d-block py-2 px-2 ${isActive("/Systemlogs") ? "active" : ""}`}
                                    >
                                        <i className="bi bi-file-earmark-medical me-2 text-bluelightsense"></i> {t('systemlogs')}
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default LeftNav;
