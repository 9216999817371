import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useParams } from 'react-router-dom';
import AddSurveyQuestion from '../../Components/AddSurveyQuestionModal/AddSurveyQuestion';
// import AnswersModal from '../../Components/AnswersModal/AnswersModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import QuestionOption from '../../Components/QuestionOptionsModal/QuestionOptions'
import { NavLink } from 'react-router-dom';
import { showToast } from '../../Components/ShowToast/ToastNotification';

const SurveyQuestions = () => {


    const { survey_id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // State variables
    const [data, setLessons] = useState([]);
    const [links, setLinks] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);

    // Handle navigation to Question page with lesson id
    const handleQuestionClick = (id) => {
        navigate(`/question/${id}`); // Pass lesson id as a route parameter
    };

    // Handle delete user action
    // const handleDelete = async(id) => {

    //        try {
    //            const token = localStorage.getItem("userToken");
    //            const response = await fetch(`${api_url}/lessons/${survey_id}/questions/${id}`,  {
    //                    method: 'DELETE',
    //                    headers: {
    //                      'Content-Type': 'application/json',
    //                      Authorization: `Bearer ${token}`,
    //                    },
    //            });

    //            if (response.ok) 
    //            {
    //                setDeleted("Question deleted successfully!");
    //                fetchQuestions('');
    //            } 
    //            else 
    //            {
    //                setDeleted("Error!! Failed to delete Question. Please try again.");
    //            }
    //        }
    //        catch (error) {
    //            setDeleted("Error!! " + error);
    //        }

    //    }


    useEffect(() => {
        if (searchValue !== '') {
            const filteredData = data.filter((item) =>
                item.question_text_en.toLowerCase().includes(searchValue.toLowerCase())
                || item.question_text_ar.toLowerCase().includes(searchValue.toLowerCase())
                || item.question_type.toLowerCase().includes(searchValue.toLowerCase())
            );
            setLessons(filteredData);
        }
        else {
            fetchQuestions('');
        }
    }, [searchValue]);


    // Fetch questions data with pagination
    const fetchQuestions = async (url) => {
        setLoading(true);
        let page = 1;
        if (url) {
            const temp = url.split('?page=');
            page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/surveys/${survey_id}/questions?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });


            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setLessons(result);
            // setLinks(result.links);
        } catch (error) {
            console.error('Error fetching data:', error);
            showToast(t("errorfetchingdata"), "error");
            setLoading(false);        
            //localStorage.removeItem("userToken");
            //localStorage.removeItem("userData");
            //window.location = "/login";
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchQuestions('');
    }, []);

    // Define table columns
    const columns = React.useMemo(
        () => [

            {
                accessorKey: 'question_text_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Question(English)')}
                    </div>
                ),
                colName: ' question_text_en',
            },
            {
                accessorKey: 'question_text_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Question(Arabic)')}
                    </div>
                ),
                colName: ' question_text_ar',
            },
            {
                accessorKey: 'question_type',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('QuestionType')}
                    </div>
                ),
                colName: 'question_type',
            },

            // {
            //     accessorKey: 'action', // Correct accessor key
            //     header: () => (
            //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            //             <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
            //         </div>
            //     ),
            //     cell: ({ row }) => {
            //         const id = row.original.id; // Getting the correct id for each row
            //         return (
            //             <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            
            //                 {/* <QuestionOption id={id} survey_question_id={id} survey_id={survey_id} /> */}
            
            //                 {/* Other components like EditQuestion if needed */}
            
            //                 {/* Delete button */}
            //                 {/* <div className="iconDel" onClick={() => handleDelete(id)}>
            //                     <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
            //                 </div> */}
            //             </div>
            //         );
            //     },
            //     colName: 'Action', // Correct property spelling
            // }
            

        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    const handleSurveyRowClick = (id) => {
        navigate(`/SurveyQuestionDetail/${id}/SurveyQuestionDetail`)
    }
    return (
        <div className="p-4">

            {/* <div className="pageTitle mb-4">All Questions</div> */}

            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('SurveyQuestion')}</label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder={t("Searchquestion")} aria-label="Search"
                                value={searchValue}
                                onChange={(e) => setSearchValue((e.target.value))}
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        {/* <AddSurveyQuestion parentfetchSurveys={fetchQuestions} /> */}
                        <NavLink className="btnWhiteGradient mt-3" to={`/Add-Survey-Question?survey_id=${survey_id}`}>
                                        <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddSurveyQuestion')}
                        </NavLink>
                    </div>
                </div>
            </div>

            {/* {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )} */}
            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}></div>
                </div>
            ) : (

                <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                                    {window.innerWidth < 768 ? (
                                        table.getRowModel().rows.map(row => (
                                            <div key={row.id} className="stacked-row"
                                                onClick={row.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                                {row.getVisibleCells().map(cell => (
                                                    <div key={cell.id} className="stacked-cell">
                                                        <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    ) : (
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                {table.getHeaderGroups().map(headerGroup => (
                                                    <tr key={headerGroup.id}>
                                                        {headerGroup.headers.map(header => (
                                                            <th key={header.id}>
                                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody>
                                                {table.getRowModel().rows.map(row => (
                                                    <tr key={row.id}>
                                                        {row.getVisibleCells().map(cell => (
                                                            <td key={cell.id}
                                                                className='rowAnchor'
                                                                onClick={cell.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
            )}



            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchQuestions(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
};

export default SurveyQuestions;
