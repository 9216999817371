import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddDepartment from '../../Components/AddDepartmentModal/AddDepartment';
// import EditDepartment from '../../Components/EditDepartmentModal/EditDepartment';
import './Department.css';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { NavLink } from 'react-router-dom';

const Department = () => {
  const [data, setDepartment] = useState([]);
  const [links, setLinks] = useState([]);
  const { t } = useTranslation();
  const [searchVal, setSearchVal] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const navigate = useNavigate(); const [toastData, setToastData] = useState(null);


  // Handle department deletion
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${api_url}/admin/main/departments/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        showToast(t("deletesuccess"), "success");
      
        // Refresh data after successful deletion
        fetchDepartment('');
        setShowModal(false);
      } else {
        showToast(t("deletefailed"), "error");
        setShowModal(false);
      }
      } catch (error) {
        setShowModal(false);
        showToast(t("deleteerror"), "error");
        setLoading(false);
      }
       finally {
      setLoading(false);
    }
  };
  useEffect(() => {

    if (searchVal !== '') {
      const filteredData = data.filter((item) =>
        item.name.toLowerCase().includes(searchVal.toLowerCase())
      );
      setDepartment(filteredData); // Update filtered data
    } else {
      fetchDepartment(''); // Update filtered data
    }
  }, [searchVal]); // Re-run whenever searchVal or allData changes

  // Fetch department data
  const fetchDepartment = async (url) => {
    setLoading(true);
    let page = 1;
    if (url) {
      const temp = url.split('?page=');
      page = temp[1];
    }

    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${api_url}/admin/main/departments?page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      setDepartment(result.data);
      setLinks(result.links);
      } catch (error) {
        console.error('Error fetching data:', error);
        showToast(t("fetcherror"), "error");
        
        setLoading(false);
      }
       finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartment('');
  }, []);

  // Define table columns
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: () => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <i class="bi bi-building"></i>{t('DepartmentName')}
          </div>


        ),
        colName: t('DepartmentName'),
      },
      {
        accessorKey: 'created_at',
        header: () => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src="/images/fi_clock.png" alt="Date of Creation" />{t('DateofCreation')}
          </div>
        ),

        cell: ({ row }) => {
          const rawDate = row.original.created_at; // Get the raw date string
          const formattedDate = new Date(rawDate).toLocaleDateString('en-US', {

            day: 'numeric',
            month: 'short',
            year: 'numeric',

          });

          return formattedDate; // Return formatted date
        },
        colName: t('DateofCreation'),
      },

      {
        accessorKey: 'id',
        header: () => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px', }}>
            <img src="/images/fi_alert-octagon.png" alt="Action" />{t('Action')}
          </div>
        ),
        cell: ({ getValue }) => {
          const id = getValue();
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {/* <EditDepartment id={id}  onfetchDepartment={fetchDepartment}/> */}
              <div className="iconDel" onClick={(e) => {e.stopPropagation();
                setSelectedDepartmentId(id); setShowModal(true)
                setLoading(true);
                setLoading(false);
              }}>
                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
              </div>
            </div>
          );
        },
        colName: t('Action'),
      },
    ],
    []
  );

  // Create a table instance
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const handleSurveyRowClick = (id) => {
    navigate(`/Departmentdetail/${id}/DepartmentDetail`)
  }
  return (
    <div className="p-4">
      <div className="pageTitle mb-4">{t('Departments')}</div>



      <div className="primaryBlueBox p-5 mb-4">
        <div className="row">
          <div className="col-md-4">
            <label className="form-label">{t('SearchDepartment')}</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder={t("Searchbydepartmentname")}
                aria-label="Search by department name"
                value={searchVal} // Bind input value to searchVal state
                onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
              />
              <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div className="col-md-1">
            <div className="vrLine"></div>
          </div>
          <div className="col-md-7">
            {/* <AddDepartment onfetchDepartment={fetchDepartment} /> */}
            <NavLink className="btnWhiteGradient mt-3" to="/add-department">
                           <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddDepartment')}
              </NavLink>

          </div>
        </div>
      </div>

      {loading ? (
        <div className='d-flex justify-content-center align-items-center my-5'>
          <div className='spinner-border' role='status' style={{
            borderTopColor: "#6897CB",
            borderBottomColor: "#6897CB",
            borderLeftColor: "#6897CB",
            marginTop: "50px"
          }}></div>
        </div>
      ) : (

        <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
          {window.innerWidth < 768 ? (
            table.getRowModel().rows.map(row => (
              <div key={row.id} className="stacked-row"
                onClick={row.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                {row.getVisibleCells().map(cell => (
                  <div key={cell.id} className="stacked-cell" >
                    <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                ))}
              </div>
            ))
          ) : (
            <table className="table table-bordered table-hover">
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}
                        className='rowAnchor'
                        onClick={cell.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}


      <div className="navigationWrapper">
        <ul>
          {links.map((link, index) => (
            <li
              key={index}
              className={`${link.active ? 'active' : ''} ${link.url === null ? 'disabled' : ''
                }`}
              onClick={link.url !== null ? () => fetchDepartment(link.url) : undefined}
            >
              <div dangerouslySetInnerHTML={{ __html: link.label }} />
            </li>
          ))}
        </ul>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                <Modal.Title>{t("ConfirmDelete")}</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            {t("Areyousureyouwanttodeletethisdepartment?")}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="danger" onClick={()=>handleDelete(selectedDepartmentId)} disabled={loading}>
                                {loading ? t("Deleting...") : t("Delete")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
     
    </div>
  );
};

export default Department;
