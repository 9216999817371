import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PreviewEmail = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ title: "", body: "", subject: "" });

    const updateEmailData = () => {
        const storedEmail = localStorage.getItem("EmailTemp");
        if (storedEmail) {
            try {
                const parsedEmail = JSON.parse(storedEmail);
                setFormData(parsedEmail || { title: "No title", body: "No content", subject: "No subject" });
            } catch (error) {
                console.error("Error parsing EmailTemp:", error);
            }
        }
    };

    useEffect(() => {
        updateEmailData(); 
        const interval = setInterval(updateEmailData, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='m-5'>
            <h2 className="pageTitle">{t("PreviewEmailTemplate")}</h2>

            <div className='mt-3'>
                <strong>{t('Title')}:</strong> <br />
                <div>{formData.title || "No data available"}</div>
            </div>

            <div className='mt-3'>
                <strong>{t('EmailBody')}:</strong>
                <div className='emailBody' dangerouslySetInnerHTML={{ __html: formData.body || "<p>No content available</p>" }}></div>
            </div>

            <div className='mt-3'>
                <strong>{t('Subject')}:</strong> <br />
                <div>{formData.subject || "No subject available"}</div>
            </div>
        </div>
    );
};

export default PreviewEmail;
