import { Button ,Spinner} from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { showToast } from "../ShowToast/ToastNotification";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";

const EditPolicy = ({ id }) => {
  const [policyId] = useState(id);
      const [isLoading, setIsLoading] = useState(false); // Loader state
  
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [policyTypes, setPolicyTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [isHtmlPolicy, setIsHtmlPolicy] = useState(false);

  const [formData, setFormData] = useState({
    name_en: "",
    name_ar: "",
    content_en: "",
    content_ar: "",
    version: "",
    policy_type: "",
    pdf_file: "",
  });

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["blockquote", "code-block"],
      ["link", "image", "video"],
      ["clean"],
    ],
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };

  useEffect(() => {
    fetchPolicyTypes();
    fetchPolicy();
  }, []);

  const fetchPolicyTypes = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/policy-types`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Error fetching policy types");

      const result = await response.json();
      setPolicyTypes(result.data || result);
    } catch (error) {
      showToast(t("errorFetchingPolicyTypes"), "error");
  }  
  };

  const fetchPolicy = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/policies/${policyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        showToast(t("errorFetchingPolicyDetails"), "error");
        handleClose();
        return;
    }    

      const res = await response.json();

      setFormData({
        name_en: res.data.name_en || "",
        name_ar: res.data.name_ar || "",
        content_en: res.data.content_en || "",
        content_ar: res.data.content_ar || "",
        version: res.data.version || "",
        policy_type: res.data.policy_type || "",
        pdf_file: res.data.pdf_file || "",
      });

      setSelectedType(res.data.policy_type || "");

      setIsHtmlPolicy(res.data.policy_type?.toLowerCase() === "html");
    } catch (err) {
      showToast(t("somethingWentWrong"), "error");
      handleClose();
  }  
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleContentChange = (key, value) => {
    setFormData(prevData => ({
      ...prevData,
      [key]: value,
    }));
  };
  

  const handlePolicyTypeChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedType(selectedValue);
    setFormData({ ...formData, policy_type: selectedValue });

    setIsHtmlPolicy(selectedValue.toLowerCase() === "html");
  };

  const handleUpdatePolicy = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
        const token = localStorage.getItem("userToken");
        const formDataToSend = new FormData();

        formDataToSend.append("name_en", formData.name_en);
        formDataToSend.append("name_ar", formData.name_ar);
        formDataToSend.append("version", String(formData.version));
        formDataToSend.append("policy_type", formData.policy_type);

        if (isHtmlPolicy) {
            formDataToSend.append("content_en", formData.content_en);
            formDataToSend.append("content_ar", formData.content_ar);
            formDataToSend.append("pdf_file", ""); // Empty PDF when HTML content is provided
        } else {
            formDataToSend.append("pdf_file", formData.pdf_file);
            formDataToSend.append("content_en", ""); // Empty content when PDF is uploaded
            formDataToSend.append("content_ar", "");
        }

        const response = await fetch(`${api_url}/policies/${policyId}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formDataToSend,
        });

        const responseData = await response.json();

        if (response.ok) {
          showToast(t("policyUpdatedSuccessfully"), "success");
          navigate(-1);
      } else {
          showToast(t("failedToUpdatePolicy"), "error");
      }
      } catch (error) {
          showToast(t("somethingWentWrong"), "error");
      } finally {
          setIsLoading(false);
      }      
};

  return (
    <div
      className="editPolicyContainer"
      style={{
        border: "1px solid #EAEAEA",
        borderRadius: "10px",
        padding: "26px 26px",
        margin: "20px",
      }}
    >
      <form onSubmit={handleUpdatePolicy} className="EditPolicy">
        <div className="mb-3">
          <label className="form-label">{t("Name(English)")}</label>
          <input type="text" name="name_en" className="form-control" onChange={handleChange} value={formData.name_en} required />
        </div>

        <div className="mb-3">
          <label className="form-label">{t("Name(Arabic)")}</label>
          <input type="text" name="name_ar" className="form-control" onChange={handleChange} value={formData.name_ar} required />
        </div>

        <div className="mb-3">
          <label className="form-label">{t("PolicyType")}</label>
          <select name="policy_type" className="form-select" onChange={handlePolicyTypeChange} value={selectedType} required disabled>
            <option value="">{t("SelectPolicyType")}</option>
            {policyTypes.map((type) => (
              <option key={type.name} value={type.name}>
                {type.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label">{t("Version")}</label>
          <input type="number" name="version" className="form-control" onChange={handleChange} value={formData.version} required />
        </div>

        {isHtmlPolicy ? (
          <>
            <div className="mb-3">
              <label className="form-label">{t("Content(English)")}</label>
              <ReactQuill value={formData.content_en} onChange={(value) => handleContentChange("content_en", value)} modules={modules} />
            </div>

            <div className="mb-3">
              <label className="form-label">{t("Content(Arabic)")}</label>
              <ReactQuill value={formData.content_ar} onChange={(value) => handleContentChange("content_ar", value)} modules={modules} />
            </div>
          </>
        ) : (
          <div className="mb-3">
            <label className="form-label">{t("UploadPDFFile")}</label>
            <input
                  type="file"
                  name="pdf_file"
                  className="form-control"
                  accept=".PDF"
                  onChange={handleChange}
                  required
                />
          </div>
        )}

        <div className="text-end">
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                              {isLoading ? (
                                                  <>
                                                      <Spinner animation="border" size="sm" role="status" className="me-2" />
                                                      {t('Update...')}
                                                  </>
                                              ) : (
                                                  t('UpdatePolicy')
                                              )}
                                          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPolicy;
