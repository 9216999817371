import { Modal, Button } from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './DetailLicense.css';
import { useTranslation } from 'react-i18next';
import { showToast } from "../ShowToast/ToastNotification";

const DetailLicense = ({id}) => {

    const { t } = useTranslation();
    const [userId] = useState(id);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        customer_id: "",
        system_license_max_users: "",
        system_license_start_date: "",
        system_license_expiration_date: "",
        phishing_license_max_users: "",
        phishing_license_start_date: "",
        phishing_license_expiration_date: "",
        is_active: "",
        is_usedt: "",
        system_license_key: "",
       
    });

    const fetchLicenseData = async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
			const response = await fetch(api_url + "/licenses/"+ userId , {
				mode: 'cors',
				method: "GET",
			  	headers: {
					"Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
			  	}
			});
	  
			if (!response.ok) {
                showToast(t("errorFetchingLicenseDetails"), "error");
              }
              
	  
			const data = await response.json();
			
            setFormData({
                ...formData,
                ["customer_id"]: data.customer_id,
                ["system_license_max_users"]: data.system_license_max_users,
                ["system_license_start_date"]: data.system_license_start_date,
                ["system_license_expiration_date"]: data.system_license_expiration_date,
                ["phishing_license_max_users"]: data.phishing_license_max_users,
                ["phishing_license_start_date"]: data.phishing_license_start_date,
                ["phishing_license_expiration_date"]: data.phishing_license_expiration_date,
                ["is_active"]: data.is_active,
                ["is_used"]: data.is_used,
                ["system_license_key"]: data.system_license_key,
            });

		} 
		catch (err) {
            showToast(t("somethingWentWrongRefresh"), "error");
          }
          
    }

    useEffect(() => {
        fetchLicenseData();
    }, []);

   

   

    return (
        <div>

            <div className="iconEdit" onClick={handleShow}>
               {t('LicenseDetail')} 
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('LicenseDetail')}</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                    <div className="row">
                    <label className="form-label">{t('Licensekey')}</label>
                    <p
                        className="form-control-plaintext text-truncate"
                        style={{ maxWidth: "300px", cursor: "pointer" }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={formData.system_license_key}
                    >
                        {formData.system_license_key
                            ? `${formData.system_license_key.substring(0, 50)}...`
                            : "N/A"}
                    </p>
                </div>
                
                <div className="container">
                <div className="row">
                    {/* Customer and Used */}
                    <div className="col-md-6 mb-3">
                        <label className="form-label">{t('Customer')}</label>
                        <p className="form-control-plaintext">{formData.customer_id}</p>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="form-label">{t('Used')}</label>
                        <p className="form-control-plaintext">{formData.is_used}</p>
                    </div>
                </div>

                <div className="row">
                    {/* License Max Users */}
                    <div className="col-md-6 mb-3">
                        <label className="form-label">{t('LicenseMaxUsers')}</label>
                        <p className="form-control-plaintext">{formData.system_license_max_users}</p>
                    </div>

                    {/* License Start Date */}
                    <div className="col-md-6 mb-3">
                        <label className="form-label">{t('LicenseStartDate')}</label>
                        <p className="form-control-plaintext">{formData.system_license_start_date}</p>
                    </div>
                </div>

                <div className="row">
                    {/* License Expiration Date */}
                    <div className="col-md-6 mb-3">
                        <label className="form-label">{t('LicenseExpirationDate')}</label>
                        <p className="form-control-plaintext">{formData.system_license_expiration_date}</p>
                    </div>

                    {/* Phishing Max Users */}
                    <div className="col-md-6 mb-3">
                        <label className="form-label">{t('PhishingMaxUsers')}</label>
                        <p className="form-control-plaintext">{formData.phishing_license_max_users}</p>
                    </div>
                </div>

                <div className="row">
                    {/* Phishing Start Date */}
                    <div className="col-md-6 mb-3">
                        <label className="form-label">{t('PhishingStartDate')}</label>
                        <p className="form-control-plaintext">{formData.phishing_license_start_date}</p>
                    </div>

                    {/* Phishing Expiration Date */}
                    <div className="col-md-6 mb-3">
                        <label className="form-label">{t('PhishingExpirationDate')}</label>
                        <p className="form-control-plaintext">{formData.phishing_license_expiration_date}</p>
                    </div>

                    {/* Active */}
                    <div className="col-md-6 mb-3">
                        <label className="form-label">{t('Active')}</label>
                        <p className="form-control-plaintext">{formData.is_active}</p>
                    </div>
                </div>

                </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {/* <div className="mb-3">
                                <label className="form-label">Role</label>
                                <select className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Supersoft</option>
                                    <option value="2">Professional Service</option>
                                    <option value="3">Admin</option>
                                </select>
                            </div> */}
                        </div>
                        <div className="col-md-6">
                            {/* <div className="mb-3">
                                <label className="form-label">Status</label>
                                <select className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div> */}
                        </div>
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')}
                    </Button>

                    {/* <Button variant="primary" onClick={handleEditEmail}>
                    {t('Update Email Server')}  
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default DetailLicense;