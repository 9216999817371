import './App.css';
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import DashboardNormal from './Pages/DashboardNormal/DashboardNormal';
import Users from './Pages/Users/Users';
import AwarenessManager from './Pages/AwarenessManager/AwarenessManager';
import NotFound from './Pages/NotFound/NotFound';
import Root from './Pages/Root/Root';
import Department from './Pages/Department/Department';
import Email from './Pages/EmailServer/Email';
import { useEffect, useState } from 'react';
import Lessons from './Pages/Lessons/Lessons';
import Questions from './Pages/Questions/Questions';
import Answers from './Pages/Answers/Answers';
import Videos from './Pages/Videos/Videos';
import LessonDetail from './Pages/LessonDetail/LessonDetail';
import License from './Pages/LicenseManager/License';
import { useTranslation } from 'react-i18next';
import Police from './Pages/Polices/Police';
import Surveys from './Pages/Surveys/Surveys';
import SurveyDetail from './Pages/SurveyDetails/SurveyDetail';
import LDAPServer from './Pages/LDAPServer/LDAPServer';
import EmailTemplates from './Pages/EmailTemplates/EmailTemplates';
import AddEmailTemplate from './Components/AddEmailTemplates/AddEmailTemplate';
import EditEmailTemplate from './Components/EditEmailTemplate/EditEmailTemplate';
import LearningManager from './Pages/LearningManager/LearningManager';
import PreviewEmailTemplate from './Components/PreviewEmailTemplate/PreviewEmailTemplate';
import Updategender from './Pages/UpdateGender/Updategender';
import Assessment from './Pages/Assessment/Assessment';
import Infologs from './Pages/Info-logs/Infologs';
import Errorlogs from './Pages/Error-logs/Errorlogs';
import UpdatePassword from './Pages/UpdatePassword/UpdatePassword';
import UserDetail from './Pages/UserDetail/UserDetail';
import LDAPServerDetail from './Pages/LDAPServerModelDetails/LDAPServerDetail';
import LearningCompaignDetail from './Pages/LearningCompaignDetail/LearningCompaignDetail';
// import Company from './Pages/SystemSettings/Company';
import PhishingCompaign from './Pages/PhishingCompaignManager/PhishingCompaign';
import PhishingCompaignDetail from './Pages/PhishingCompaignDetail/PhishingCompaignDetail';
import CompanyDetail from './Pages/CompanySettingDetail/CompanyDetail';
import PhishingCompaignSettingDetail from './Components/PhishingCompaignSettingDetailModal/PhishingCompaignSettingDetail';
import DepartmentDetail from './Pages/DepartmentDetail/DepartmentDetail';
import EmailServerDetail from './Pages/EmailServerDetail/EmailServerDetail';
import PoliceDetail from './Pages/PoliceDetail/PoliceDetail';
import ToastNotification from './Components/ShowToast/ToastNotification';
import LessonQuiz from './Pages/LessonQuizDetail/LessonQuiz';
import LessonQuizDetail from './Pages/LessonQuizDetail/LessonQuizDetail';
import EmailTemplatesDetail from './Pages/EmailTemplatesDetail/EmailTemplatesDetail';
import LessonVideos from './Pages/LessonVideo/lessonVideo';
import Callback from './Pages/Callback/CallBackpage';
import QuizNormal from './Pages/QuizNormal/QuizNormal';
import AddEmail from './Components/AddEmailServerModal/AddEmail';
import AddLicenseManager from './Components/AddLicenseManager/AddLicenseManager';
import AddDepartment from './Components/AddDepartmentModal/AddDepartment';
import AddLDAPServer from './Components/AddLDAPServerModal/AddLDAPServer';
import AddLesson from './Components/AddLessonModal/AddLesson';
import AddPolice from './Components/AddPoliceModal/AddPolice';
import AddSurvey from './Components/AddSurveyModal/AddSurvey';
import AddUser from './Components/AddUserModal/AddUser';
import AddLearningManager from './Components/AddLearningManager/AddLearningManager';
import AddPhishingCompaign from './Components/AddPhishingCompaignModal/AddPhishingCompaign';
import AddVideo from './Components/AddVideoModal/AddVideo';
import AddSurveyQuestion from './Components/AddSurveyQuestionModal/AddSurveyQuestion';
import AddQuestion from './Components/AddQuestionModal/AddQuestion';
import AddLessonQuiz from './Components/AddLessonQuizModal/Addlessonquiz';
import AddLcLesson from './Components/AddLcLessonModal/AddLcLesson';
import AddLcUserModal from './Components/AddLcUserModal/AddLcUserModal';
import AddPhishingCompaignUser from './Components/AddPhishingCompaignUserModal/AddPhishingCompaignUser';
import AddPhishingCompaignSetting from './Components/AddPhishingCompaignSettingModal/AddPhishingCompaignSetting';
import LessonVideo from './Pages/LessonVideoDetail/LessonVideo';
import LessonQuestion from './Pages/LessonQuestionDetail/LessonQuestion';
import CampaignExam from './Pages/DashboardNormal/CampaignExam/CampaignExam';
import TemsDetails from './Pages/TermsAndConditions/TemsDetails';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';
import CertificateManager from './Pages/CertificateManager/CertificateManager';
// import AddPoliceUser from './Components/AddPoliceUserModel/AddPoliceUser';
import AddPoliceUser from './Components/AddPoliceUserModel/AddPoliceUser';
import AddCertificateManager from './Components/AddCertificateManager/AddCertificateManager';
import CertificateManagerDetails from './Pages/CertificateManagerDetails/CertificateManagerDetails';
import Terms from './Pages/Terms/Terms';
import SurveyQuestionDetail from './Pages/SurveyQuestionDetails/SurveyQuestionDetail ';
import LockedUsers from './Pages/LockedUsers/LockedUsers';
import Profile from './Pages/Profile/Profile';
import MyCertificate from './Pages/MyCertificate/MyCertificate';
import SystemLogs from './Pages/SystemLogsPage/SystemLogs';
import UserPolicies from './Pages/DashboardNormal/UserPolicies/UserPolicies';
import UploadCertificate from'./Pages/UploadCertificate/UploadCertificate';
import Certificate from './Pages/Certificate/Certificate';
const checkAuth = () => {
	const isAuthenticated = localStorage.getItem('userToken');
	if (!isAuthenticated) {
		return redirect('/login');
	}
	return null;
};

// Define Routes
const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <NotFound />,
		children: [
			{ index: true, element: <Dashboard />, loader: checkAuth },
			{ path: '/login', element: <Login /> },
			{ path: '/callback', element: <Callback /> },
			{ path: '/dashboard', element: <Dashboard />, loader: checkAuth },
			{ path: '/dashboardNormal', element: <DashboardNormal />, loader: checkAuth },
			{ path: '/UserPolicies', element: <UserPolicies />, loader: checkAuth },
			{ path: '/Updategender', element: <Updategender /> },
			{ path: '/Assessment', element: <Assessment /> },
			{ path: '/users', element: <Users />, loader: checkAuth },
			{ path: '/lessons', element: <Lessons />, loader: checkAuth },
			{ path: '/awareness-manager', element: <AwarenessManager />, loader: checkAuth },
			{ path: '/departments', element: <Department />, loader: checkAuth },
			{ path: '/emailserver', element: <Email />, loader: checkAuth },
			{ path: '/lessons/:lesson_id/questions', element: <Questions />, loader: checkAuth },
			{ path: '/questions/:questions_id/answers', element: <Answers />, loader: checkAuth },
			{ path: '/lessons/:lesson_id/videos', element: <Videos />, loader: checkAuth },
			{ path: '/lessons/:lesson_id/detail', element: <LessonDetail />, loader: checkAuth },
			{ path: '/licensemanager', element: <License />, loader: checkAuth },
			{ path: '/policies', element: <Police />, loader: checkAuth },
			{ path: '/surveys', element: <Surveys />, loader: checkAuth },
			{ path: '/surveys/:survey_id/detail', element: <SurveyDetail />, loader: checkAuth },
			{ path: '/ldapserver', element: <LDAPServer />, loader: checkAuth },
			{ path: '/learningcompaignmanager', element: <LearningManager />, loader: checkAuth },
			{ path: '/emailtemplates', element: <EmailTemplates />, loader: checkAuth },
			{ path: '/addemailtemplate', element: <AddEmailTemplate />, loader: checkAuth },
			{ path: '/editemailtemplate/:id', element: <EditEmailTemplate />, loader: checkAuth },
			{ path: '/previewemailtemplate/:id', element: <PreviewEmailTemplate />, loader: checkAuth },
			{ path: '/infolog', element: <Infologs /> },
			{ path: '/errorlog', element: <Errorlogs /> },
			{ path: '/updatepassword', element: <UpdatePassword /> },
			{ path: '/learningcompainmanager/:id/LearningCompainDetail', element: <LearningCompaignDetail /> },
			{ path: '/UserManagement/:id/UserDetail', element: <UserDetail /> },
			{ path: '/ldapservermodel/:id/LDAPServerDetail', element: <LDAPServerDetail /> },
			{ path: '/learningcompaignmanager/:id/LearningCompaignDetail', element: <LearningCompaignDetail /> },
			{ path: '/UserManagement/:id/UserDetail', element: <UserDetail /> },
			// {path:'/company',element:<Company />},
			{ path: "/PhishingCompaign", element: <PhishingCompaign /> },
			{ path: "/Systemlogs", element: <SystemLogs /> },
			{ path: '/phishinhcompaigndetail/:id/phishinhcompaigndetail', element: <PhishingCompaignDetail /> },
			{ path: '/companydetail', element: <CompanyDetail /> },
			{ path: '/PhishingCompaignSettingDetail/:id/PhishingCompaignSettingDetail', element: <PhishingCompaignSettingDetail /> },
			{ path: '/Departmentdetail/:id/DepartmentDetail', element: <DepartmentDetail /> },
			{ path: '//EmailServerDetail/:id/EmailServerDetail', element: <EmailServerDetail /> },
			{ path: '/PoliceDetail/:id/PoliceDetail', element: <PoliceDetail /> },
			{ path: '/LessonQuiz/:id/LessonQuiz', element: <LessonQuiz /> },
			{ path: '/LessonQuizDetail/:id', element: <LessonQuizDetail /> },
			{ path: '/EmailTemplatesDetail/:id/EmailTemplatesDetail', element: <EmailTemplatesDetail /> },
			{ path: '/LessonVideos', element: <LessonVideos />, loader: checkAuth },
			{ path: '/QuizNormal', element: <QuizNormal />, loader: checkAuth },
			{ path: '/EmailTemplatesDetail/:id/EmailTemplatesDetail', element: <EmailTemplatesDetail /> },
			{ path: '/AddEmail', element: <AddEmail />, loader: checkAuth },
			{ path: '/AddLicenseManager', element: <AddLicenseManager /> },
			{ path: '/add-department', element: <AddDepartment /> },
			{ path: '/add-ldap-server', element: <AddLDAPServer /> },
			{ path: '/Add-Lesson', element: <AddLesson /> },
			{ path: '/Add-Police', element: <AddPolice /> },
			{ path: '/Add-Survey', element: <AddSurvey /> },
			{ path: '/Add-User', element: <AddUser /> },
			{ path: '/Add-Learning-Manager', element: <AddLearningManager /> },
			{ path: '/Add-Phishing-Compaign', element: <AddPhishingCompaign /> },
			{ path: '/Add-Video', element: <AddVideo /> },
			{ path: '/Add-Survey-Question', element: <AddSurveyQuestion /> },
			{ path: '/Add-Question', element: <AddQuestion /> },
			{ path: '/Add-License-Manager', element: <AddLicenseManager /> },
			{ path: '/Add-Lesson-Quiz', element: <AddLessonQuiz /> },
			{ path: '/Add-Lc-Lesson', element: <AddLcLesson /> },
			{ path: '/AddLc-User-Modal', element: <AddLcUserModal /> },
			{ path: '/AddPhishing-Compaign', element: <AddPhishingCompaignUser /> },
			{ path: '/AddPhishing-CompaignSetting', element: <AddPhishingCompaignSetting /> },
			{ path: "/LessonVideo/:id", element: <LessonVideo /> },
			{ path: "/LessonQuestion", element: <LessonQuestion /> },
			{ path: "/CampaignExam", element: <CampaignExam /> },
			{ path: "/TermDetails", element: <TemsDetails /> },
			{ path: "/TermsAndConditions", element: <TermsAndConditions /> },
			{ path: "/CertificateManagerDetails/:id/CertificateManagerDetails", element: <CertificateManagerDetails /> },
			{ path: "/CertificateManagement", element: <CertificateManager /> },
			{ path: "/AddCertificateManager", element: <AddCertificateManager /> },
			{path:"/Terms",element:<Terms />},
			{path:'/SurveyQuestionDetail/:id/SurveyQuestionDetail',element:<SurveyQuestionDetail />},
			{path:'/AddPoliceUser',element:<AddPoliceUser />},
			{path:'/LockedAccounts',element:<LockedUsers />},
			{path:'/profile',element:<Profile />},
			{path:'/MyCertificate',element:<MyCertificate />},
			{path:'/UploadCertificate',element:<UploadCertificate />},
			{path:'/Certificate/:id/Certificate',element:<Certificate />}


		],
	},
]);

function App() {

	const [userLoggeIn, setUserLoggedIn] = useState(false);
	const { i18n } = useTranslation();

	useEffect(() => {
		console.log(localStorage.getItem("userToken"));
		if (localStorage.getItem("userToken") !== null) {
			setUserLoggedIn(true);
		}

		const lng = localStorage.getItem("i18nextLng") || "en";
		i18n.changeLanguage(lng);
		document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
		document.getElementsByTagName("body")[0].setAttribute("dir", lng === "ar" ? "rtl" : "ltr");

		const rtlStylesheetId = "bootstrap-rtl";
		if (lng === "ar") {
			// Add RTL Bootstrap CSS
			const link = document.createElement("link");
			link.id = rtlStylesheetId;
			link.rel = "stylesheet";
			link.href = "/styles/bootstrap.rtl.min.css";
			document.head.appendChild(link);
		}
		else {
			// Remove RTL Bootstrap CSS
			const link = document.getElementById(rtlStylesheetId);
			if (link) {
				document.head.removeChild(link);
			}
		}

	}, []);

	return (
		<div className={`app ${userLoggeIn ? "loggedin" : ""}`}>
			<RouterProvider router={router} />
			<ToastNotification />
		</div>

	);

	/*
	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/dashboard" element={<Dashboard />} />
			</Routes>
		</div>
	  ); */
}

export default App;
