import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddUser from '../../Components/AddUserModal/AddUser';
import BulkUserImport from '../../Components/BulkUserImport/BulkUserImport';
import './Users.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal , Button} from 'react-bootstrap';
import { showToast } from '../../Components/ShowToast/ToastNotification';
import { NavLink } from 'react-router-dom';

const Users = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [searchVal, setSearchVal] = useState('');
    const [data, setUsers] = useState([]);
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
  

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/users/" + id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (response.ok) {
                showToast(t("itemdeletedsuccessfully"), "success");
                setShowModal(false);
                fetchUsers('');
            } else {
                showToast(t("failedtodeleteitem"), "error");
                setShowModal(false);
            }
            } catch (error) {
                setShowModal(false);
                showToast(t("somethingwentwrong"), "error");
            }            
    };

    useEffect(() => {
        if (searchVal !== '') {
            const filteredData = data.filter((item) =>
                item.firstname?.toLowerCase().includes(searchVal?.toLowerCase() || '') ||
                item.lastname?.toLowerCase().includes(searchVal?.toLowerCase() || '') ||
                item.source_name?.toLowerCase().includes(searchVal?.toLowerCase() || '') ||
                item.role_name?.toLowerCase().includes(searchVal?.toLowerCase() || '')
            );
            setUsers(filteredData);
        } else {
            fetchUsers('');
        }
    }, [searchVal]);

    const fetchUsers = async (url) => {
        setLoading(true);

        let page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            page = temp[1];
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/users?page=' + page, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setUsers(result.data?.data || []);
            setLinks(result.data?.links || []);

        } catch (error) {
            console.error('Error fetching data:', error);
            showToast(t("errorfetchingdata"), "error");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers('');
    }, []);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'username',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_user.svg" alt="First Name" />{t('UserName')}
                    </div>
                ),
                colName: t('UserName'),
            },
            {
                accessorKey: 'firstname',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_user.svg" alt="First Name" />{t('FirstName')}
                    </div>
                ),
                colName: t('FirstName'),
            },
            {
                accessorKey: 'lastname',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_user.svg" alt="Last Name" />{t('LastName')}
                    </div>
                ),
                colName: t('LastName'),
            },
            {
                accessorKey: 'email',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-envelope"></i>{t('Email')}
                    </div>
                ),
                colName: t('Email'),
            },
            {
                accessorKey: 'source_name',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_align-left.svg" alt="Source" />{t('Source')}
                    </div>
                ),
                colName: t('Source'),
            },
            {
                accessorKey: 'status_id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_loader.svg" alt="Status" />{t('Status')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const status = getValue();
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            {status === 1 ? (
                                <div className="statusActive badgeActive">
                                    {t('Active')}
                                </div>
                            ) : (
                                <div className="statusInactive badgeInactive">
                                    {t('Inactive')}
                                </div>
                            )}
                        </div>
                    );
                },
                colName: t('Status'),
            },
            {
                accessorKey: 'role_name',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_tool.svg" alt="Role" />{t('Role')}
                    </div>
                ),
                colName: t('Role'),
            },
            {
                accessorKey: 'id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Action" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue();
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <div className="iconDel" onClick={(e) => {e.stopPropagation(); setSelectedUserId(id);setShowModal(true)}}>
                                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: t('Action'),
            },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    const handleSurveyRowClick = (id) => {
        navigate(`/UserManagement/${id}/UserDetail`)
    }

    return (
        <div className="p-4" >
    
        <div className="pageTitle mb-4">{t('AllUsers')}</div>

        <div className="primaryBlueBox p-5 mb-4">
            <div className="row">
                <div className="col-md-4">
                    <label className="form-label">{t('SearchUsers')}</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("Searchbyname")}
                            aria-label="Search by user"
                            value={searchVal} // Bind input value to searchVal state
                            onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
                        />
                        <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                            <i className="bi bi-search"></i>
                        </button>
                    </div>
                </div>
                <div className="col-md-1">
                    <div className="vrLine"></div>
                </div>
                <div className="col-md-7">
                <div className="d-flex flex-wrap gap-lg-3 users">
                    <NavLink className="btnWhiteGradient mt-3" to="/Add-User">
                        <i className="bi bi-envelope me-2"></i> {t('AddUser')}
                    </NavLink>
                    <BulkUserImport onfetchUsers={fetchUsers} />
                </div>
            </div>

            </div>
        </div>

        {loading ? (
            <div className="d-flex justify-content-center align-items-center  mt-5">
                <div className="spinner-border loader-api" role="status" style={{
                    borderTopColor: "#6897CB",
                    borderBottomColor: "#6897CB",
                    borderLeftColor: "#6897CB",
                    marginTop: "50px"
                }}>
                </div>
            </div>
        ) : (

            <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
            {/* Mobile View - Show stacked rows */}
            <div className="d-md-none">
                {table.getRowModel().rows.map(row => (
                    <div key={row.id} className="stacked-row"
                        onClick={!row.id.includes('id') ? () => handleSurveyRowClick(row.original.id) : null}>
                        {row.getVisibleCells().map(cell => (
                            <div key={cell.id} className="stacked-cell">
                                <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        
            {/* Desktop View - Show Table */}
            <div className="d-none d-md-block">
                <table className="table table-bordered table-hover">
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}
                                        className='rowAnchor'
                                        onClick={!cell.id.includes('id') ? () => handleSurveyRowClick(row.original.id) : null}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        
        )}


        <div className="navigationWrapper">
            <ul>
                {links.map((link, index) => (

                    <li key={index}
                        className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                        onClick={link.url !== null ? () => fetchUsers(link.url) : undefined}
                    >
                        <div dangerouslySetInnerHTML={{ __html: link.label }} />
                    </li>
                ))}
            </ul>
        </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                <Modal.Title>{t("ConfirmDelete")}</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            {t("Areyousureyouwanttodeletethisuser?")}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="danger" onClick={()=>handleDelete(selectedUserId)} disabled={loading}>
                                {loading ? t("Deleting...") : t("Delete")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
    </div>
    );
};

export default Users;
