import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddPhishingCompaignSetting from '../../Components/AddPhishingCompaignSettingModal/AddPhishingCompaignSetting';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';


const PhishingCompaignSetting = (LearningManager_id) => {
    const LM_Id=LearningManager_id?.id;
    // console.log("LMID:",LM_Id);
    const [data, setPhishingCompaign] = useState([]);
    const [links, setLinks] = useState([]);
    const { t } = useTranslation();
    const [searchVal, setSearchVal] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

   
useEffect(() => {

    if (searchVal !== '') {
      const filteredData = data.filter((item) =>
      item.fake_domain.toLowerCase().includes(searchVal.toLowerCase()) ||
      item.execute_url.toLowerCase().includes(searchVal.toLowerCase()) ||
      item.email_subject.toLowerCase().includes(searchVal.toLowerCase()) ||
      item.from_email_address.toLowerCase().includes(searchVal.toLowerCase()) ||
      item.from_name.toLowerCase().includes(searchVal.toLowerCase()) ||
      item.server_name.toLowerCase().includes(searchVal.toLowerCase())
      );
      setPhishingCompaign(filteredData); // Update filtered data
       }  else{
        fetchPhishingCompaignSetting(''); // Update filtered data
      }
      }, [searchVal]); // Re-run whenever searchVal or allData changes

  // Fetch department data
  const fetchPhishingCompaignSetting = async (url) => {
     setLoading(true);
        let page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            page = temp[1] ? temp[1] : 1;
        }
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/phishing-campaign-settings/${LM_Id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
            setLinks([result]); // Ensure result.links exists
    
        } catch (error) {
            console.error('Error fetching Phishing Campaign data:', error);
            setPhishingCompaign([]);
            setLinks([]);
        } finally {
            setLoading(false);
        }
    };



     
     
    
  const columns = React.useMemo(
    () => [
    // {
    //     accessorKey: 'campaign_id',
    //     header: () => (
    //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //          <i class="bi bi-clipboard-data"></i>{t('Campaign id')} 
    //         </div>
    //     ),
    //     colName: 'campaign_id',

    // },
    // {
    //     accessorKey: 'server_id',
    //     header: () => (
    //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //          <i class="bi bi-clipboard-data"></i>{t('Server id')}
    //         </div>
    //     ),
    //     colName: 'server_id',
    // },
    // {
    //     accessorKey: 'template_id',
    //     header: () => (
    //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //           <i class="bi bi-clipboard-data"></i>{t('Template id')} 
    //         </div>
    //     ),
    //     colName: 'template_id',

    // },
    {
        accessorKey: 'fake_domain',
        header: () => (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
         <i class="bi bi-clipboard-data"></i>{t('FakeDomain')}
            </div>
        ),
        colName: 'fake_domain',

    },
//     {
//       accessorKey: 'execute_url',
//       header: () => (
//           <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
//             <i class="bi bi-clipboard-data"></i>{t('ExecuteUrl')} 
//           </div>
//       ),
//       colName: 'execute_url',

//   },
  {
    accessorKey: 'email_subject',
    header: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <i class="bi bi-clipboard-data"></i>{t('EmailSubject')} 
        </div>
    ),
    colName: 'email_subject',

},
{
    accessorKey: 'from_email_address',
    header: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <i class="bi bi-clipboard-data"></i>{t('FromEmailAddress')} 
        </div>
    ),
    colName: 'from_email_address',

},
{
    accessorKey: 'from_name',
    header: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <i class="bi bi-clipboard-data"></i>{t('FormName')} 
        </div>
    ),
    colName: 'from_name',

},
// {
//     accessorKey: 'is_auto_send',
//     header: () => (
//         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
//           <i class="bi bi-clipboard-data"></i>{t('Is auto send')} 
//         </div>
//     ),
//     colName: 'is_auto_send',

// },
{
    accessorKey: 'server_name',
    header: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <i class="bi bi-clipboard-data"></i>{t('ServerName')} 
        </div>
    ),
    colName: 'server_name',

},
{
    accessorKey: 'template_name',
    header: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <i class="bi bi-clipboard-data"></i>{t('TemplateName')} 
        </div>
    ),
    colName: 'template_name',

},

    ],
    []
);

// Create a table instance
   const table = useReactTable({
        data:links,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
const handleSurveyRowClick = (id) => {
    navigate(`/PhishingCompaignSettingDetail/${id}/PhishingCompaignSettingDetail`)
}

  return (
   <div className="p-4">
               {/* <div className="pageTitle mb-4">{t('PhishingCampaignSetting')}</div> */}
               <div className="primaryBlueBox pad mb-4">
                   <div className="row">
                       <div className="col-md-4">
                           <label className="form-label">{t('SearchPhishingCampaignSetting')}</label>
                           <div className="input-group">
                           <input
                               type="text"
                               className="form-control"
                               placeholder={t("Searchbyname")}
                               aria-label="Search by Phishing Campaign"
                               value={searchVal} // Bind input value to searchVal state
                               onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
                               />              
                                <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                   <i className="bi bi-search"></i>
                               </button>
                           </div>
                       </div>
                       <div className="col-md-1">
                           <div className="vrLine"></div>
                       </div>
                       <div className="col-md-7">
                           {/* <AddPhishingCompaignSetting id={LM_Id}/> */}
                           <NavLink className="btnWhiteGradient mt-3" to={`/AddPhishing-CompaignSetting?id=${LM_Id}`}>
                                                         <i class="bi bi-envelope me-2 mt-3 h6" ></i> {t('AddPhishingCampaignSetting')}
                                               </NavLink>
                       </div>
                   </div>
               </div>
   
             
   
               {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}></div>
                </div>
            ) : (
                  <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                                    {window.innerWidth < 768 ? (
                                        table.getRowModel().rows.map(row => (
                                            <div key={row.id} className="stacked-row"
                                                onClick={row.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                                {row.getVisibleCells().map(cell => (
                                                    <div key={cell.id} className="stacked-cell">
                                                        <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                                        <div className='w-100'>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    ) : (
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                {table.getHeaderGroups().map(headerGroup => (
                                                    <tr key={headerGroup.id}>
                                                        {headerGroup.headers.map(header => (
                                                            <th key={header.id}>
                                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody>
                                                {table.getRowModel().rows.map(row => (
                                                    <tr key={row.id}>
                                                        {row.getVisibleCells().map(cell => (
                                                            <td key={cell.id}
                                                                className='rowAnchor'
                                                                onClick={cell.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
            )}

              
                         <div className="navigationWrapper">
                   <ul>
                       {links.map((link, index) => (
   
                           <li key={index}
                               className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                               onClick={link.url !== null ? () => fetchPhishingCompaignSetting(link.url) : undefined}
                           >
                               <div dangerouslySetInnerHTML={{ __html: link.label }} />
                           </li>
                       ))}
                   </ul>
               </div>
   
           </div>
  );
};

export default PhishingCompaignSetting;
